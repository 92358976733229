import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

export default () => 

{
    return(
        <Container>
            <Row className="d-flex justify-content-center">
                <Col xs={10}>
                    <Card className="w-100 p-4 text-left">
                        <Card.Title className="text-center">Community Guidelines</Card.Title>
                        

<p className="mt-3 text-center"><i>All human beings are born free and equal in dignity and rights. 
They are endowed with reason and conscience and should act towards one another in a spirit of brotherhood.</i></p>
<p>- The United Nations Declaration of Human Rights of 10. desember 1948 (UDHR) article 1. </p>

                   </Card>
                </Col>
            </Row>
        </Container>
    );
}