export const UPDATE_GROUP_PROFILE = "updateGroupProfile";

export type User = {
  id: string;
  gar_avatar_name: string;
  username: string;
  email: string;
  created: string;
};

export interface GroupProfileState {
  pk: string;
  id: string;
  user: User; 
  owner: string;
  admin: string;
  name: string;
  established: string;
  description: string;
  code: string;
  image: string;
  country: string;
  city: string;
  homepageViewAllowed: string;
  homepageAddAllowed: string;
}

export interface UpdateGroupProfileAction {
  type: typeof UPDATE_GROUP_PROFILE;
  payload: GroupProfileState;
}
