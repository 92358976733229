import React, { Fragment, useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../state/GlobalState";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { useUserAuthSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { InputGroup, Form, Modal, FormControl, Button } from "react-bootstrap";
import {API_URL} from '../../constants/apiUrl'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ShareUDHR = ({article}) => {
  

    //   const { loadStatements } = useContext(GlobalContext);
//   const [statement, setStatement] = useState();
  const [copied, setCopied] = useState(false);
  const [copyText, setCopyText] = useState('');
  
  const shareInput = React.useRef();

  let history = useHistory();

  useEffect(() => {
    setCopyText(API_URL+'/home/udhr/'+article);
}, []);
  
  
  const handleClose = e => {
    e.preventDefault();
    // props.setShareActive(false);
    // history.push(homeLink);
  }

return (

    <Fragment>
        <Form onReset={handleClose}>
            <div>           
                
                <InputGroup className="mt-2" style = {{width: '80%'}}>
                    <FormControl                                            
                        ref={shareInput} 
                        as="textarea" 
                        aria-label="With textarea" 
                        id={new Date().getTime().toString()+"-shareInput"}
                        defaultValue={API_URL+'home/udhr/'+article}
                        readOnly
                    />
                </InputGroup>
                
                
                <div style = {{width: '80%'}} className="text-right pt-2">                              
                    <CopyToClipboard 
                        text={copyText}
                        onCopy={() => setCopied(true)}>

                        <Button size="sm" type="submit" onClick={(e) => {e.preventDefault(); setCopied(true);}}>{(copied) ? ('Copied') : ('Copy to clipboard')}</Button>

                    </CopyToClipboard>

                    <Button size="sm" type="reset">Close</Button>
                    
                </div>               
            
            </div>
        </Form>
    </Fragment>

      
  );
}

export default ShareUDHR;