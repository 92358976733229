import React from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, Row, Col, Dropdown, DropdownButton, Form, FormControl, Button } from "react-bootstrap";
import UserAccessLinks from "./UserMenu";
import { homeLink, searchLink, friendsLink } from "../views/contentPageLinks";
import {  groupsLink } from "../user/UserProfileLinks";

import CookieBanner from "./cookieBanner";
import Search from "../search/Search"
import LoadingAlertBar from "./loadingAlertBar";
import DataFriendsComponent from "../../../services/DataFriendsComponent";
import { ShowOnlyWhenLoggedIn } from "../utils/elementWrappers/authBasedVisibilityWrappers";

const LinkStyle = styled.a`
  color: red;
  border: 2px solid white;
  `;

const NavbarStyle = styled.div`

#nav-bar-main-style {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    box-shadow: 3px 2px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .max-width {
    display: flex;
    width: 100%;
  }

  .rounded-left-0 {
    border-bottom-left-radius: 0rem !important;
    border-top-left-radius: 0rem !important;
  }
  .rounded-right-0 {
    border-bottom-right-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
  }

  #nav-space-filler {
    padding-bottom: 3.5rem;
  }

  #logo-home-nav {
    width: 30px;
    height: auto;
  }

  .nav-toggle {
    align-self: flex-end;
  }
`;

export default () => {


  return (

    <>
    {/* <DataFriendsComponent /> */}

    <NavbarStyle>

      <div id="nav-space-filler"></div>

      <Navbar
        bg="white"
        expand="md"
        fixed="top"
        id="nav-bar-main-style"
        collapseOnSelect
      >

        <CookieBanner />

        <div className="max-width">

          <Container className="pt-2 pb-2">

            {/* <div className="border border-warning"></div> */}

            <Row as="div" className="max-width ml-1 d-flex flex-row justify-content-start">

            {/* <Col className="col-1"> */}
              <Navbar.Brand style={{color: "#007bff", fontWeight: "bold"}} >GAR217A</Navbar.Brand>
            {/* </Col> */}
            
            <Navbar.Toggle style={{marginLeft: "auto"}} aria-controls="basic-navbar-nav" className="nav-toggle" />
            <Navbar.Collapse id="basic-navbar-nav" className="flex-start">

            <Nav className="max-width" activeKey={""}>

              <Col className="col-12 col-md-2 d-block d-md-flex flex-row p-0" >

                  <LinkContainer to={homeLink.link}>
                    <Nav.Link eventKey={homeLink.link}>
                      {homeLink.icon}
                      {homeLink.displayName}
                    </Nav.Link>
                  </LinkContainer>

                <ShowOnlyWhenLoggedIn>
                  <LinkContainer to={friendsLink.link}>
                    <Nav.Link eventKey={friendsLink.link}>
                      {friendsLink.icon}
                      {friendsLink.displayName}
                    </Nav.Link>
                  </LinkContainer>
                  </ShowOnlyWhenLoggedIn>

                  {/* Menu item for mobil devices */}
                  <div className="d-md-none flex-row justify-content-center p-1">
                  <LinkContainer to={searchLink.link}>
                    <Nav.Link eventKey={searchLink.link}>
                      {searchLink.icon}
                      {searchLink.displayName}
                    </Nav.Link>
                  </LinkContainer>
                  </div>


              </Col>

              {/* Search on desktops */}
              <Col className="col-5 d-none d-md-flex flex-row justify-content-center p-1">
                <Search />
                    {/* <Form inline>
                        
                        <FormControl
                            value={searchString}
                            onChange={e => onChangeHandler(e)} 
                            size="sm" 
                            type="text" 
                            placeholder="Search for persons" 
                            className="border border-info mr-sm-2" 
                            style={{width: "300px"}}
                            onKeyPress={handleKeyPress}
                            />
                        
                        <Button 
                            size="sm" 
                            className="text-right" 
                            variant="primary"
                            onClick={(searchString) ? handleClick : null}
                        >
                            Search
                        </Button>
                    </Form>                 */}
            </Col>

            <Col className="ml-1 col-2 p-0 d-flex flex-row justify-content-end  ">
            <ShowOnlyWhenLoggedIn>

            <LinkContainer to={groupsLink.link}>
                    <Nav.Link eventKey={groupsLink.link}>
                      {groupsLink.icon}
                      {groupsLink.displayName}
                    </Nav.Link>
                  </LinkContainer>
                  
            </ShowOnlyWhenLoggedIn>
              
              </Col>
              <UserAccessLinks />

            </Nav>

          </Navbar.Collapse>

          </Row>

          </Container>

        </div>

        <div className="max-width">
          <LoadingAlertBar />
        </div>

      </Navbar>

      <div className="mt-4"></div>

    </NavbarStyle>
    </>
  );
};
