export const USERNAME = "profile_username";
export const FIRSTNAME = "profile_firstname";
export const LASTNAME = "profile_lastname";
export const EMAIL = "profile_email";
export const IMAGE = "profile_image";
export const LAST_SEEN = "profile_last_seen";
export const ONLINE = "profile_online";
export const DATE_OF_BIRTH = "profile_date_of_birth";
export const STATUS = "profile_status";
export const GAR217A_AVATAR_NAME = "profile_gar217a_avatar_name";
export const CREATED = "profile_created";
export const BORN_IN = "profile_born_in";
export const LIVING_IN = "profile_living_in";
export const CITY = "profile_city";
export const BIO = "profile_bio";
export const LAST_SAVED = "profile_last_saved";


export const SUBMIT = "profile_submit";
export const FORM_ID = "profileForm";
export const FORM_ERROR_DIV_ID = "profile_form-errors";
