import React from 'react'
import { useState, useContext } from 'react';
import { useDispatch } from "react-redux";
import axios from 'axios'
import {API_PROFILE_URL, API_URL, API_MEDIA_URL, API_STATIC} from "../../../constants/apiUrl";
import { ACTION_ADD_FOLLOWEE, ACTION_REMOVE_FOLLOWEE } from "../../../constants/stickTypes";
// import Friends from "../../friends/Friends"
import { Container, Col, Row, Card, Button, Image } from "react-bootstrap";
import { useUserAuthSelector, useUserProfileSelector, useGroupProfileSelector } from "../../../state/typedSelectors";
import { updateGroupProfile } from "../../../state/groupProfileState/groupProfileActions";
import { useHistory } from "react-router-dom";
import {homeLink} from '../../views/contentPageLinks'
import ReactRoundedImage from "react-rounded-image";

import StickActions from './StickActions'

import { StickButton, RequestButton } from './StickButton'

import { 
  STICKTYPE_USER_SEARCH,
  STICKTYPE_FRIENDREQUESTS,
  STICKTYPE_FRIENDS,
  STICKTYPE_FOLLOWERS,
  STICKTYPE_FOLLOWEES,
  STICKTYPE_BLOCKS,
  STICKTYPE_GROUPS,
  STICKTYPE_PROJECTS,
  STICKTYPE_ENGAGE
} from "../../../constants/stickTypes";

import { 
  ACTION_ADD_FRIENDREQUEST,
  ACTION_ACCEPT_FRIENDREQUEST,
  ACTION_REJECT_FRIENDREQUEST,
  ACTION_REMOVE_FRIEND,
  ACTION_BLOCK,
  ACTION_UNBLOCK,
  ACTION_ADD_GROUP,
  ACTION_REMOVE_GROUP
} from "../../../constants/stickTypes";

import { GlobalContext } from "../../../state/GlobalState";

const stickStyle = {
  fontSize: "0.9rem"
}

const Stick = ({ stickObject, stickType, objectType }, { match, location }) => {

  // console.log("stickObject", stickObject);
  // console.log("stickListType", stickType);

  let buttonSet = [];
  
  const [requestSent, setRequestSent] = useState(false);
  const [follows, setFollows] = useState(false);
  
  const { block, unblock, addFollowee, removeFollowee, friends, addFriend, removeFriend, groups, addGroup, removeGroup, editGroup } = useContext(GlobalContext);
  
  const { authToken } = useUserAuthSelector(
     state => state.userAuthReducer
   );    


   const { name } = useGroupProfileSelector(
    state => state.groupProfileReducer
  );  


  let history = useHistory();
  const dispatch = useDispatch();
  let stickTypeButtonSet = null;


  // Property for sending correct image-url to consumer UserPageFull
  // TODO - fix backend to send the same image file reference always.
  let localImage = null;



  if (stickObject.image) {
    localImage = stickObject.image;
    if (localImage.search('http') === -1) {
      localImage = API_MEDIA_URL + stickObject.image;
      // console.log("LocalTmage", localImage);
    } else {
      localImage = stickObject.image;
    } 
  }
  
  const thisLocalObject = stickType;
  
  const view = (e) => {

    let user_id = e.target.dataset.pk;

    history.push({
      pathname: '/user/'+user_id,
      // state: { localObject: objectType }
      state: { localObject: thisLocalObject }
    })

    // history.push('/user/'+user_id);
    
  };

  const viewGroup = (e) => {

    console.log("StickObject", stickObject);

    const groupUser = {'id': stickObject.user.pk,
                      'gar_avatar_name': stickObject.user.gar_avatar_name,
                      'username': stickObject.user.username,
                      'email': stickObject.user.email,
                      'created': stickObject.user.created
                    }

    // Setting state for the selcted Group
    // The dispatch here, assumes stickObject is loaded
    // with relevant data from group

    console.log("groupUser", groupUser)

    dispatch(
      updateGroupProfile(
        stickObject.pk, 
        stickObject.id,
        groupUser,
        stickObject.owner,
        stickObject.admin, 
        stickObject.name, 
        stickObject.estabilshed, 
        stickObject.description, 
        stickObject.code,
        stickObject.image,
        stickObject.country, 
        stickObject.city, 
        stickObject.homepageViewAllowed,
        stickObject.homepageAddAllowed           
      )  
    );
    
    let user_id = e.target.dataset.pk;

    // console.log("user_id", user_id);
    // console.log("stickObject", stickObject);
    // console.log("STATE, name", name);

    history.push({
      pathname: '/group/profile/'
    })

    // history.push('/user/'+user_id);
    
  };

  
  const stickTypeUsersButtonSet = (    
  
      <div>             {/*className="d-flex flex-row text-right"*/}
   
       <StickButton
         stickObject={stickObject}
         stickType={stickType}
         objectType={objectType}
         action={ACTION_ADD_FRIENDREQUEST}
         // Friendrequests does not have a global state yet.
         // Hence stateAction is set to ''.
         stateAction=''
         text="Add" 
         setText="Sent req" 
         color="primary"
         setColor="success"
        />   
   
        <StickButton
            stickObject={stickObject}
            stickType={stickType}
            objectType={objectType}
            action={ACTION_ADD_FOLLOWEE}
            stateAction={addFollowee}
            text="Follow" 
            setText="Followed" 
            color="primary"
            setColor="success"
         />    
   
     </div>
   );   

   const stickTypeFriendsButtonSet = (    
  
    <div>
  
      <StickButton
        stickObject={stickObject}
        stickType={stickType}
        objectType={objectType}
        action={ACTION_BLOCK}
        // Friendrequests does not have a global state yet.
        // Hence stateAction is set to ''.
        stateAction='block'
        text="Block" 
        setText="Blocked" 
        color="primary"
        setColor="danger"
        />
   
      <StickButton
          stickObject={stickObject}
          stickType={stickType}
          objectType={objectType}
          action={ACTION_REMOVE_FRIEND} 
          stateAction={removeFriend}
          text="Remove" 
          setText="Removed" 
          color="primary"
          setColor="success"
      />
  
   </div>
  );

  const stickTypeFriendRequestsButtonSet = (    
  
    <div>
  
      <StickButton
        stickObject={stickObject}
        stickType={stickType}
        objectType={objectType}
        action={ACTION_ACCEPT_FRIENDREQUEST}
        // Friendrequests does not have a global state yet.
        // Hence stateAction is set to ''.
        stateAction=''
        text="Accept" 
        setText="Accepted" 
        color="primary"
        setColor="success"
        />
   
      <StickButton
          stickObject={stickObject}
          stickType={stickType}
          objectType={objectType}
          action={ACTION_REJECT_FRIENDREQUEST} 
          stateAction=''
          text="Reject" 
          setText="Rejected" 
          color="primary"
          setColor="danger"
      />
  
   </div>
  );


  const StickTypeFollowersButtonSet = (
    <>  
    
      <StickButton
        stickObject={stickObject}
        stickType={stickType}
        objectType={objectType}
        action={ACTION_BLOCK}
        stateAction={block}
        text="Block" 
        setText="Blocked" 
        color="primary"
        setColor="danger"
      />
    </>

  )

  const StickTypeFolloweesButtonSet = (
    <>  

      <StickButton
        stickObject={stickObject}
        stickType={stickType}
        objectType={objectType}
        action={ACTION_BLOCK}
        stateAction={block}
        text="Block" 
        setText="Blocked" 
        color="primary"
        setColor="danger"
      />

      <StickButton
          stickObject={stickObject}
          stickType={stickType}
          objectType={objectType}
          action={ACTION_REMOVE_FOLLOWEE}
          stateAction={removeFollowee}
          text="Unfollow" 
          setText="Unfollowed" 
          color="primary"
          setColor="danger"
      />

    </>
  );

  const stickTypeBlocksButtonSet = (
    <>
  
    <StickButton
      stickObject={stickObject}
      stickType={stickType}
      objectType={objectType}
      action="unblock"
      stateAction={ACTION_UNBLOCK}
      text="Unblock" 
      setText="Unblocked" 
      color="primary"
      setColor="success"
     />
  

  </>

  );
  
  const stickTypeGroupsButtonSet = (    
  
    <div>
     

      {/* ViewButton - Present the VIEW button at the end FOR all StickTypes*/}
      <Button 
        id="userstick-view-btn" 
        size="sm" 
        data-pk={stickObject.pk} 
        onClick={viewGroup} 
        className="ml-1"
      >
        Manage group
      </Button> 


     <StickButton
          stickObject={stickObject}
          stickType={stickType}
          objectType={objectType}
          action={ACTION_REMOVE_GROUP} 
          stateAction={removeGroup}
          text="Remove" 
          setText="Removed" 
          color="primary"
          setColor="success"
      />


  
   </div>
   
  );

let stickImage, stickName, stickCountry, stickCity = null;


 

switch (stickType) {
  case STICKTYPE_GROUPS:
    stickImage = localImage;
    stickName = stickObject.name;
    stickCountry = stickObject.country;
    stickCity = stickObject.city;
    break;
  default:
    stickImage = localImage;
    stickName = (stickObject.first_name !== null) ? (stickObject.first_name+' '+stickObject.last_name) : (stickObject.username);
    stickCountry = (stickObject.living_in) ? (stickObject.living_in) : (null);
    stickCity = (stickObject.city) ? (stickObject.status) : (null);
}


// lazy programmering towards release
if ((objectType === "groups") | (objectType === "udhr_groups") ) {
    stickImage = localImage;
    stickName = stickObject.name;
    stickCountry = stickObject.country;
    stickCity = stickObject.city;
}


  const uName = <div>{stickName}</div>;

  const uLivingIn = (stickCountry) ? 
    (<Card.Text className="m-0">Country: {stickCountry}</Card.Text>): null

  const uCity = (stickCity) ? 
    (<Card.Text className="m-0">City: {stickCity}</Card.Text>): null

  //   const uName = (stickObject.first_name !== null) ? 
  //   (<div>{stickObject.first_name} {stickObject.last_name}</div>):
  //   (<div>{stickObject.username}</div>)

  // const uStatus = (stickObject.status) ? 
  // (<Card.Text className="m-0">Status: {stickObject.status}</Card.Text>): null

  // const uLivingIn = (stickObject.living_in) ? 
  //   (<Card.Text className="m-0">Living in: {stickObject.living_in}</Card.Text>): null

  // const uCity = (stickObject.city) ? 
  //   (<Card.Text className="m-0">City: {stickObject.city}</Card.Text>): null

  switch (stickType) {        
    

    case STICKTYPE_USER_SEARCH: 
      stickTypeButtonSet = stickTypeUsersButtonSet;
      break;
    case STICKTYPE_ENGAGE: 
      stickTypeButtonSet = stickTypeUsersButtonSet;
      break;
    case STICKTYPE_FRIENDREQUESTS: 
      stickTypeButtonSet = stickTypeFriendRequestsButtonSet;
      break;
    case STICKTYPE_FRIENDS: 
      stickTypeButtonSet = stickTypeFriendsButtonSet;
      break;
    case STICKTYPE_FOLLOWERS: 
    stickTypeButtonSet = StickTypeFollowersButtonSet;
      break;
    case STICKTYPE_FOLLOWEES: 
    stickTypeButtonSet = StickTypeFolloweesButtonSet;
      break;
    case STICKTYPE_BLOCKS: 
    stickTypeButtonSet = stickTypeBlocksButtonSet;
      break;
    case STICKTYPE_GROUPS: 
      stickTypeButtonSet = stickTypeGroupsButtonSet;
      break;
      
    }

    
    return (
      <React.Fragment>                    
               
        {/* This is the presentation/render of the actual stick */}
        {/* The stick is horizontally aligned */}
        {/* All sticks are represented by image/avatar, name/username, location (country, city) a VIEW button and additional optional buttons */}

        <Card className="mb-2 ml-0 p-1 border border-light-dark">
        {/* <Card className="mb-2 p-1 border border-light-dark"> */}

          <Card.Body className="m-0 pl-2 pr-2 pt-0 pb-0">
          <Row xs={2} className="d-flex flex-row pl-xs-10 m-md-1">

            
            {/* "Left" part of the stick - user image/avatar, name/username, location*/}
            <Col xs={5} md={8} style={stickStyle} className="d-flex flex-row m-0 p-0">  {/*className="border border-danger"*/}
              
              {/* Make image/name/location stack on top of each other at smaller viewports */}
              <Row className="d-flex flex-column flex-md-row m-0 p-0 w-100">
                
                {/* Picture/Avatar */}
                <Col xs={5} md={2} className="pl-1">
                  <ReactRoundedImage 
                    image={localImage} 
                    imageWidth="42"
                    imageHeight="42"
                    roundedSize="0"
                  />
                </Col>

                {/* Name/Username */}
                <Col xs={12} md={4} style={stickStyle} className="pl-1 ml-1">
                      {/* {uName} */}
                      {stickName}
                </Col>

                {/* Location = Country/City */}
                <Col xs={12} md={5} style={stickStyle} className="pl-1 ml-1 w-100">
                      <div className="mr-1">{stickCity}</div>
                      {/* {stickObject.city} */}
                      {stickCountry}
                      {/* {(stickObject.living_in && stickObject.city) ? ", "+(stickObject.living_in) : (stickObject.living_in) ? (stickObject.living_in): null} */}
                </Col>

              </Row>
            </Col>


            {/* "Right" part of the stick - the buttons - specific for each StickType*/}
            <Col xs={7} md={4} className="d-flex flex-row justify-content-end align-items-start p-1 w-100" > 
              
              {/* Present zero or more StickButtons as defined per stickType */}
              {stickTypeButtonSet}

              {/* ViewButton - Present the VIEW button at the end FOR all StickTypes*/}
              <Button 
                id="userstick-view-btn" 
                size="sm" 
                data-pk={stickObject.pk} 
                onClick={view} 
                className="ml-1"
              >
                View
              </Button>                       


            </Col>

            {/* <Col sm={1} className="d-flex text-right align-items-center">

            </Col> */}

          </Row>
          </Card.Body>
        </Card>
      </React.Fragment>

    );
  };

export default Stick;