import React ,{useContext} from 'react';
import UserList from '../user/UserList';
import StickList from '../generic/stick/StickList';
import SearchMenu from './SearchMenu';
import Search from './Search';
import { Container, Col, Row } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers';
import { GlobalContext } from "../../state/GlobalState";
import styled from "styled-components";
import { STICKTYPE_USER_SEARCH } from "../../constants/stickTypes";


const styledRow = styled(Row)`
  margin-top: 3 rem;
`;

function SearchPage() {

const { users } = useContext(GlobalContext);

console.log(users);

  return (
    
    <React.Fragment>
               
        <Container id="search-main-container" className="mt-3 w-100">            
          
          <Row id="search-main-row" className="justify-content-center">
              
              {/* <Col id="search-main-sidebar" sm={2} md={2} className="p-0 d-none d-md-block">
                  <SearchMenu />
              </Col> */}
      
              <Col id="search-main-feed" xs={12} md={10} className="p-0"> 
                  
                  {/* // search component on mobile devices */}
                    <div className="d-flex d-md-none justify-content-center">
                      {/* <Search /> */}
                    </div>

                  <div id="home-main-feed" className="w-100 m-0">
                    <div style={{color: "grey"}} className="text-center mb-5"><h2>Search result</h2></div>
                    {(users.length > 0) ? (<StickList stickType={STICKTYPE_USER_SEARCH} objectList={users} />) :
                    (<p className="text-center">No matches. Please adjust your search criteria.</p>)}
                </div>
              </Col>    

              {/* <Col id="home-main-rightarea" sm={2} md={2}  className="p-0 d-none d-md-block">
                <div className="d-none d-md-block">
                </div>
              </Col>           */}

          </Row>       

        </Container>
        
    </React.Fragment>

);
}

export default SearchPage;