import { http, httpForm, getReq } from "./http-common";
import axios from 'axios'

import {API_FRIENDS_URL, 
        API_REPLY_ADD_URL, 
        API_REACTION_ADD_URL,
        API_REACTION_URL} from "../web/constants/apiUrl";

class FriendsDataService {

    getAll() {
      const res = http.get(`/friends/`);
      console.log(res);
      return res;
    }

    getAllId(id) {
      const res = http.get(`/friends/all/?user_id=${id}`);
      console.log(res);
      return res;
    }

    requestFriendship(data) {
      const res = httpForm.post("/friends/", data);
      console.log(res);
      return res;
    }

    acceptFriendship(id) {
      return http.get(`/friendrequests/${id}/accept/`);
    }

    rejectFriendship(id) {
      return http.get(`/friendrequests/${id}/reject/`);
    }

    block(id, user) {
      console.log("FriendsDataService, block", id, user);      
      return http.get(`/friends/${id}/block/?user_id=${user}`);
    }

    unblock(id, user) {
      console.log("FriendsDataService, unblock", id, user);      
      return http.get(`/friends/${id}/unblock/?user_id=${user}`);
    }

    remove(id) {
      return http.delete(`/friends/${id}/`);
    }

    getFriendRequests(id) {
      return http.get(`/friends/requests/?user_id=${id}`);    
    }

    blocking(id) {
      return http.get(`/friends/blocking/?user_id=${id}`);
    }
}

export default new FriendsDataService();