import React from 'react';
import {Statements} from '../statements/Statements'
import { AddStatement } from '../statements/addStatement';
import { DontShowWhenLoggedIn, ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers';
import FeedRoutes from './FeedRoutes'
import FeedPublicRoutes from './FeedPublicRoutes'
import BodyRoutes from "../views/bodyRoutes";
import {UDHR_ARTICLES} from '../../constants/UdhrArticles.js'
import {AREA_PUBLIC, AREA_PRIVATE} from '../../constants/GlobalConstants'

export default function Feed(area) {
  
    return (
        
        <>
            <ShowOnlyWhenLoggedIn>
                <FeedRoutes />
                <div className="mb-5 d-flex justify-content-center">
                    <AddStatement objectState="standard" />
                </div>
                <div className="d-flex justify-content-center">
                    <Statements area={AREA_PRIVATE} indentifier={null} user_id={null} objectState="common" />
                </div>
            </ShowOnlyWhenLoggedIn>
            
            <DontShowWhenLoggedIn>
                    <FeedPublicRoutes />
                    {/* <h4 className="text-center mb-3">Recent messages:</h4> */}
                    <div className="d-flex justify-content-center">
                        {/* <Statements area={AREA_PUBLIC}  indentifier={null} user_id={null} objectState="common"/> */}
                    </div>
            </DontShowWhenLoggedIn>
        </>
                    
    );
}