import { http, httpForm } from "./http-common";

import {  API_STATEMENT_ADD_URL, 
        API_REPLY_ADD_URL, 
        API_REACTION_ADD_URL,
        API_REACTION_URL} from "../web/constants/apiUrl";

class StatementsDataService {
    
    getStatement(id, data) {
      const res = http.get(`/users/user/statement/${id}/`);
      return res;
    }

    getAll(id) {
      const res = http.get(`/users/feed/${id}/`);
      return res;
    }

    getAllToArea(area) {
      const res = http.get(`/users/statements/?area=${area}`);
      
      return res;
    }

    getAllToUDHR(udhr) {
      const res = http.get(`/users/statements/udhr/?udhr=${udhr}`);
      return res;
    }

    getAllFromCategoryToUDHR(udhr, category) {
      const res = http.get(`/users/statements/udhr/?udhr=${udhr}&category=${category}`);
      return res;
    }

    create(id, data) {
      console.log(data);
      const res = http.post(API_STATEMENT_ADD_URL+id, data);
      console.log("create", res);
      return res;
    }
  
    update(id, data) {
      return http.put(`/users/user/statement/edit/${id}/`, data);
    }
  
    delete(id) {
      const res2 = http.delete(`/users/user/statement/delete/${id}/`);
      console.log("create", res2);
      return res2;
    }
  
    deleteAlbum(id) {
      return http.delete(`/users/user/statement/delete/1`);
    }


    deleteAll(id) {
      return http.delete(`/users/user/statements/${id}`);
    }
  
    find(searchString) {
      return http.get(`/users/statements/?statment=${searchString}`);
    }

    createReply(data) {
      return http.post(API_REPLY_ADD_URL, data);
    }

    createReaction(data) {
      return http.post(API_REACTION_ADD_URL, data);
    }

    deleteReaction(id) {
      const res3 = http.delete(API_REACTION_URL+id);
      console.log("id", res3);
      return res3
    }

  }

  export default new StatementsDataService();