import * as types from "./userProfileTypes";

export const updateUserProfile = (
  username?: string,
  first_name?: string,
  last_name?: string,
  email?: string,
  gar_avatar_name?: string,
  image?: string,
  last_seen?: string,
  online?: string,
  created?: string,
  status?: string,
  date_of_birth?: string,
  born_in?: string,
  living_in?: string,
  city?: string,
  bio?: string,
  last_saved?: string,
  profile_completed_percent?: string,
  number_of_logins?: string,
  education?: string,
  education_place?: string,
  occupation?: string,
  position?: string,
  first_login?: string,
  homepageViewAllowed?: string,
  homepageAddAllowed?: string
): types.UpdateProfileAction => {
  // console.log("updateUserProfile", updateUserProfile);
  return {
    type: types.UPDATE_PROFILE,
    payload: {
      username: username,
      ufirst_name: first_name,
      ulast_name: last_name,
      uemail: email,
      gar_avatar_name: gar_avatar_name,
      image: image,
      last_seen: last_seen,
      online: online,
      created: created,
      status: status,
      date_of_birth: date_of_birth, 
      born_in: born_in,
      living_in: living_in,
      city: city,
      bio: bio,
      last_saved: last_saved,
      profile_completed_percent: profile_completed_percent,
      number_of_logins: number_of_logins,
      education: education,
      education_place: education_place,
      occupation: occupation,
      position: position,
      first_login: first_login,
      homepageViewAllowed: homepageViewAllowed,
      homepageAddAllowed: homepageAddAllowed
    
    }
  };
};