import React, { useState, useRef } from 'react';
import axios from 'axios'
import {API_PROFILE_URL} from "../../constants/apiUrl";
import { Modal, Container, Col, Row, Card, Button } from "react-bootstrap";
import { useUserAuthSelector, useUserProfileSelector } from "../../state/typedSelectors";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import DataService from "../../../services/DataService"
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { userProfileLink } from './UserProfileLinks'


const UserWelcomeModal = ({setShowWelcomeModal}) => {
    
    const [show, setShow] = useState(true);
    const [gotIt, setGotIt] = useState(false);

    const { userid, authToken } = useUserAuthSelector(
        state => state.userAuthReducer
    );  

    const { first_login } = useUserProfileSelector(
        state => state.userProfileReducer
        );              

    const dispatch = useDispatch();

    const proceedBtn = useRef(null);


    const handleGotItClick = () => {
        setGotIt(true);
    }

    const handleClose = () => {
        setShow(false);
        setShowWelcomeModal(false);
    }

    const handleSubmit = () => {

        let form_data = new FormData();
        form_data.append('first_login', false); 
        
        DataService.update(userid, form_data)
        .then(response => {
              console.log(response);              
              dispatch(
                updateUserProfile(
                    response.data.username,
                    response.data.first_name,
                    response.data.last_name,
                    response.data.email,
                    response.data.gar_avatar_name,
                    response.data.image,
                    response.data.last_seen,
                    response.data.online,
                    response.data.created,
                    response.data.status,
                    response.data.date_of_birth,
                    response.data.born_in,
                    response.data.living_in,
                    response.data.city,
                    response.data.bio,
                    response.data.last_saved,
                    response.data.profile_completed_percent,
                    response.data.number_of_logins,
                    response.data.education,
                    response.data.education_place,
                    response.data.occupation,
                    response.data.position,
                    response.data.first_login              )  
                  );
              
              dispatch(push(userProfileLink));
              setShow(false);
              setShowWelcomeModal(false);
       
            }
            )
            .catch(error => console.log(error))
      };

          
    return (
      <>
  
        <Modal show={show} onHide={handleSubmit}>

            <Modal.Header closeButton>
                <Modal.Title>GAR217A</Modal.Title>
            </Modal.Header>          

            <Modal.Body>                    
                <Container className="mt-2">        
                    <Row>            
                        <Col className="justify-content-centre">
                            <Card className="border-0 w-100">
                                <Card.Body className="text-center">                            
                                    {/* {(number_of_logins == 0) ?  */}                                
                                    <div>
                                        <Card.Title as="h1" className="mb-5">Welcome to GAR217A!</Card.Title>
                                        <Card.Text className="mb-3 text-danger border border-warning" as="p" >While using GAR217A, please consider this:</Card.Text>
                                        <Card.Text style={{fontSize: "1.5rem"}}>
                                            <em>"All human beings are born free and equal in dignity and rights.
                                            They are endowed with reason and conscience and should act towards one another in a spirit of brotherhood."</em>
                                        </Card.Text>
                                        <Card.Text as="p">- The Universal Declaration of Human Rights, Article 1</Card.Text>
                                    </div>

                                    <Button 
                                        variant={(gotIt) ? "success" : "primary"} 
                                        onClick={handleGotItClick}
                                        className="text-center mt-5 mb-3"
                                    >
                                     {(gotIt) ? "Great - Thank you!" : "Got it! :-"}
                                    </Button>
                            </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                </Container> 

                <Modal.Footer className="d-flex justify-content-center text-center">
                    <div className="d-flex justify-content-center text-center">
                    {(gotIt) ? (
                        <Button
                            id="velcome-modal-proceed-btn"
                            ref={proceedBtn} 
                            variant="primary"
                            onClick={handleSubmit}
                            type="button"
                        >
                            Proceed
                        </Button>
                    ) : null}
                    </div>                            
                </Modal.Footer>

          </Modal.Body> 

        </Modal>
      </>
    );
  }

  export default UserWelcomeModal;