import React, { Fragment, useState, useContext } from "react";
import { GlobalContext } from "../../state/GlobalState";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { useUserAuthSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { InputGroup, Form, Modal, FormControl, Button } from "react-bootstrap";

export const EditStatement = (props) => {
    
  const { userid, authToken } = useUserAuthSelector(
      state => state.userAuthReducer
    );
  
  const { loadStatements, editStatement, editUDHRStatement } = useContext(GlobalContext);
  
  const [editedStatement, setStatement] = useState();
  const [err, setErr] = useState("");
  const [errBool, setErrBool] = useState(false);

  const editInput = React.useRef();

  let history = useHistory();

  const handleReset = e => {
    e.preventDefault();
    props.setEditActive(false);
    history.push(homeLink);
  }

  const handleSubmit = e => {
    e.preventDefault();

    const updateStatementWith = {
        statement: editedStatement,
        last_saved_by: props.statement.user.pk,
        last_saved: new Date(Date.now()),
        edited: true        
      };          
    
    StatementsDataService.update(props.statement.id, updateStatementWith)
    .then(response => {
      
        console.log(response.data);
        const newStatement = {
            ...props.statement,
            ...updateStatementWith
          }
          console.log(newStatement);
        //update Global State
        editStatement(newStatement);

    })
        .catch(error => {
      if (error["response"] !== undefined) {
          console.log(error.response);
      } else {
      }
    })

    props.setEditActive(false);
    history.push(homeLink);
  };

  return (
    <Fragment>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
            <InputGroup className="mt-2" style = {{width: '80%'}}>
                <FormControl                                            
                    ref={editInput} 
                    as="textarea" 
                    aria-label="With textarea" 
                    id="editInput"
                    defaultValue={props.statement.statement}
                    onChange={(e) => {
                        e.preventDefault();
                        setStatement(e.target.value);
                    }}    
                />
            </InputGroup>
            <div style = {{width: '80%'}} className="text-right pt-2">
                <Button size="sm" type="submit">Edit</Button>
                <Button size="sm" type="reset">Cancel</Button>
            </div>
        </Form>
    </Fragment>
  );
};