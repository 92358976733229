import React, { useState, useContext, useEffect } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import GroupsDataService from "../../../services/GroupsDataService";
import { updateGroupProfile } from "../../state/groupProfileState/groupProfileActions";
import { useUserAuthSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import { Modal, Form, Button, Card, CardGroup, InputGroup} from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../state/GlobalState";
import {UDHR_ARTICLES} from '../../constants/UdhrArticles.js'
import * as Io from "react-icons/io";


import {
  handleInputChange,
  displayServerErrorMessagesInErrorDiv,
  displayInputErrorMessages,
  hideAllInputErrorMessages
} from "../utils/formUtils";

import {
  PASSWORD,
  REPEATED_PASSWORD,
} from "./GroupProfileFormFieldNames";

import * as fieldNames from "./GroupProfileFormFieldNames";
import { API_PROFILE_URL } from "../../constants/apiUrl";
import { clearAlerts, setSuccessAlerts, setNegativeAlerts, setLoadingAlertVisibility } from "../../state/alertsState/alertActions";
import { groupCategoriesCheckButtons } from "./groupCategoryCheckButtons";
import { isPropertyAccessOrQualifiedName } from "typescript";

function formatJsonDate(jsonDate) {
  return new Date(jsonDate.split("T")[0]).toLocaleDateString();
};

const StyledInputGroup = styled(InputGroup)`
  font-size: 1.2rem;
  size: "sm";
${props => props.active && `
    // color: red;
    // border-bottom: 3px solid green;
  `}
`;

export default () => {

  const [show, setShow] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const { userid, authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { addGroup, groups } = useContext(GlobalContext);

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    // updating an object instead of a Map
    setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
}

useEffect(() => {
  console.log("checkedItems: ", checkedItems);
}, [checkedItems]);

  const handleCreate = () => {

    let data = new FormData();
    data.append("name", "Group");
    data.append("username", "GroupUserName");
    data.append("description", "GroupDescription");

    GroupsDataService.createGroup(userid, data)
    .then(response => {
      console.log(response.data);
      addGroup(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  let history = useHistory();

  const [userDetails, setUserDetails] = useState({
    [fieldNames.NAME]: "",
    [fieldNames.USERNAME]: "",
    [fieldNames.EMAIL]: "",
    // [fieldNames.PASSWORD]: "",
    // [fieldNames.REPEATED_PASSWORD]: "",
  });

 
  const [groupCategories, setGroupCategories]  = useState([
    {id: 0, value: "General", isChecked: false},
    {id: 1, value: "Brotherhood", isChecked: false},
    {id: 2, value: "Equality", isChecked: false},
    {id: 3, value: "Life", isChecked: false},
    {id: 4, value: "Slavery", isChecked: false},
    {id: 5, value: "Torture", isChecked: false},
    {id: 6, value: "Recognition", isChecked: false},
    {id: 7, value: "Protection", isChecked: false},
    {id: 8, value: "Remedy", isChecked: false},
    {id: 9, value: "Arrest", isChecked: false},
    {id: 10, value: "Trial", isChecked: false},
    {id: 11, value: "Innocent", isChecked: false},
    {id: 12, value: "Privacy", isChecked: false},
    {id: 13, value: "Movement", isChecked: false},
    {id: 14, value: "Asylum", isChecked: false},
    {id: 15, value: "Nationality", isChecked: false},
    {id: 16, value: "Family", isChecked: false},
    {id: 17, value: "Property", isChecked: false},
    {id: 18, value: "Thought", isChecked: false},
    {id: 19, value: "Speach", isChecked: false},
    {id: 20, value: "Assambly", isChecked: false},
    {id: 21, value: "Election", isChecked: false},
    {id: 22, value: "Care", isChecked: false},
    {id: 23, value: "Work", isChecked: false},
    {id: 24, value: "Rest", isChecked: false},
    {id: 25, value: "Living standard", isChecked: false},
    {id: 26, value: "Education", isChecked: false},
    {id: 27, value: "Culture", isChecked: false},
    {id: 28, value: "Order", isChecked: false},
    {id: 29, value: "Duties", isChecked: false},
    {id: 30, value: "Exhaustive", isChecked: false}]
  );
 

  let udhr0 = groupCategories.slice(0, 16);
  let udhr15 = groupCategories.slice(16, 31);

  const CheckBox = props => {
    // console.log("In CheckBox", props.isChecked);
    return (
      <div>
        <input className="mr-1 pr-0" type="checkbox" key={props.id} checked={props.isChecked} value={props.value} onChange={props.handleCheckFieldElement}/> 
        {props.value}
      </div>
    )
  }

  const handleCheckFieldElement = (event) => {
    let categories= groupCategories;
    categories.forEach(category => {
      if (category.value === event.target.value)
          category.isChecked =  event.target.checked
    })
    setGroupCategories(Object.assign([{}], groupCategories, categories));
  }

  const handleAllChecked = (event) => {
      let categories = groupCategories;
      categories.forEach(category => category.isChecked = event.target.checked)
      setGroupCategories(Object.assign([{}], groupCategories, categories));
    }


    const updateFormElement = (e) =>
      handleInputChange(e, userDetails, setUserDetails);

    const handleStatusSelect  = (eventKey) => {
      console.log(eventKey);
    }

  const createGroupTopBtn = (
    (groups.length === 0) ? (null) : (
      <Button variant="primary"  onClick={handleShow}>
    Create new group
  </Button>
    )
    );

    const createGroupBottomBtn = (
      (groups.length === 0) ? (
        <Button variant="primary"  onClick={handleShow}>
      Create new group
    </Button>
      ) : (null)
    
  );


  return (

    <>

        
        {/* Display create new group-button on top if there are groups */}

        {createGroupTopBtn}

        {createGroupBottomBtn}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>GAR217A</Modal.Title>
          </Modal.Header>

          <Modal.Body>


          <Form
                  onSubmit={(e) => {

                    e.preventDefault();

                    hideAllInputErrorMessages([
                      fieldNames.NAME,
                      fieldNames.USERNAME,
                      fieldNames.EMAIL,
                      fieldNames.PASSWORD,
                      fieldNames.REPEATED_PASSWORD
                    ]);

                    let errorMessageFieldnames = [];

                    if (userDetails[fieldNames.NAME].length === 0) {
                      errorMessageFieldnames.push(fieldNames.NAME);
                    }

                    if (userDetails[fieldNames.USERNAME].length === 0) {
                      errorMessageFieldnames.push(fieldNames.USERNAME);
                    }

                    if (userDetails[fieldNames.EMAIL].length === 0) {
                      errorMessageFieldnames.push(fieldNames.EMAIL);
                    }

                    if (errorMessageFieldnames.length > 0) {
                      displayInputErrorMessages(errorMessageFieldnames);
                      return;
                    }

                    const submitBtn = document.getElementById(
                      fieldNames.SUBMIT
                    );

                    submitBtn.disabled = true;

                    document.getElementById(
                      fieldNames.FORM_ERROR_DIV_ID
                    ).innerHTML = "";

                    dispatch(setLoadingAlertVisibility("loading"));


                    let categoriesArray = [];

                    console.log("groupCategories", groupCategories);

                    
                    groupCategories.forEach(category => {
                      if (category.isChecked ) {
                        console.log(category);
                        categoriesArray.push(category.id);
                        }
                      }); 

                    console.log("checkData", categoriesArray);


                    let config = {
                      headers: {
                        "Authorization": `Token ${authToken}`
                      }
                    }

                    let data = new FormData();
                    data.append("name", userDetails[fieldNames.NAME]);
                    data.append("username", userDetails[fieldNames.USERNAME]);
                    data.append("email", userDetails[fieldNames.EMAIL]);
                    data.append("categories", categoriesArray.toString());

                    console.log("", data);

                    GroupsDataService.createGroup(userid, data)
                      .then(response => {

                        console.log(response.data);

                        dispatch(clearAlerts());

                              dispatch(
                          setSuccessAlerts([
                            `Group created!`
                          ])
                        );

                        submitBtn.disabled = false;

                        setShow(false);

                        const groupUser = { 'id': response.data.user.pk,
                                            'gar_avatar_name': response.data.user.gar_avatar_name,
                                            'username': response.data.user.username,
                                            'email': response.data.user.email,
                                            'created': response.data.user.created
                          }

                        // Setting state for the selcted Group
                        // The dispatch here, assumes response.data is loaded
                        // with relevant data from group

                        console.log("groupUser", groupUser)

                        dispatch(
                          updateGroupProfile(
                            response.data.pk,
                            response.data.id,
                            groupUser,
                            response.data.owner,
                            response.data.admin,
                            response.data.name,
                            response.data.description,
                            response.data.code,
                            response.data.owner.image
                          )
                        );

                        history.push({
                          pathname: '/group/profile/'
                        })

                        })
                      .catch(error => {
                        if (error["response"] !== undefined) {

                          dispatch(
                            setNegativeAlerts([
                              `Something went wrong. Profile not updated.`
                            ])
                          );

                          console.log(error.response);

                          displayServerErrorMessagesInErrorDiv(
                            fieldNames.FORM_ERROR_DIV_ID,
                            [error.response.data]
                          );

                          submitBtn.disabled = false;
                        } else {

                          console.log(error);

                          submitBtn.disabled = false;
                        }
                      })
                      .finally(() =>
                        dispatch(setLoadingAlertVisibility("finishing"))
                      );

                  }}
                >



                  <Form.Group className="mb-1" controlId="formName">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label
                          htmlFor={`${fieldNames.NAME}-formcontrol`}
                          id={`${fieldNames.NAME}-label`}
                          style={{fontSize: "0.9rem"}}
                          className="p-0"
                          >
                            Group name:
                        </label>
                      </Col>


                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.NAME}-formcontrol`}
                          name={fieldNames.NAME}
                          value={userDetails[fieldNames.NAME]}
                          type="text"
                          placeholder="Group name"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.NAME + "-error"}
                    >
                      Please enter the group name
                    </Form.Text>
                  </Form.Group>


                  <Form.Group className="mb-1" controlId="formUserName">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label
                          htmlFor={`${fieldNames.USERNAME}-formcontrol`}
                          id={`${fieldNames.USERNAME}-label`}
                          style={{fontSize: "0.9rem"}}
                          className="p-0"
                          >
                            Username:
                        </label>
                      </Col>


                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.USERNAME}-formcontrol`}
                          name={fieldNames.USERNAME}
                          value={userDetails[fieldNames.USERNAME]}
                          type="text"
                          placeholder="Username"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.USERNAME + "-error"}
                    >
                      Please enter a username
                    </Form.Text>
                  </Form.Group>


                  <Form.Group className="mb-1" controlId="formEmail">
                    <InputGroup size="sm">

                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label
                          htmlFor={`${fieldNames.EMAIL}-formcontrol`}
                          id={`${fieldNames.EMAIL}-label`}
                          style={{fontSize: "0.9rem"}}
                          className="p-0"
                          >
                            Email:
                        </label>
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.EMAIL}-formcontrol`}
                          name={fieldNames.EMAIL}
                          value={userDetails[fieldNames.EMAIL]}
                          type="email"
                          placeholder="Email address"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.EMAIL + "-error"}
                    >
                      Please enter your email address
                    </Form.Text>

                    </Form.Group>

{/* 

                    <Form.Group controlId={PASSWORD}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id={`${PASSWORD}-prepend`}>
                    <Io.IoMdLock />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name={PASSWORD}
                  type="password"
                  placeholder="Password"
                  value={userDetails[fieldNames.PASSWORD]}
                  onChange={updateFormElement}
                  required
                />
              </InputGroup>
              <Form.Text
                className="d-none text-danger"
                id={PASSWORD + "-error"}
              >
                Please choose a safer password. It should be at least 6
                characters long, contain lower and uppercase letters as well as
                at least one number.
              </Form.Text>
            </Form.Group>
          <Form.Group controlId={REPEATED_PASSWORD}>
            <Form.Label>Repeat Password</Form.Label>
            <Form.Control
              name={REPEATED_PASSWORD}
              type="password"
              placeholder="Password"
              value={userDetails[fieldNames.REPEATED_PASSWORD]}
              onChange={updateFormElement}
              required
            />
            <Form.Text
              className="d-none text-danger"
              id={REPEATED_PASSWORD + "-error"}
            >
              Passwords don't match
            </Form.Text>
          </Form.Group> 
 */}








                    <Form.Group className="mb-1" controlId="formGroupCategories">

                    <InputGroup>
                        <Form.Text as="p" className="mb-1">Choose one or more categories for your group (corelate to articles in the Universal Declaration of Human Rights):</Form.Text>
                        <div className="mb-2">
                        <input type="checkbox" onClick={handleAllChecked} value="checkedall" /> General (check all)
                        </div>
                        </InputGroup>

                    <div className="d-flex flex-row">
                    <Col className="p-1 border border-secondary">

                    <ul className="px-0">
                          {
                            udhr0.map((category) => {
                              return (<CheckBox handleCheckFieldElement={handleCheckFieldElement} {...category}  />)
                            })
                          }
                          </ul>

                    </Col>

                    <Col className="p-1 ml-2 border border-secondary">
                    <ul className="px-0">
                          {
                            udhr15.map((category) => {
                              return (<CheckBox handleCheckFieldElement={handleCheckFieldElement} {...category}  />)
                            })
                          }
                          </ul>



                    </Col>
                  </div>

                    </Form.Group> 


                  <Modal.Footer>

                    <Form.Group controlId="submit">

                      <Button variant="primary" type="button" size="sm" onClick={handleClose}>
                        Exit without saving
                      </Button>

                      <Button className="ml-1" id={fieldNames.SUBMIT} variant="primary" type="submit" size="sm">
                        Create group
                      </Button>

                      <h3>
                        <div id={fieldNames.FORM_ERROR_DIV_ID}>
                        </div>
                      </h3>

                    </Form.Group>

                  </Modal.Footer>

                </Form>

          {/* <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>

            <Button className="ml-3" variant="primary" onClick={handleCreate}>
              Create
            </Button>

          </Modal.Footer> */}

          </Modal.Body>
        </Modal>
      </>

  );
};
