export const ROOT_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000"
    : document.location.origin;

export const API_STATIC =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000"
    : document.location.origin;

export const API_MEDIA_URL =
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000"
      : document.location.origin;
  
export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/api/"
    : document.location.origin + "/api/";

export const API_RESTAUTH_URL = API_URL + "dj-rest-auth/";
export const API_REGISTRATION_URL = API_RESTAUTH_URL + "registration/";


// Specific endpoints
export const API_LOGIN_URL = API_RESTAUTH_URL + "login/";
export const API_LOGOUT_URL = API_RESTAUTH_URL + "logout/";
export const API_CONFIRM_EMAIL = API_REGISTRATION_URL + "verify-email/";

export const API_PASSWORD_REQUEST = API_RESTAUTH_URL + "password/";
export const API_PASSWORD_RESET_REQUEST = API_PASSWORD_REQUEST + "reset/";
export const API_PASSWORD_RESET_CONFIRM = API_PASSWORD_RESET_REQUEST + "confirm/";


export const API_PROFILE_URL = API_URL + "users/user/";
export const API_USER_LIST = API_RESTAUTH_URL + "users/";
export const API_CHANGE_PIC = API_URL + "users/userpic/";

export const API_STATEMENT_LIST = API_URL + "user/statements/";
export const API_STATEMENT_ADD_URL = API_URL + "users/user/statement/add/";
export const API_DELETE_URL = API_URL + "users/user/statement/";

export const API_REPLY_ADD_URL = API_URL + "users/user/reply/add/";
export const API_REACTION_ADD_URL = API_URL + "users/user/reaction/add/";
export const API_REACTION_URL = API_URL + "users/user/reaction/";

export const API_FRIENDS_LIST_URL = API_URL + "friends/";
export const API_FRIEND_ADD_URL = API_URL + "friends/";

export const API_FOLLOWS_URL = API_URL + "follows/follows/";
export const API_FOLLOWERS_URL = API_URL + "follows/followers/";

export const API_GROUP_URL = API_URL + "groups/";


export const API_PHOTO_ADD_URL = API_URL + "photo/add/";
export const API_PHOTO_ADD_PICTURE_URL = API_URL + "photo/add/picture/";
export const API_PHOTO_LIST_URL = API_URL + "photo/user/";
export const API_PHOTO_URL = API_URL + "photo/";


// friendship/ ^users/$ [name='friendship_view_users']
// friendship/ ^friends/(?P<username>[\w-]+)/$ [name='friendship_view_friends']
// friendship/ ^friend/add/(?P<to_username>[\w-]+)/$ [name='friendship_add_friend']
// friendship/ ^friend/accept/(?P<friendship_request_id>\d+)/$ [name='friendship_accept']
// friendship/ ^friend/reject/(?P<friendship_request_id>\d+)/$ [name='friendship_reject']
// friendship/ ^friend/cancel/(?P<friendship_request_id>\d+)/$ [name='friendship_cancel']
// friendship/ ^friend/requests/$ [name='friendship_request_list']
// friendship/ ^friend/requests/rejected/$ [name='friendship_requests_rejected']
// friendship/ ^friend/request/(?P<friendship_request_id>\d+)/$ [name='friendship_requests_detail']
// friendship/ ^followers/(?P<username>[\w-]+)/$ [name='friendship_followers']
// friendship/ ^following/(?P<username>[\w-]+)/$ [name='friendship_following']
// friendship/ ^follower/add/(?P<followee_username>[\w-]+)/$ [name='follower_add']
// friendship/ ^follower/remove/(?P<followee_username>[\w-]+)/$ [name='follower_remove']
// friendship/ ^blockers/(?P<username>[\w-]+)/$ [name='friendship_blockers']
// friendship/ ^blocking/(?P<username>[\w-]+)/$ [name='friendship_blocking']
// friendship/ ^block/add/(?P<blocked_username>[\w-]+)/$ [name='block_add']
// friendship/ ^block/remove/(?P<blocked_username>[\w-]+)/$ [name='block_remove'