import React from 'react'
import Banner from "../generic/Banner"
import UdhrBar from '../udhrBar/udhrBar'
import Friends from "../friends/Friends"
import Ngos from "../Ngos/Ngos"
import { Container, Col, Row } from "react-bootstrap";
import Feed from '../feed/Feed'
import { DontShowWhenLoggedIn, ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import Follows from '../follow/Follows'
import DataComponent from '../../../services/DataComponent'
import DataFriendsComponent from '../../../services/DataFriendsComponent'
import { AREA_PRIVATE, AREA_PUBLIC } from '../../constants/GlobalConstants'


function HomePage() {

  return (
    
      <React.Fragment>
          
          <DontShowWhenLoggedIn>
            <Banner id="home-public-banner"/>
              <UdhrBar area={ AREA_PUBLIC } id="home-udhrbar"/>
          </DontShowWhenLoggedIn>
          
          <ShowOnlyWhenLoggedIn>
            <UdhrBar area={ AREA_PRIVATE } id="home-udhrbar"/>
          </ShowOnlyWhenLoggedIn>
                    
          <Container id="home-main-container" className="mt-3">            
            
            <Row id="home-main-row" className="justify-content-center">
                
                <Col id="home-main-leftarea" md={2} className="p-0 d-none d-md-block">
                    <ShowOnlyWhenLoggedIn>
                        <Friends id="home-main-friends" />
                    </ShowOnlyWhenLoggedIn>
                </Col>
        
                <Col id="home-main-centerarea" sm={12} md={8} className="d-block justify-content-center p-0">                   
                    <DataComponent />
                    <div id="home-main-feed">
                      <Feed />                                 
                  </div>
                </Col>    

                <Col id="home-main-rightarea" md={2} className="p-0">
                  <div className="d-none d-md-block">
                    <ShowOnlyWhenLoggedIn>
                      <Follows list={true} isFollowers={true} id="home-main-ngos" />
                    </ShowOnlyWhenLoggedIn>
                  </div>
                </Col>          

            </Row>       

          </Container>
          
      </React.Fragment>

  );
}

export default HomePage;