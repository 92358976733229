import React, { useRef } from "react";
import axios from 'axios';
import {API_PROFILE_URL, API_CHANGE_PIC} from "../../constants/apiUrl";
import { useUserAuthSelector } from "../../state/typedSelectors";
import { useState } from 'react';
import { Form, Formfile, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import { ImageCropper } from "react-bootstrap-image-cropper";


function ImageCropperDemo(props) {
  // if you don't care the onChange event, you can use a ref to retrieve the cropped file
  const fileRef = useRef();

  function handleChange(croppedFile) {
    console.log("croppedFile", croppedFile);
    console.log("fileRef.current", fileRef.current);
    // croppedFile === fileRef.current
    props.setImage(fileRef.current);
  }

  return (
    <div>
      <h1>ImageCropper</h1>
      <ImageCropper
        fileRef={fileRef}
        onChange={handleChange}
        cropOptions={{ aspect: 1 / 1, maxZoom: 10 }}
        outputOptions={{ maxWidth: 165, maxHeight: 165 }}
        previewOptions={{ width: 400, height: 300 }}
        title="Crop Image"
      />
    </div>
  );
}



const UserPicture = () => {

  const fileRef = useRef();

  const dispatch = useDispatch();

  const [image, setImage] = useState({ image: null });
  const [title, setTitle] = useState({ title: '' });
  const [content, setContent] = useState({ content: '' });
  const [fileName, setFileName] = useState("Upload Boundary File");

    const { userid, username, authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );
  
  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    setImage(e.target.files[0])
  };

  function handleChange(croppedFile) {
    console.log("croppedFile", croppedFile);
    console.log("fileRef.current", fileRef.current);
    // croppedFile === fileRef.current
    setImage(croppedFile);
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    let form_data = new FormData();
    form_data.append('image', image, 'this.jpg');
    let url = API_PROFILE_URL+userid+"/";
    console.log("image", image);
    console.log("image file", image.file);
    console.log("form_data", form_data)
    axios.patch(url, form_data, {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Token ' + String(authToken)
      }
    })
        .then(res => {
          console.log(res.data);
          dispatch(
            updateUserProfile(
              res.data.image,
              )  
              );
          
        })
        .catch(err => console.log(err))
  };

    return (
      <div className="d-flex justify-content-center">

{/* 
        <Form
          onSubmit={handleSubmit}
        >
          <Form.File 
            className="mt-3"
            size="sm"
            id="custom-file"
            label={fileName}
            data-browse="Browse"
            feedback="Checking image"
            accept="image/png, image/jpeg"  
            onChange={(e) => {setFileName(e.target.files[0].name); setImage(e.target.files[0]);}}
            custom
            required
          />

          <div className=" text-right">
            <Button size="sm" variant="primary" type="submit" className="mt-3">
              Upload profile image
            </Button>
          </div>

        </Form>
 */}


      <Form
          onSubmit={handleSubmit}
        >

        <div>
          <ImageCropper
            fileRef={fileRef}
            onChange={handleChange}
            cropOptions={{ aspect: 1 / 1, maxZoom: 10 }}
            outputOptions={{ maxWidth: 165, maxHeight: 165 }}
            previewOptions={{ width: 400, height: 300 }}
          />
        </div>
        
        <div className=" m-5">
            <Button size="sm" variant="primary" type="submit" className="mt-3">
              New Upload
            </Button>
        </div>

      </Form>

      </div>
    );
  }

export default UserPicture;