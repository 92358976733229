import React from "react";

import UserAccessRoutes from "./userAccess/userAccessRoutes";
import PasswordResetRoutes from "./userAccess/passwordReset/passwordResetRoutes";
import FooterRoutes from "../footer/FooterRoutes";
import { Container } from 'react-bootstrap';

export default () => {
  return (
    <React.Fragment>
      <Container id="home-main-container" className="mt-5 justify-content-center text-center">            
          <UserAccessRoutes />
          <PasswordResetRoutes />
          <FooterRoutes />
      </Container>
    </React.Fragment>
  );
};
