import React from "react";
import { Nav, NavDropdown, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {API_MEDIA_URL, API_STATIC} from "../../constants/apiUrl"

import { sendLogoutRequest } from "../../state/userAuthState/userAuthActions";
import { useUserAuthSelector, useUserProfileSelector } from "../../state/typedSelectors";

import * as linkItems from "../views/userAccess/userAccessLinks";
import { homeLink, profileLink } from "../views/contentPageLinks";

import {
  ShowOnlyWhenLoggedIn,
  DontShowWhenLoggedIn
} from "../utils/elementWrappers/authBasedVisibilityWrappers";

export default () => {
  const dispatch = useDispatch();

  const {authToken, username} = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { image } = useUserProfileSelector(
    state => state.userProfileReducer
    );     
    
  let localImage = null;

  if (image) {
    localImage = image;
    if (localImage.search('http') === -1) {
      localImage = API_MEDIA_URL + image;
      // console.log("LocalTmage", localImage);
    } else {
      localImage = image;
    } 
  }

  return (
    
    <div className="col-12 col-lg-3 d-block d-lg-flex flex-row justify-content-end p-0 mr-0">
          
      <DontShowWhenLoggedIn>
      
        <LinkContainer to={linkItems.login.link}>
          <Nav.Link eventKey={linkItems.login.link}>
            {linkItems.login.icon}
            {linkItems.login.displayName}
          </Nav.Link>
        </LinkContainer>
      
        <LinkContainer to={linkItems.register.link}>
          <Nav.Link eventKey={linkItems.register.link}>
            {linkItems.register.icon}
            {linkItems.register.displayName}
          </Nav.Link>
        </LinkContainer>
      
      </DontShowWhenLoggedIn>
            
      <ShowOnlyWhenLoggedIn>

        <Image
            src={localImage}
            // src={API_STATIC+image}
            width="28"
            height="28"
            className="d-inline-block mt-2 p-0"
            rounded
          />

        <NavDropdown title={(username) ? username : "Menu"} id="basic-nav-dropdown" className="m-0 p-0">                                           


              <NavDropdown.Item as="li">
                <LinkContainer style={{padding: "0px"}}to={profileLink.link}>
                  <Nav.Link eventKey={profileLink.link}>
                    {profileLink.icon}
                    {profileLink.displayName}
                  </Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>

              <NavDropdown.Divider />

              <NavDropdown.Item as="li">
                <Nav.Link 
                  onClick={() => {
                  dispatch(sendLogoutRequest(authToken));
                  dispatch(push(homeLink.link));  
                  }}
                >
                
                {linkItems.logout.icon}
                {linkItems.logout.displayName}
  
                </Nav.Link>            
              </NavDropdown.Item>

        </NavDropdown>

      </ShowOnlyWhenLoggedIn>
    </div>
  );
};
