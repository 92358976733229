import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

export default () => 

{
    return(
        <Container>
            <Row className="d-flex justify-content-center">
                <Col xs={7}>
                    <Card className="w-100">
                        <Card.Title className="m-2 text-center">Page Not Found 404</Card.Title>
                        <Card.Text className="m-2">Not able to locate the specfied document.</Card.Text>
                        <Card.Text className="m-2">Please check the spelling and try again!</Card.Text>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}