import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import {API_MEDIA_URL} from "../../constants/apiUrl";
import { Container, Col, Row, Card, Button, Image } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector, useUserProfileSelector } from "../../state/typedSelectors";
import { useHistory } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import FriendsDataService from "../../../services/FriendsDataService";
import ReactRoundedImage from "react-rounded-image";


const stickStyle = {
  fontSize: "0.9rem"
}

const FriendRequestStick = ({friend, user_id}, { match, location }) => {
    
  const user = friend;

  console.log("FRIEND", friend);
  console.log("USER_ID", user_id);

    const { authToken } = useUserAuthSelector(
        state => state.userAuthReducer
    );
   
    const [acceptedFriendsships, setAcceptedFriendships] = useState([]);
    const [accepted, setAccepted] = useState(false);
    const [rejected, setRejected] = useState(false);
  
    let history = useHistory();


    const view = (e) => {

      let user_id = e.target.dataset.pk;

      history.push({
        pathname: '/user/'+user_id,
        state: { localObject: "friendRequests" }
      })
      
    };
    

    const acceptFriendship = (e) => {

      var axios = require('axios');

        var data = new FormData();
        data.append('user_id', String(e.target.dataset.pk));
        data.append('message', '"hello"');

        var config = {
          method: 'get',
          url: `http://127.0.0.1:8000/api/friendrequests/${friend.pk}/accept/?user_id=${user_id}`,
          headers: { 
            'Authorization': 'Token ' + String(authToken)
        },
          data : data
        };
          

        axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            setAccepted(true);
        })
        .catch(function (error) {
            console.log(error);
        });

    };

    const rejectFriendship = (e) => {

      var axios = require('axios');

        var data = new FormData();
        data.append('user_id', String(e.target.dataset.pk));
        data.append('message', '"hello"');

        var config = {
          method: 'get',
          url: `http://127.0.0.1:8000/api/friendrequests/${friend.pk}/reject/?user_id=${user_id}`,
          headers: { 
            'Authorization': 'Token ' + String(authToken)
        },
          data : data
        };          

        axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            setRejected(true);
        })
        .catch(function (error) {
            console.log(error);
        });

    };

  const uName = (friend.first_name) ? 
        (<div>{friend.first_name} {friend.last_name}</div>):
        (<div>{friend.username}</div>)

    const uStatus = (friend.status) ? 
    (<Card.Text className="m-0">Status: {friend.status}</Card.Text>): null

    const uUsername = (friend.username) ? 
    (<div className="m-0">@{friend.username}</div>): null

    const uLivingIn = (friend.living_in) ? 
        (<Card.Text className="m-0">Living in: {friend.living_in}</Card.Text>): null

    const uCity = (friend.city) ? 
        (<Card.Text className="m-0">City: {friend.city}</Card.Text>): null

    return (
      <React.Fragment>                    
        
        
        <Card className="mb-2 p-1 border border-light-dark">

          <Row className="d-block d-flex flex-row">
            <Col sm={1} style={stickStyle}>
              <ReactRoundedImage 
                image={API_MEDIA_URL+user.image} 
                imageWidth="42"
                imageHeight="42"
                roundedSize="0"
              />
            </Col>
            <Col  md="auto" style={stickStyle}>
                  {uName}
            </Col>


            <Col sm={3} style={stickStyle}>
                  {user.city}, {user.living_in}
            </Col>

            <Col sm={4} style={{marginLeft: 'auto'}} className="d-flex justify-content-center align-items-center">

              <Button 
                    variant={(!accepted) ? "primary" : "success"}
                    id={"friendrequeststick-accept-btn-"+user.pk}
                    size="sm" 
                    className="ml-1" 
                    data-pk={user.pk}
                    onClick={acceptFriendship}>
                    {(accepted) ? "Accepted" : "Accept"}
                </Button>

                <Button 
                    variant={(!rejected) ? "primary" : "danger"}
                    id={"friendrequeststick-reject-btn-"+user.pk}
                    size="sm" 
                    className="ml-1" 
                    data-pk={user.pk}
                    onClick={rejectFriendship}>
                    {(rejected) ? "Rejected" : "Reject"}
                </Button>

                <Button id="userstick-follow-btn" size="sm" className="ml-1" data-pk={user.pk} onClick={view}>View</Button>
              </Col>


          </Row>
        </Card>
      </React.Fragment>

    );
  };

export default FriendRequestStick;