import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Card, InputGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { API_LOGIN_URL } from "../../constants/apiUrl";
import { useUserAuthSelector } from "../../state/typedSelectors";
import { sendLogoutRequest } from "../../state/userAuthState/userAuthActions";
import { login } from "../views/userAccess/userAccessLinks";
import DataService from "../../../services/DataService";


export default ( {user} ) => {

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const { userid, authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

  const handleDeleteAccount = () => {   

    DataService.deleteUser(userid)
    .then(response => {
      console.log("Dataservice, user deleted", response.data);
      dispatch(sendLogoutRequest(authToken));
      dispatch(push(login.link));
      })
    .catch(e => {
     console.log(e);
     });  

  }

  return (

    <>
        
        <Button variant="secondary" onClick={handleShow}>
          Close account
        </Button>        
        
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>GAR217A</Modal.Title>
          </Modal.Header>
          <Modal.Body>

        <div className="d-flex flex-column align-items-center">
          <p><h4>Your account will be closed.</h4></p><br></br>
          <p><h1>Confirm?</h1></p>
        </div>
    
    </Modal.Body>
          <Modal.Footer>
            
            <Button className="ml-3" variant="primary" onClick={handleClose}>
              Stay
            </Button>

            <Button variant="primary" onClick={handleDeleteAccount}>
              Close Account
            </Button>
            

          </Modal.Footer>
        </Modal>
      </>

  );
};
