import React from 'react';

function Velcome(){
    return(
      <div>
        <h2 style={{textAlign: "center"}} id="txt">Velcome to GAR217A</h2>
        <p className="mt-5" style={{textAlign: "center"}}> <a href="/register/">Sign up</a> or <a href="/login/">log in</a> to start sharing and engaging in Human Rights!</p>
        <p style={{textAlign: "center"}}>Follow and invite your friends and others. </p>
      </div>
    )
  }

export default Velcome;