import * as userTypes from "./groupProfileTypes";

export const initialState: userTypes.GroupProfileState = {
  pk: "",
  id: "",
  user: {id: "", gar_avatar_name: "", username: "", email: "", created: ""},
  owner: "",
  admin: "",
  name: "",
  established: "",
  description: "",
  code: "",
  image: "",
  country: "",
  city: "",
  homepageViewAllowed: "",
  homepageAddAllowed: ""
};

export default (
  previousState: userTypes.GroupProfileState = initialState,
  action: userTypes.UpdateGroupProfileAction
): userTypes.GroupProfileState => {
  // console.log("action.type", action.type);
  switch (action.type) {
    case userTypes.UPDATE_GROUP_PROFILE:
      {
        // console.log(userTypes.UPDATE_GROUP_PROFILE, action.payload);
        return action.payload;
      }

    }
  return previousState;
};
