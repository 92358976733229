import React, { SyntheticEvent, useState } from "react";
import axios from "axios";
import { Modal, Form, Button, Card, CardGroup, InputGroup} from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";


import {
  handleInputChange,
  displayServerErrorMessagesInErrorDiv,
  displayInputErrorMessages,
  hideAllInputErrorMessages
} from "../../utils/formUtils";

import * as fieldNames from "./UserProfileFormFieldNames";
import { API_PROFILE_URL } from "../../../constants/apiUrl";
import { clearAlerts, setSuccessAlerts, setNegativeAlerts, setLoadingAlertVisibility } from "../../../state/alertsState/alertActions";
import { useUserAuthSelector, useUserProfileSelector } from "../../../state/typedSelectors";
import { updateUserProfile } from "../../../state/userProfileState/userProfileActions";

function formatJsonDate(jsonDate: string) {
  return new Date(jsonDate.split("T")[0]).toLocaleDateString();
}; 

const StyledInputGroup = styled(InputGroup)`
  font-size: 1.2rem;
  size: "sm";
${props => props.active && `
    // color: red;
    // border-bottom: 3px solid green;
  `}
`;

interface UserFormDetails {
  [fieldNames.USERNAME]: string;
  [fieldNames.FIRSTNAME]: string;
  [fieldNames.LASTNAME]: string;
  [fieldNames.EMAIL]: string;
  [fieldNames.GAR217A_AVATAR_NAME]: string;
  [fieldNames.IMAGE]: string;
  [fieldNames.CREATED]: string;
  [fieldNames.STATUS]: string;
  [fieldNames.DATE_OF_BIRTH]: string;
  [fieldNames.BIO]: string;
  [fieldNames.BORN_IN]: string;
  [fieldNames.LIVING_IN]: string;
  [fieldNames.CITY]: string;
  [fieldNames.LAST_SAVED]: string;
}

export default () => {

    
  let history = useHistory();

  const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

    const { username, first_name, last_name, email, userid, image, authToken} = useUserAuthSelector(
      state => state.userAuthReducer
    );
  
    const { ufirst_name, ulast_name, uemail, date_of_birth, status, gar_avatar_name, 
      created, born_in, living_in, city, bio, last_saved } = useUserProfileSelector(
      state => state.userProfileReducer
    );  
  
    const [userDetails, setUserDetails] = useState<UserFormDetails>({
      [fieldNames.USERNAME]: String(username),
      [fieldNames.FIRSTNAME]: String(ufirst_name),
      [fieldNames.LASTNAME]: String(ulast_name),
      [fieldNames.EMAIL]: String(uemail),
      [fieldNames.GAR217A_AVATAR_NAME]: String(gar_avatar_name),
      [fieldNames.IMAGE]: String(image),
      [fieldNames.CREATED]: String(created),
      [fieldNames.STATUS]: String(status),
      [fieldNames.DATE_OF_BIRTH]: String(date_of_birth),
      [fieldNames.BORN_IN]: String(born_in),
      [fieldNames.LIVING_IN]: String(living_in),
      [fieldNames.CITY]: String(city),
      [fieldNames.BIO]: String(bio),
      [fieldNames.LAST_SAVED]: String(last_saved)
    });
    
    const dispatch = useDispatch();
    
    const updateFormElement = (e: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) =>
      handleInputChange(e, userDetails, setUserDetails);
  
    const handleStatusSelect  = (eventKey: string) => {
      console.log(eventKey);
    }

    // console.log(formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH]));
    console.log(userDetails[fieldNames.DATE_OF_BIRTH]);

    return (
      <>
        <Button variant="primary" size="sm" onClick={handleShow}>
          Edit profile
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Profile form</Modal.Title>
          </Modal.Header>
          <Modal.Body> 
  
          
                <Form 
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    
                    e.preventDefault();
                    
                    hideAllInputErrorMessages([
                      fieldNames.DATE_OF_BIRTH,
                      fieldNames.FIRSTNAME,
                      fieldNames.LASTNAME,
                      fieldNames.EMAIL
                    ]);
                    
                    let errorMessageFieldnames = [];
                    
                    if (userDetails[fieldNames.DATE_OF_BIRTH].length === 0) {
                       errorMessageFieldnames.push(fieldNames.DATE_OF_BIRTH);
                     }

                    if (userDetails[fieldNames.FIRSTNAME].length === 0) {
                      errorMessageFieldnames.push(fieldNames.FIRSTNAME);
                    }
                    
                    if (userDetails[fieldNames.LASTNAME].length === 0) {
                      errorMessageFieldnames.push(fieldNames.LASTNAME);
                    }
                    
                    if (userDetails[fieldNames.EMAIL].length === 0) {
                      errorMessageFieldnames.push(fieldNames.EMAIL);
                    }
                    
                    if (errorMessageFieldnames.length > 0) {
                      displayInputErrorMessages(errorMessageFieldnames);
                      return;
                    }
                    
                    const submitBtn = document.getElementById(
                      fieldNames.SUBMIT
                    ) as HTMLInputElement;
                    submitBtn.disabled = true;
                    (document.getElementById(
                      fieldNames.FORM_ERROR_DIV_ID
                    ) as HTMLElement).innerHTML = "";
                    
                    dispatch(setLoadingAlertVisibility("loading"));
                   
                    let config = {
                      headers: {
                        "Authorization": `Token ${authToken}`
                      }
                    }

                    let data = {
                      first_name: userDetails[fieldNames.FIRSTNAME],
                      last_name: userDetails[fieldNames.LASTNAME],
                      email: userDetails[fieldNames.EMAIL],
                      status: userDetails[fieldNames.STATUS],
                      date_of_birth: (userDetails[fieldNames.DATE_OF_BIRTH]) ? ((userDetails[fieldNames.DATE_OF_BIRTH])) : "",
                      bio: userDetails[fieldNames.BIO],
                      born_in: userDetails[fieldNames.BORN_IN],
                      living_in: userDetails[fieldNames.LIVING_IN],
                      city: userDetails[fieldNames.CITY],
                      last_saved: String(new Date(Date.now()))
                    }                                        
                    
                    axios
                    .put(API_PROFILE_URL+userid+"/", data, config)
                      .then(response => {
                        
                        console.log(response.data);

                        dispatch(clearAlerts());
                        
                        dispatch(
                          updateUserProfile(
                            response.data.username,
                            response.data.first_name,
                            response.data.last_name,
                            response.data.email,
                            response.data.gar_avatar_name,
                            response.data.image,
                            response.data.last_seen,
                            response.data.online,
                            response.data.created,
                            response.data.status,
                            response.data.date_of_birth,
                            response.data.born_in,
                            response.data.living_in,
                            response.data.city,
                            response.data.bio,
                            response.data.last_saved,
                            response.data.profile_completed_percent,
                            response.data.number_of_logins,
                            response.data.education,
                            response.data.education_place,
                            response.data.occupation,
                            response.data.position,
                            response.data.first_login,
                            response.data.homepageViewAllowed,
                            response.data.homepageAddAllowed                    
                      )  
                        );
                              dispatch(
                          setSuccessAlerts([
                            `Profile updated!`
                          ])
                        );


                        submitBtn.disabled = false;

                        setShow(false);

                        })
                      .catch(error => {
                        if (error["response"] !== undefined) {

                          dispatch(
                            setNegativeAlerts([
                              `Something went wrong. Profile not updated.`
                            ])
                          );
  
                          console.log(error.response.data);

                          displayServerErrorMessagesInErrorDiv(
                            fieldNames.FORM_ERROR_DIV_ID,
                            [error.response.data]
                          );

                          submitBtn.disabled = false;
                        } else {
                          
                          console.log(error);

                          submitBtn.disabled = false;
                        }
                      })
                      .finally(() =>
                        dispatch(setLoadingAlertVisibility("finishing"))
                      );

                  }}
                >                  

                  
                  <Form.Group className="mb-1" controlId="formFirstname">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.FIRSTNAME}-formcontrol`}
                          id={`${fieldNames.FIRSTNAME}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            First name:
                        </label>                                            
                      </Col>

  
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.FIRSTNAME}-formcontrol`}
                          name={fieldNames.FIRSTNAME}
                          value={userDetails[fieldNames.FIRSTNAME]}
                          type="text"
                          placeholder="First name"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.FIRSTNAME + "-error"}
                    >
                      Please enter your first name
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formLastname">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.LASTNAME}-formcontrol`}
                          id={`${fieldNames.LASTNAME}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Last name:
                        </label>                                            
                      </Col>

  
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.LASTNAME}-formcontrol`}
                          name={fieldNames.LASTNAME}
                          value={userDetails[fieldNames.LASTNAME]}
                          type="text"
                          placeholder="Last name"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.LASTNAME + "-error"}
                    >
                      Please enter your last name
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formEmail">
                    <InputGroup size="sm">
                      
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.EMAIL}-formcontrol`}
                          id={`${fieldNames.EMAIL}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Email:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.EMAIL}-formcontrol`}
                          name={fieldNames.EMAIL}
                          value={userDetails[fieldNames.EMAIL]}
                          type="email"
                          placeholder="Email address"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.EMAIL + "-error"}
                    >
                      Please enter your email address
                    </Form.Text>
                  
                  </Form.Group>



                  <Form.Group className="mb-1 " controlId="formDateOfBirth">
                    <InputGroup size="sm">
                      
                      <Col className="m-0 p-0 d-flex flex-column">
                        <label 
                          htmlFor={fieldNames.DATE_OF_BIRTH + "-formcontrol"} 
                          id={`${fieldNames.DATE_OF_BIRTH}-prepend`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Born:
                        </label>                                            
                      </Col>

                      <Col className="d-flex flex-column justify-content-center">
                        <Form.Control
                          size="sm"
                          // id={fieldNames.DATE_OF_BIRTH + "-formcontrol"}
                          name={fieldNames.DATE_OF_BIRTH}
                          value={userDetails[fieldNames.DATE_OF_BIRTH]}
                          // value={formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH])}
                          type="date"
                          placeholder="Date of birth"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.DATE_OF_BIRTH + "-error"}
                    >
                      Please enter your date of birth
                    </Form.Text>
                  </Form.Group> 


                  <Form.Group className="mb-1" controlId="formStatus">
                    <InputGroup size="sm">
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.STATUS}-formcontrol`}
                          id={`${fieldNames.STATUS}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Status:
                        </label>                                            
                      </Col>


                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.STATUS}-formcontrol`}
                          name={fieldNames.STATUS}
                          value={userDetails[fieldNames.STATUS]}
                          placeholder="Status"
                          as="select" 
                          // defaultValue={userDetails[fieldNames.STATUS]}
                          onSelect={(e: any) =>
                          console.log("Dropdown onSelect - eventKey", e)}                            
                          onChange={updateFormElement}
                            >
                          <option>Choose...</option>
                          <option>Single</option>
                          <option>Married</option>
                          <option>Related</option>
                        </Form.Control>                        
                      </Col>

                      </InputGroup>


                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.STATUS + "-error"}
                    >
                      Please enter your status
                    </Form.Text>
                  </Form.Group>



                  <Form.Group className="m-1" controlId="formBornIn">
                    <InputGroup size="sm">                    

                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">                                         
                        <label 
                          htmlFor={`${fieldNames.BORN_IN}-formcontrol`}
                          id={`${fieldNames.BORN_IN}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                          Born in:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.BORN_IN}-formcontrol`}
                          name={fieldNames.BORN_IN}
                          value={userDetails[fieldNames.BORN_IN]}
                          type="text"
                          placeholder="Born in"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.BORN_IN + "-error"}
                    >
                      Please enter your country of origin
                    </Form.Text>
                  
                  </Form.Group>


                  <Form.Group className="m-1" controlId="formLivingIn">
                    <InputGroup size="sm">
                      
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.LIVING_IN}-formcontrol`}
                          id={`${fieldNames.LIVING_IN}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Living in:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.LIVING_IN}-formcontrol`}
                          name={fieldNames.LIVING_IN}
                          value={userDetails[fieldNames.LIVING_IN]}
                          type="text"
                          placeholder="Living in"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.LIVING_IN + "-error"}
                    >
                      Please enter the country you live in
                    </Form.Text>
                  
                  </Form.Group>


                  <Form.Group className="mb-1" controlId="formCity">
                    <InputGroup size="sm">
                      
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.CITY}-formcontrol`}
                          id={`${fieldNames.CITY}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            City:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.CITY}-formcontrol`}
                          name={fieldNames.CITY}
                          value={userDetails[fieldNames.CITY]}
                          type="text"
                          placeholder="City"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.CITY + "-error"}
                    >
                      Please enter the city you live in
                    </Form.Text>
                  
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formBio">
                    <InputGroup size="sm">
                      
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-start">
                        <label 
                          htmlFor={`${fieldNames.BIO}-formcontrol`}
                          id={`${fieldNames.BIO}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Bio:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">

                      <textarea 
                        rows={5} 
                        cols={40}
                        placeholder="Bio..."                         
                        name={fieldNames.BIO}
                        value={userDetails[fieldNames.BIO]}
                        onChange={updateFormElement}
                        style={{border: "1px solid LightGray", color: "grey", padding: "5px 5px", outlineColor: "red!important"}}
                      />

                      </Col>

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.BIO + "-error"}
                    >
                      A little bit about your self
                    </Form.Text>
                  
                  </Form.Group>

                  <Modal.Footer>
                    
                    <Form.Group controlId="submit">
                    
                      <Button variant="primary" type="button" size="sm" onClick={handleClose}>
                        Close
                      </Button>
                      
                      <Button className="ml-1" id={fieldNames.SUBMIT} variant="primary" type="submit" size="sm">
                        Save Changes
                      </Button>
                    
                      <h3>
                        <div id={fieldNames.FORM_ERROR_DIV_ID}>
                        </div>
                      </h3>
                  
                    </Form.Group>

                  </Modal.Footer>

                  {/* <Form.Group controlId="submit">
                    <Button id={fieldNames.SUBMIT} variant="primary" type="submit" size="sm">
                      Save
                    </Button>
                  
                    <h3>
                      <div id={fieldNames.FORM_ERROR_DIV_ID}>
                      </div>
                    </h3>
                  
                  </Form.Group> */}

                </Form> 

          
              </Modal.Body>          
        </Modal>
      </>
    );
  }
  