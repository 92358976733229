import { http } from "./http-common";


class UserDataService {


    getUserByName(searchString) {
        return http.get(`/users/search/?search_string=${searchString}`);
      }

    getPhotos(userid) {
      return http.get(`/photo/list/${userid}`);
    }
  

}

export default new UserDataService();