export const STICKTYPE_FRIENDS = "friends";
export const STICKTYPE_FRIENDREQUESTS = "friendRequests";
export const STICKTYPE_USER_SEARCH = "userSearch";
export const STICKTYPE_FOLLOWERS = "followers";
export const STICKTYPE_FOLLOWEES = "following";
export const STICKTYPE_BLOCKS = "blocks";
export const STICKTYPE_GROUPS = "groups";
export const STICKTYPE_PROJECTS = "projects";
export const STICKTYPE_ENGAGE = "udhr_groups";

export const ACTION_ADD_FRIENDREQUEST = "addFriendRequest";
export const ACTION_ACCEPT_FRIENDREQUEST = "acceptFriendRequest";
export const ACTION_REJECT_FRIENDREQUEST = "rejectFriendRequest";
export const ACTION_REMOVE_FRIEND = "removeFriend";
export const ACTION_ADD_FOLLOWER = "addFollower";
export const ACTION_REMOVE_FOLLOWER = "removeFollower";
export const ACTION_ADD_FOLLOWEE = "addFollowee";
export const ACTION_REMOVE_FOLLOWEE = "removeFollowee";
export const ACTION_BLOCK = "block";
export const ACTION_UNBLOCK = "unblock";
export const ACTION_ADD_GROUP = "addGroup";
export const ACTION_REMOVE_GROUP = "removeGroup";
