import React from 'react';
import { ListGroup } from 'react-bootstrap';

const FollowList = (props) => {
  const { repos } = props;
  if (!repos || repos.length === 0) return (<h6>FollowList No items, sorry</h6>);
  return (
      <div className="d-flex flex-row justify-content-center">
        {repos.map((repo) => {
          return (
            <div key={repo.id} className="d-flex justify-content-center">
              <ListGroup.Item style={{textAlign: "center"}} key={repo.pk} id={repo.pk} >
                {repo.last_name}
              </ListGroup.Item>
            </div>
          );
        })}
      </div>
  );
};
export default FollowList;