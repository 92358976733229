
import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./web/state/store";
import App from "./web/components/App";
import 'bootstrap/dist/css/bootstrap.min.css';
import { unregister } from './serviceWorker';
import { disableReactDevTools } from './web/components/utils/DisableReactDevTools'

const store = configureStore();

if (process.env.NODE_ENV === "production") disableReactDevTools();

ReactDOM.render(
   <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode> ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
