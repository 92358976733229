import React, { useEffect, useState } from 'react';
import FollowList from '../follow/FollowList';
import withListLoading from '../utils/WithListLoading';
import axios from 'axios';
import Friend from '../friends/Friend'
import UserStick from '../user/UserStick'
import FollowingStick from '../follow/FollowingStick'
import ListGroup from 'react-bootstrap/ListGroup';
import { useUserAuthSelector } from "../../state/typedSelectors";

function Groups({isFollowers}) {
    
  const ListLoading = withListLoading(FollowList);
    
  const [follows, setFollows] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );    

  useEffect(() => {

    if (authToken) {
    
      const follow_url = (isFollowers) ? "follows/" : "followers/";

      setLoading(true);

      const url = 'http://127.0.0.1:8000/api/follows/'+follow_url;

      axios
       .get(url, {
         headers: { Authorization: `Token ${authToken}` } 
       })
      .then(response => {         
        console.log("Follows", response.data);
        const newFollows = response.data;
        setFollows(newFollows);
        setLoading(false);
      })
      .catch(error => console.log(error)); 
    }
  
  }, [setFollows, setLoading, authToken, isFollowers])

  if (!follows || follows.length === 0) return <p>No followers, sorry</p>;

  
  return (
    
    <div><h1>Groups</h1> </div>
    
    // <div className="Friends">
    //   <ListGroup>
    //     <h4 className="mb-5" style={{color: "grey", textAlign: "center"}} >{(isFollowers) ? "You are currently following" : "These are your followers"}</h4>      
        
    //     {follows.map((follow) => (
    //         <div key={follow.pk}>
    //           <FollowingStick user={follow} />
    //         </div>
    //       ))}

    //   </ListGroup>
    // </div>

);
}

export default Groups;