import React ,{ useContext, useState, useEffect} from 'react'
// import UserBanner from "./UserBanner"
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Col, Row, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import { GlobalContext } from "../../state/GlobalState";
import GroupProfileRoutes from "./GroupProfileRoutes"
import GroupProfileMenu from './GroupProfileMenu'
import {  followersLink, followsLink, groupsLink, projectsLink } from "../user/UserProfileLinks";
import { groupProfileLink, groupSettingsLink, groupMembersLink, groupRequestMembershipLink } from './groupProfileLinks';
import UserWelcomeModal from '../user/UserWelcomeModal';
import { useUserAuthSelector, useUserProfileSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import FollowersDataService from '../../../services/FollowersDataService';
import FriendsDataService from '../../../services/FriendsDataService';
import DataComponent from '../../../services/DataComponent';
import GroupDataComponent from '../../../services/GroupDataComponent';
import { SiteMap } from '../utils/SiteMap';

const StyledLink = styled(Nav.Link)`
color: grey;
font-size: 1.0rem;
margin-top: 2px;
${props => props.active && `
    //color: blue;
    //border-bottom: 3px solid green;
  `}
`;

function GroupProfilePage( {match, location} ) {

  const [showWelcomeModal, setShowWelcomeModal] = useState(true); 

  const { userid, authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );  

  const { first_login } = useUserProfileSelector(
    state => state.userProfileReducer
  );  

  const { name } = useGroupProfileSelector(
    state => state.groupProfileReducer
  );  



  const { followers, loadFollowers, followees, loadFollowees, blockers, loadBlockers } = useContext(GlobalContext);

  console.log(name);


  return (
    
    <React.Fragment>
        
        <GroupDataComponent />

        <Container id="home-main-container" className="mt-3">            
          
          <Row id="home-main-row" className="pl-0 justify-content-center">
              
              {/* UserProfileMenu on md-viewports */}
              <Col id="home-main-leftarea" md={2} className="d-none d-md-block ml-0 p-0" style={{borderStyle: "solid", borderWidth: "0px 1px 0px 0px", borderColor: "lightgrey"}}>
                  <GroupProfileMenu />
              </Col>

              
              {/* UserProfileMenu on small/mobile viewports  */}
              
              <div className="pl-1 d-md-none d-flex flex-row justify-content-center">

                <DropdownButton className="mb-3" variant="secondary" id="dropdown-basic-button" title="Profile menu">
                                       
                    <Dropdown.Item >
                      <LinkContainer to={groupProfileLink.link}>
                        <StyledLink eventKey={groupProfileLink.link}>
                          {groupProfileLink.displayName}
                        </StyledLink>
                      </LinkContainer>
                    </Dropdown.Item>


                    <Dropdown.Item >
                    <LinkContainer to={groupSettingsLink.link}>
                <StyledLink eventKey={groupSettingsLink.link}>
                  {groupSettingsLink.displayName}
                </StyledLink>
              </LinkContainer>
                    </Dropdown.Item>


                    <Dropdown.Item >
                    <LinkContainer to={groupMembersLink.link}>
                <StyledLink eventKey={groupMembersLink.link}>
                  {groupMembersLink.displayName}
                </StyledLink>
              </LinkContainer>
                    </Dropdown.Item>

                    <Dropdown.Item >
                    <LinkContainer to={groupRequestMembershipLink.link}>
                <StyledLink eventKey={groupRequestMembershipLink.link}>
                  {groupRequestMembershipLink.displayName}
                </StyledLink>
              </LinkContainer>
                    </Dropdown.Item>


                    <Dropdown.Item >
                    <LinkContainer to={followersLink.link}>
                <StyledLink eventKey={followersLink.link}>
                  {followersLink.displayName}
                </StyledLink>
              </LinkContainer>
                    </Dropdown.Item>


                    <Dropdown.Item >
                    <LinkContainer to={followsLink.link}>
                <StyledLink eventKey={followsLink.link}>
                  {followsLink.displayName}
                </StyledLink>
              </LinkContainer>
                    </Dropdown.Item>


                    {/* <Dropdown.Item >
                    <LinkContainer to={projectsLink.link}>
                <StyledLink eventKey={projectsLink.link}>
                  {projectsLink.displayName}
                </StyledLink>
            </LinkContainer>
                    </Dropdown.Item>


                    <Dropdown.Item >
                    </Dropdown.Item> */}



                </DropdownButton>
              </div>


              <Col id="userprofile-main-centerarea" sm={9} xl={9} className="ml-md-5 ml-xs-2d-flex flex-column">                   
                <div > 
                  
                  {/* {(showWelcomeModal) ? <UserBanner setShowBanner={setShowBanner}/> : null} */}

                  {(first_login) ? <UserWelcomeModal setShowWelcomeModal={setShowWelcomeModal}/> : null}
                                  
                </div>
                  <div id="userprofile-main">
                    
                    
                      {/* TODO: Add sitemap */}
                    {/* <SiteMap /> */}


                    <h1 style = {{fontSize: "1.4rem", color: "grey" }} className="text-left mb-3" >Group pages for  {name}</h1>
                    <GroupProfileRoutes />
                </div>
              </Col>    

              {/* <Col id="home-main-rightarea" sm={2} md={2}  className="p-0"> */}
                {/* <div className="d-none d-md-block"> */}
                  {/* <ShowOnlyWhenLoggedIn> */}
                  {/* </ShowOnlyWhenLoggedIn> */}
                {/* </div> */}
              {/* </Col>           */}

          </Row>       

        </Container>
        
    </React.Fragment>

);
}

export default GroupProfilePage;