import React, { SyntheticEvent, useState } from "react";
import axios from "axios";
import * as Io from "react-icons/io";
import { Form, Button, Card, CardGroup, InputGroup, Table } from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import UserPicture from "../../user/UserPicture";
import AddPhoto from "../../photos/AddPhoto";
import Video from "../../videos/Video";
import PhotoAlbum from '../../photos/PhotoAlbum';
import styled from "styled-components";

// import FormWrapper from "../userAccess/formWrapper";

import {
  handleInputChange,
  displayServerErrorMessagesInErrorDiv,
  displayInputErrorMessages,
  hideAllInputErrorMessages
} from "../../utils/formUtils";

import * as fieldNames from "./UserProfileFormFieldNames";
import { API_PROFILE_URL, API_STATIC } from "../../../constants/apiUrl";
import { clearAlerts, setSuccessAlerts, setNegativeAlerts, setLoadingAlertVisibility } from "../../../state/alertsState/alertActions";
import { useUserAuthSelector, useUserProfileSelector } from "../../../state/typedSelectors";
import { updateUserProfile } from "../../../state/userProfileState/userProfileActions";
import UserInfoModal from '../../user/UserInfoModal'

function formatJsonDate(jsonDate: string) {
  return new Date(jsonDate.split("T")[0]).toLocaleDateString();
}; 

const StyledInputGroup = styled(InputGroup)`
  font-size: 1.2rem;
  size: "sm";
${props => props.active && `
    // color: red;
    // border-bottom: 3px solid green;
  `}
`;

interface UserFormDetails {
  [fieldNames.USERNAME]: string;
  [fieldNames.FIRSTNAME]: string;
  [fieldNames.LASTNAME]: string;
  [fieldNames.EMAIL]: string;
  [fieldNames.GAR217A_AVATAR_NAME]: string;
  [fieldNames.IMAGE]: string;
  // [fieldNames.LAST_SEEN]: string;
  // [fieldNames.ONLINE]: string;
  [fieldNames.CREATED]: string;
  [fieldNames.STATUS]: string;
  [fieldNames.DATE_OF_BIRTH]: string;
  [fieldNames.BIO]: string;
  [fieldNames.BORN_IN]: string;
  [fieldNames.LIVING_IN]: string;
  [fieldNames.CITY]: string;
  [fieldNames.LAST_SAVED]: string;
}

export default () => {
       
  const { username, first_name, last_name, email, userid, image, authToken} = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { ufirst_name, ulast_name, uemail, date_of_birth, status, gar_avatar_name, 
    created, born_in, living_in, city, bio, last_saved } = useUserProfileSelector(
    state => state.userProfileReducer
  );  

  const [userDetails, setUserDetails] = useState<UserFormDetails>({
    [fieldNames.USERNAME]: String(username),
    [fieldNames.FIRSTNAME]: String(ufirst_name),
    [fieldNames.LASTNAME]: String(ulast_name),
    [fieldNames.EMAIL]: String(uemail),
    [fieldNames.GAR217A_AVATAR_NAME]: String(gar_avatar_name),
    [fieldNames.IMAGE]: String(image),
    [fieldNames.CREATED]: String(created),
    [fieldNames.STATUS]: String(status),
    [fieldNames.DATE_OF_BIRTH]: String(date_of_birth),
    [fieldNames.BORN_IN]: String(born_in),
    [fieldNames.LIVING_IN]: String(living_in),
    [fieldNames.CITY]: String(city),
    [fieldNames.BIO]: String(bio),
    [fieldNames.LAST_SAVED]: String(last_saved)
  });
  
  const dispatch = useDispatch();
  
  const updateFormElement = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(e, userDetails, setUserDetails);

  const handleStatusSelect  = (eventKey: string) => {
    console.log(eventKey);
  }


  const Select = (): JSX.Element => {
    const [selectValue, setSelectValue] = useState<string>(
        "optionA",
    );
    return (
        <select
            value={selectValue}
            onBlur={(
                ev: React.ChangeEvent<HTMLSelectElement>,
            ): void => setSelectValue(ev.target.value)}
        >
            <option value="optionA">Option A</option>
            <option value="optionB">Option B</option>
            <option value="optionC">Option C</option>
        </select>
    );
};


const renderEmail = 
  <>
    <Col md={4} className="ml-0 pt-1 pl-1 d-flex flex-column" >
    <label 
      htmlFor={`${fieldNames.EMAIL}-formcontrol`}
      id={`${fieldNames.EMAIL}-label`} 
      style={{fontSize: "0.9rem"}}  
      >
        Email:
    </label>                                            
    </Col>

    <Col md={8} >
    <Form.Control
      id={`${fieldNames.EMAIL}-formcontrol`}
      name={fieldNames.EMAIL}
      value={userDetails[fieldNames.EMAIL]}
      size="sm"
      type="email"
      placeholder="Email address"
      onChange={updateFormElement}
      className = "border-0 bg-white"
      readOnly
    />
    </Col>
  </>


  return (
    
    <Container className="mt-3 p-0 border border-dark d-flex justify-content-center">

      <Col sm="11" className="d-flex flex-row p-0 justify-content-center border border-danger">

      <CardGroup className="border border-warning w-100 d-block m-0 p-2">        
        <Row>
          <Col className="justify-content-centre">

            <Card className="shadow">            
              <Card.Body>
                <Card.Title as="h2">{first_name} {last_name}</Card.Title>


                <Table size="sm" style={{fontSize: "0.9rem"}}>
                    <tr className="mb-1">
                      <td> <Card.Text>GarAvatarId:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.GAR217A_AVATAR_NAME]}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>Joined:</Card.Text></td>
                      <td><Card.Text>{formatJsonDate(userDetails[fieldNames.CREATED])}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>Username:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.USERNAME]}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>Firstname:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.FIRSTNAME]}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>Lastname:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.LASTNAME]}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>Age:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.DATE_OF_BIRTH]}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>Living in:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.LIVING_IN]}</Card.Text></td>
                    </tr>
                    <tr className="mb-1">
                      <td><Card.Text>City:</Card.Text></td>
                      <td><Card.Text>{userDetails[fieldNames.CITY]}</Card.Text></td>
                    </tr>
                </Table>



                {/* <Form 
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    
                    e.preventDefault();
                    
                    hideAllInputErrorMessages([
                      fieldNames.DATE_OF_BIRTH,
                      fieldNames.FIRSTNAME,
                      fieldNames.LASTNAME,
                      fieldNames.EMAIL
                    ]);
                    
                    let errorMessageFieldnames = [];
                    
                    if (userDetails[fieldNames.DATE_OF_BIRTH].length === 0) {
                       errorMessageFieldnames.push(fieldNames.DATE_OF_BIRTH);
                     }

                    if (userDetails[fieldNames.FIRSTNAME].length === 0) {
                      errorMessageFieldnames.push(fieldNames.FIRSTNAME);
                    }
                    
                    if (userDetails[fieldNames.LASTNAME].length === 0) {
                      errorMessageFieldnames.push(fieldNames.LASTNAME);
                    }
                    
                    if (userDetails[fieldNames.EMAIL].length === 0) {
                      errorMessageFieldnames.push(fieldNames.EMAIL);
                    }
                    
                    if (errorMessageFieldnames.length > 0) {
                      displayInputErrorMessages(errorMessageFieldnames);
                      return;
                    }
                    
                    const submitBtn = document.getElementById(
                      fieldNames.SUBMIT
                    ) as HTMLInputElement;
                    submitBtn.disabled = true;
                    (document.getElementById(
                      fieldNames.FORM_ERROR_DIV_ID
                    ) as HTMLElement).innerHTML = "";
                    
                    dispatch(setLoadingAlertVisibility("loading"));
                   
                    let config = {
                      headers: {
                        "Authorization": `Token ${authToken}`
                      }
                    }

                    console.log(image);

                    let data = {
                      // username: userDetails[fieldNames.USERNAME],
                      first_name: userDetails[fieldNames.FIRSTNAME],
                      last_name: userDetails[fieldNames.LASTNAME],
                      email: userDetails[fieldNames.EMAIL],
                      gar_avatar_name: userDetails[fieldNames.GAR217A_AVATAR_NAME],
                      image: userDetails[fieldNames.IMAGE],
                      // last_seen: userDetails[fieldNames.LAST_SEEN],
                      // online: userDetails[fieldNames.ONLINE],
                      // created: userDetails[fieldNames.CREATED],
                      status: userDetails[fieldNames.STATUS],
                      date_of_birth: userDetails[fieldNames.DATE_OF_BIRTH],
                      bio: userDetails[fieldNames.BIO],
                      born_in: userDetails[fieldNames.BORN_IN],
                      living_in: userDetails[fieldNames.LIVING_IN],
                      city: userDetails[fieldNames.CITY],
                      last_saved: String(new Date(Date.now()))
                    }                                        
                    
                    axios
                    .put(API_PROFILE_URL+userid+"/", data, config)
                      .then(response => {
                        
                        dispatch(clearAlerts());
                        
                        dispatch(
                          updateUserProfile(
                            // response.data.username,
                            response.data.first_name,
                            response.data.last_name,
                            response.data.email,
                            response.data.gar_avatar_name,
                            // response.data.image,
                            response.data.last_seen,
                            response.data.online,
                            response.data.created,
                            response.data.status,
                            response.data.date_of_birth,
                            response.data.bio,
                            response.data.born_in,
                            response.data.living_in,
                            response.data.city,
                            response.data.last_saved
                          )  
                        );                      

                        dispatch(
                          setSuccessAlerts([
                            `Profile updated!`
                          ])
                        );

                        submitBtn.disabled = false;

                        })
                      .catch(error => {
                        if (error["response"] !== undefined) {

                          dispatch(
                            setNegativeAlerts([
                              `Something went wrong. Profile not updated.`
                            ])
                          );
  
                          console.log(error.response.data);

                          displayServerErrorMessagesInErrorDiv(
                            fieldNames.FORM_ERROR_DIV_ID,
                            [error.response.data]
                          );

                          submitBtn.disabled = false;
                        } else {
                          
                          console.log(error);

                          submitBtn.disabled = false;
                        }
                      })
                      .finally(() =>
                        dispatch(setLoadingAlertVisibility("finishing"))
                      );

                  }}
                >                  

                  <div className="d-none d-md-flex pl-0">
                    {renderEmail}
                  </div>


                  <div className="d-none d-flex pl-0">
                    <Col className="ml-0 pt-1 pl-1 d-flex flex-column">
                        <label 
                          htmlFor={`${fieldNames.EMAIL}-formcontrol`}
                          id={`${fieldNames.EMAIL}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          >
                            Email:
                        </label>                                            
                      </Col>

                      <Col className=" flex-strech">
                        <Form.Control
                          id={`${fieldNames.EMAIL}-formcontrol`}
                          name={fieldNames.EMAIL}
                          value={userDetails[fieldNames.EMAIL]}
                          size="sm"
                          type="email"
                          placeholder="Email address"
                          onChange={updateFormElement}
                          className = "border-0 bg-white"
                          readOnly
                        />
                      </Col>
                  </div>
                  
                  <Form.Group as={Row} controlId="formFirstname" className="ml-1 mb-1 w-100 border border-danger">      
                    <InputGroup size="sm" className="border border-success">                    
                      
                      <Form.Label 
                        id={`${fieldNames.FIRSTNAME}-prepend`} 
                        column="sm" sm={6} md={3} 
                        className="p-0 m-0 align-self-center border border-primary"
                      >
                        First name:
                      </Form.Label>
                      
                      <Col sm={2} md={2} className="border border-warning p-0 justify-content-end" style={{marginLeft: 'auto'}}>                          
                        <Form.Control size="sm" as="input" type="text" className="p-0 m-0 border border-dark bg-white"
                          name={fieldNames.FIRSTNAME}
                          value={userDetails[fieldNames.FIRSTNAME]}
                          onChange={updateFormElement}
                          readOnly
                        />
                        <Form.Text
                          className="d-none text-danger"
                          id={fieldNames.FIRSTNAME + "-error"}
                          >
                          Please enter your firstname
                        </Form.Text>
                      </Col>

                    </InputGroup>            
                  </Form.Group>

                  <Form.Group className="m-1" controlId="formLastname">

                  <label htmlFor="basic-url">First name:</label>
                  <InputGroup size="sm">
                        <Form.Label id={`${fieldNames.LASTNAME}-prepend`}
                        column="sm" sm={6} md={3}
                        >
                          Last name
                        </Form.Label>
                      <Col sm={4} md={6} className="border border-warning p-0 justify-content-end" style={{marginLeft: 'auto'}}>
                      <Form.Control
                        id="basic-url"
                        name={fieldNames.LASTNAME}
                        value={userDetails[fieldNames.LASTNAME]}
                        type="lastname"
                        placeholder="Lastname"
                        onChange={updateFormElement}
                      />
                      </Col>
                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.LASTNAME + "-error"}
                    >
                      Please enter your last name
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="m-1" controlId="formEmail">
                  <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text id={`${fieldNames.EMAIL}-prepend`}>
                          Email
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={fieldNames.EMAIL}
                        value={userDetails[fieldNames.EMAIL]}
                        type="email"
                        placeholder="Email"
                        onChange={updateFormElement}
                      />
                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.EMAIL + "-error"}
                    >
                      Please enter your email address
                    </Form.Text>
                  </Form.Group>


                  <Form.Group className="mb-1" controlId="formEmail">
                    <InputGroup size="sm">
                      
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.EMAIL}-formcontrol`}
                          id={`${fieldNames.EMAIL}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Email:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          id={`${fieldNames.EMAIL}-formcontrol`}
                          name={fieldNames.EMAIL}
                          value={userDetails[fieldNames.EMAIL]}
                          type="email"
                          placeholder="Email address"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.EMAIL + "-error"}
                    >
                      Please enter your email address
                    </Form.Text>
                  </Form.Group>



                  <Form.Group className="mb-1 " controlId="formDateOfBirth">
                    <InputGroup size="sm">
                      
                      <Col className="m-0 p-0 d-flex flex-column">
                        <label 
                          htmlFor="dob-url" 
                          id={`${fieldNames.DATE_OF_BIRTH}-prepend`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            First name:
                        </label>                                            
                      </Col>

                      <Col className="d-flex flex-column justify-content-center">
                        <Form.Control
                          size="sm"
                          id="dob-url"
                          name={fieldNames.DATE_OF_BIRTH}
                          value={formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH])}
                          type="date"
                          placeholder="Date of birth"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.DATE_OF_BIRTH + "-error"}
                    >
                      Please enter your date of birth
                    </Form.Text>
                  </Form.Group> 


                  <Form.Group className="mb-1" controlId="formDateOfBirth">
                    <InputGroup size="sm">
                      
                      <Col className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={fieldNames.DATE_OF_BIRTH + "-formcontrol"} 
                          id={`${fieldNames.DATE_OF_BIRTH}-prepend`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Born:
                        </label>                                            
                      </Col>

                      <Col className="d-flex flex-column justify-content-center">
                        <Form.Control
                          size="sm"
                          id={fieldNames.DATE_OF_BIRTH + "-formcontrol"}
                          name={fieldNames.DATE_OF_BIRTH}
                          value={formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH])}
                          type="date"
                          placeholder="Date of birth"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.DATE_OF_BIRTH + "-error"}
                    >
                      Please enter your date of birth
                    </Form.Text>
                  </Form.Group>



                  <Form.Group className="m-1" controlId="formStatus">
                  <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text id={`${fieldNames.STATUS}-prepend`}>
                          Status
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                        <Form.Control 
                        name={fieldNames.STATUS}
                        value={userDetails[fieldNames.STATUS]}
                        as="select" 
                          defaultValue={userDetails[fieldNames.STATUS]}
                          onSelect={(e: any) =>
                          console.log("Dropdown onSelect - eventKey", e)}                            
                          onChange={updateFormElement}
                            >
                          <option>Choose...</option>
                          <option>Single</option>
                          <option>Married</option>
                          <option>Related</option>
                        </Form.Control>
                      </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.STATUS + "-error"}
                    >
                      Please enter your status
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="m-1" controlId="formBornIn">
                  <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text id={`${fieldNames.BORN_IN}-prepend`}>
                          Born in
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={fieldNames.BORN_IN}
                        value={userDetails[fieldNames.BORN_IN]}
                        type="dropdown"
                        placeholder="Born in"
                        onChange={updateFormElement}
                      />
                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.BORN_IN + "-error"}
                    >
                      Please enter the country you where born in.
                    </Form.Text>
                  </Form.Group>
                  

                  <Form.Group className="m-1" controlId="formLivingIn">
                  <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text id={`${fieldNames.LIVING_IN}-prepend`}>
                          Living in 
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={fieldNames.LIVING_IN}
                        value={userDetails[fieldNames.LIVING_IN]}
                        type="text"
                        placeholder="Living in"
                        onChange={updateFormElement}
                      />
                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.LIVING_IN + "-error"}
                    >
                      Please enter the country you are living in
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="m-1" controlId="formCity">
                  <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text id={`${fieldNames.CITY}-prepend`}>
                          City
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={fieldNames.CITY}
                        value={userDetails[fieldNames.CITY]}
                        type="text"
                        placeholder="City"
                        onChange={updateFormElement}
                      />
                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.CITY + "-error"}
                    >
                      Please enter your status
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="m-1" controlId="formBio">
                  
                    <InputGroup size="sm">
                    
                        <InputGroup.Prepend>
                          <InputGroup.Text id={`${fieldNames.BIO}-prepend`}>
                            Bio
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                    
                        <Form.Control
                          name={fieldNames.BIO}
                          value={userDetails[fieldNames.BIO]}
                          type="text"
                          // placeholder="Bio"
                          onChange={updateFormElement}
                        />

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.BIO + "-error"}
                    >
                      Please enter your bio
                    </Form.Text>
                  
                  </Form.Group>

                  <Form.Group controlId="submit">
                    <Button id={fieldNames.SUBMIT} variant="primary" type="submit">
                      Save
                    </Button>
                  
                    <h3>
                      <div id={fieldNames.FORM_ERROR_DIV_ID}>
                      </div>
                    </h3>
                  
                  </Form.Group>

                </Form>  */}
              </Card.Body>
          </Card>
        </Col>
        
        <Col className="d-flex flex-row justify-content-center">
          <Card>
            <Card.Body>
              <Card.Img src={API_STATIC+image} style={{width: '165px', height: '165px', marginLeft: 'auto'}} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Card>
            {/* <PhotoAlbum /> */}
            {/* <UserPicture /> */}
            {/* <AddPhoto /> */}
            {/* <UserInfoModal /> */}
            {/* <Video /> */}
        </Card>  
      </Row>


    </CardGroup>    
              
    </Col>
    </Container>
  );
};
