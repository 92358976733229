import React, { useContext, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import FriendRequests from '../friends/FriendRequests'
import StickList from '../generic/stick/StickList'
import GroupProfileSettings from "./GroupProfileSettings"
import Project from "../project/Project";
import GroupProfile from './GroupProfile';
import { useGroupProfileSelector } from "../../state/typedSelectors";


import {  groupProfileLink, 
          groupSettingsLink, 
          groupRequestMembershipLink,
          groupMembersLink,
          groupFollowersLink,
          groupFollowsLink,
          groupBlockLink,
          groupProjectsLink 
           } from './groupProfileLinks';

import { 
  STICKTYPE_USER_SEARCH,
  STICKTYPE_FRIENDREQUESTS, 
  STICKTYPE_FRIENDS,
  STICKTYPE_FOLLOWERS,
  STICKTYPE_FOLLOWEES,
  STICKTYPE_BLOCKS,
  STICKTYPE_GROUPS,
  STICKTYPE_PROJECTS
 } from "../../constants/stickTypes";

 import { GlobalContext } from "../../state/GlobalState";

export default ({ match, location }) => {

  const { users, 
          friends, 
          friend_requests, 
          followers, 
          followees, 
          blockers, 
          groups,
          group_members,
          group_membership_requests,
          group_followers,
          group_followees,
          group_blockers,
          group_projects } = useContext(GlobalContext);

  const { pk, user } = useGroupProfileSelector(
    state => state.groupProfileReducer
  );         

  useEffect(() => {
    //effect
    return () => {
      //cleanup
    }
  }, [location])

  console.log("GROUP_ID____WHAT IS THIS_____:", pk, user.id, user);

  return (
    <React.Fragment>  

      <Switch location={location}>
        
        <Route exact path={groupProfileLink.link} component={GroupProfile} />
        <Route exact path={groupSettingsLink.link} component={GroupProfileSettings} />
        <Route exact path={groupMembersLink.link}>
          <StickList stickType={STICKTYPE_FRIENDS} stickListText="Members:" objectList = {group_members} objectType="groups"/>
        </Route> 
        <Route exact path={groupRequestMembershipLink.link}>
          <FriendRequests objectType="groups" user_id={user.id} />
        </Route>
        <Route exact path={groupFollowersLink.link}>
          <StickList stickType={STICKTYPE_FOLLOWERS} stickListText="Group followers:" objectList={group_followers} objectType="groups"/>
        </Route>
        <Route exact path={groupFollowsLink.link}>
          <StickList stickType={STICKTYPE_FOLLOWEES} stickListText="Your group is currently following:" objectList = {group_followees} objectType="groups"/>
        </Route>
        <Route path={groupBlockLink.link}>
          <StickList stickType={STICKTYPE_BLOCKS} stickListText="Blocked users:" objectList = {group_blockers} objectType="groups"/>
        </Route>
        {/* <Route path={groupProjectsLink.link} component={Project} />         */}
      
      </Switch>

    </React.Fragment>
  );
};
