import React from "react";
import * as Io from "react-icons/io";

import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { LinkItem } from "../utils/navLinkInterface";


export const homeLink: LinkItem = {
    link: "/home",
    displayName: "Home",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

export const searchLink: LinkItem = {
  link: "/search",
  displayName: "Search",
  icon: <IconWrapper Icon={Io.IoIosSearch} />
};
  
export const profileLink: LinkItem = { 
  link: "/user/profile",
  displayName: "Profile",
  icon: <IconWrapper Icon={Io.IoIosContact} />
};
  
 export const stateLink: LinkItem = {
    link: "/state",
    displayName: "State",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

  export const groupsLink: LinkItem = {
    link: "/group/profile",
    displayName: "Groups",
    icon: <IconWrapper Icon={Io.IoIosPeople} />
  };

  export const userLink: LinkItem = {
    link: "/user/:id",
    displayName: "User",
  };

  export const friendLink: LinkItem = {
    link: "/user/:id",
    displayName: "FRIEND",
  };

  export const friendsLink: LinkItem = {
    link: "/user/profile/friends",
    displayName: "Friends",
    icon: <IconWrapper Icon={Io.IoIosPerson} />
  };


  export const helpCenterLink: LinkItem = {
    link: "/helpcenter",
    displayName: "Helpcenter",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };


  export const DataPolicyLink: LinkItem = {
    link: "/helpcenter/datapolicy",
    displayName: "Data Policy",
    icon: <IconWrapper Icon={Io.IoIosPrint} />
  };

  export const TermsOfUseLink: LinkItem = {
    link: "/helpcenter/termsofuse",
    displayName: "Terms of Use",
    icon: <IconWrapper Icon={Io.IoIosSave} />
  };

  export const CreditsLink: LinkItem = {
    link: "/helpcenter/credits",
    displayName: "Credits",
    icon: <IconWrapper Icon={Io.IoIosApps} />
  };

  
  export const GuidelinesLink: LinkItem = {
      link: "/helpcenter/communityguidelines",
      displayName: "Guidelines",
      icon: <IconWrapper Icon={Io.IoIosBook} />
  };