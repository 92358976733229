import React, { useContext, useEffect, useState } from "react";
import { Nav, ListGroup, Image, Card, Dropdown, DropdownButton, Button, Row, Col } from "react-bootstrap";
import ReactRoundedImage from "react-rounded-image";
import { Link, Route, Switch } from "react-router-dom";
import Likes from '../reaction/Likes';
import Share from './Share';
import Reply from './Reply';
import Udhr from "../udhr/Udhr";
import { AddReply } from '../statements/addReply';
import { EditStatement } from './EditStatement';
import LoginModal from '../views/userAccess/login/LoginModal'
import {AREA_PUBLIC, AREA_PRIVATE, AREA_UDHR} from '../../constants/GlobalConstants'
import StatementsDataService from "../../../services/StatementsDataService";
import { useUserAuthSelector } from "../../state/typedSelectors";
import { GlobalContext } from "../../state/GlobalState";
import {timeSince} from '../utils/dateTime'
import { isBlank, isEmpty } from '../utils/gString'
import { API_MEDIA_URL } from '../../constants/apiUrl'
import * as Io from "react-icons/io";
import IconWrapper from "../utils/elementWrappers/IconWrapper";
import Favourite from "../reaction/Favourite";


const Statement = ({statement, area, thisEditStatement }, { match, location }) => {

//   const statement = props.statement;

  
    
    const [show, setShow] = useState(false);
    const [isReplyActive, setReplyActive] = useState(false);
    const [isEditActive, setEditActive] = useState(false);
    const [isShareActive, setShareActive] = useState(false);
    const [currentStatement, setCurrentStatement] = useState(null);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [likes, setLikes] = useState(null);
    const [liked, setLiked] = useState(false);
    const [reaction, setReaction] = useState(0);

  const disableFunctions = (area === AREA_PUBLIC) ? true : false;

  const { statements, addStatement, removeStatement, editStatement, editUDHRStatement, loadStatements } = useContext(GlobalContext);
  
  const { userid, sessionActive, authToken, loginTime, image, online }= useUserAuthSelector(
    state => state.userAuthReducer
  );

  let statementsState = null;

    // set state to update
    switch (area) {
        case AREA_UDHR:
            statementsState = editUDHRStatement;
            break;
        default: statementsState = editStatement;
        }
    

  var found = [];


  useEffect(() => {

        if (!!statement.reactions) {
    // if (statement.reactions.length > 0) {
        // console.log("Statement - Likes?", statement);
        found = statement.reactions.find(function (reaction) {                
            if (reaction.created_by === userid) {
                // console.log(reaction.created_by);

                if (reaction.reaction === 1)
                    setLiked(true);

                setReaction(reaction);
                
                return reaction;
            } 
        })
    
    } else {
        found = [];
    }
    return () => {
            
    }
  }, [statement])

   
const retrieveStatements = () => {
    StatementsDataService.getAll(userid)
    .then(response => {
        loadStatements(response.data);
    })
    .catch(e => {
    console.log(e);
    });   
};

const handleShare = (e) => {

    if (!sessionActive) {
        e.preventDefault();
        setShowLoginModal(true);  
    } else {
        setCurrentStatement(e.currentTarget.id);
        setShareActive(true);
    }
}

const handleReply = (e) => {

    if (!sessionActive) {
        setShowLoginModal(true);  
    } else {

    setCurrentStatement(e.currentTarget.id);
    setReplyActive(true);
    }
}

const handleLike = (e) => { 

    if (!sessionActive) {
        // check in user if not logged in
        setShowLoginModal(true);  
    } else {

        // we are logged in - allowed to engage

        const statementId = parseInt(e.currentTarget.dataset.id);
        const currentNoLikes = parseInt(e.currentTarget.dataset.likes_count);
        let newNoLikes = (liked) ? currentNoLikes-1: currentNoLikes+1;

        //variable to hold function
        let updateLike = ""

        if (liked) {
            console.log("liked should be true", liked, reaction)
            updateLike = StatementsDataService.deleteReaction(reaction.id);
            // setLiked(false);
        } else {
            
            const newReaction = {
                userid: userid,
                created_by: userid,
                reaction_to: e.currentTarget.dataset.id,
                reaction: 1
            };                         
                
            updateLike = StatementsDataService.createReaction(newReaction);
            console.log("liked should be false", liked)
            // setLiked(true);
        }

        updateLike
            .then(response => {
                const statementUpdatedNoOfLikes = {likes_count: newNoLikes};
                            
                let newReactions = response.data
                
                console.log("statement", statement);
                
                StatementsDataService.update(statementId, statementUpdatedNoOfLikes)
                .then(response => {
                    const newLikedStatus=!liked;
                    if (newReactions.reaction === 1)  setLiked(newLikedStatus);

                    const newStatement = {
                        ...statement,
                        likes_count: newNoLikes,
                        reactions: [newReactions]
                    }

                    
                    // switch (area) {
                    //     case AREA_UDHR:
                    //         editUDHRStatement();
                    // }
                                    
                     statementsState(newStatement);

                    // console.log("statements retrieved");

                    })   
                .catch(error => {
                if (error["response"] !== undefined) {
                    console.log(error.response);
                } else {
                }});
            })
            .catch(error => {
            if (error["response"] !== undefined) {
                console.log(error.response);
            } else {
            }
            })

        }
}

const handleEdit = (e) => {
    setCurrentStatement(e.currentTarget.id);
    setEditActive(true);
}


  const showReplyComponent = (statement) => {
    if (isReplyActive && currentStatement === String(statement.id))
        return <AddReply statement={statement} setReplyActive={setReplyActive} area={area} thisEditStatement={thisEditStatement}/>;
}
  
const showEditStatement = (statement) => {
    if (isEditActive && currentStatement === String(statement.id))
        return <EditStatement statement={statement} setEditActive={setEditActive}/>;
}

const showShareComponent = (statement) => {
    if (isShareActive && currentStatement === String(statement.id)){
        console.log("showShareComponent", statement);
        return <Share statement={statement} setShareActive={setShareActive}/>;}
}

const showLikeButton = (statement) => {
    if (userid != String(statement.user.pk))
        return (
            <Button className="text-right" size="sm" data-id={statement.id} data-likes_count={statement.likes_count} data-statement={statement} onClick={handleLike}>
                Like
            </Button>
        )
}

const showReplyButton = (statement) => {
    if (userid != String(statement.user.pk))
        return (
            <Button id={statement.id} data-id={statement.id} className="text-right ml-1" size="sm" onClick={handleReply}>
                Reply
            </Button>    
        )
}


const showDeleteButton = (statement) => {
    if (userid === String(statement.user.pk))
        return (
            <Button id={statement.id} className="text-right ml-1" size="sm" onClick={() => {
                StatementsDataService.delete(statement.id);
                removeStatement(statement.id)}}>
            Delete
            </Button>

        )
}

const showEditButton = (statement) => {
    if (userid === String(statement.user.pk))
        return (
            <Button id={statement.id} className="text-right ml-1" size="sm" onClick={handleEdit}>
            Edit
        </Button>
        )
}



if (!statement.user) return null;

let localImage = "";
let localFullName = "";
let localUserName = "";
let localOnline = false

if ((statement.user && statement.user.image)) {
    localImage=statement.user.image;
    localFullName = statement.user.full_name;
    localUserName = statement.user.username;
    localOnline = (!(statement.user && statement.user.online)) ? "" : statement.user.online;
    if ( localImage.search('http') === -1) {
        localImage = API_MEDIA_URL + statement.user.image;
    }
} else {
    localImage="";
}

// let localImage = (!(statement.user && statement.user.image)) ? "" : statement.user.image;
// Using this as a workaround to read
// to read the CREATOR of the Statement
// no USER. Since CREATOR Statemens "belongs"
// ti the USER when posted on USERS wall.
// Change 21. April 2021



// if (!!statement.user) {
    // if (!!statement.user.image) {
    
    // localFullName = statement.created_by.full_name;
    // localUserName = statement.created_by.username;
    // localOnline = statement.created_by.online;
 
 

    // if ( localImage.search('http') === -1)
    //     {
    //         console.log("Needs API_MEDIA_URL", statement.user.image);
            // localImage = API_MEDIA_URL + statement.created_by.image;
            // localImage = API_MEDIA_URL + statement.user.image;
            // localImage = API_MEDIA_URL + statement.user.image;
        // } else {
            // console.log("Image URL ok", statement.user.image);
            // localImage = statement.created_by.image;
            // localImage = statement.user.image;
//         }
// }


  return (

    <div className="p-0 mb-3 d-flex justify-content-center">

        {(showLoginModal) ? (<LoginModal show={showLoginModal} setShowLoginModal={setShowLoginModal} />) : null}

                    
        <ListGroup.Item as="li" key={statement.id} id={"statement-list-item-"+statement.id} className=" p-0 m-0 w-100">

          {/* The statement Card.... */}

          <Card className="p-0 m-0 ">

              <Card.Header style={{backgroundColor: "white"}} className="border-0 ml-2 mr-2 mb-0 pb-0">
                  
                  <Row className="pl-0">
                          
                      <Col xs={3} md="auto" className="m-0 p-0">

                              <ReactRoundedImage 
                                  image={localImage} 
                                  imageWidth="48"
                                  imageHeight="48"
                                //   roundedSize="0"
                                  roundedSize={localOnline ? '6' : '0'}
                                  borderRadius="15"
                              />                                            

                      </Col>

                      <Col xs="auto" className="ml-md-1 mr-1 p-0">
                          
                          <Row className="ml-1 mr-1 d-flex flex-column flex-md-row">
                              
                              <Col md="auto" className="m-0 p-0">                                            
                                  <p style={{fontWeight: "bold"}} className="m-0 p-0">
                                                  {(localFullName) ? localFullName : localUserName}
                                  </p>                                            
                              </Col>   

                              <Col md="auto" className="m-0 ml-md-1 p-0">
                                  <p className="m-0 p-0">
                                      {timeSince(new Date(statement.created))} ago
                                  </p>                                            
                              </Col>

                          </Row>
                      </Col>
                    
                    <div className="d-flex flex-row ml-auto justify-content-end">
                        <Col xs={2} md="auto" className=" m-0 p-0">
                            <Favourite user_id={userid} statement={statement} udhr={statement.statement_to_udhr} area={area} />
                        </Col>
                          
                      <Col xs={2} md="auto" className="d-flex ml-auto justify-content-end m-0 p-0">
                          <Likes user_id={userid} statement={statement} udhr={statement.statement_to_udhr} area={area}/>
                      </Col>

                      </div>
                          
                  </Row>

              </Card.Header>
              
              
              {/* ---- STATEMENT BODY ----- */}
              
              <Card.Body className="ml-2 mr-2 mt-0 pt-0">

                
                
              {/* ---------- STATEMENT ROW -------------------- */}
                
                <Row>    

                  { (isEditActive && currentStatement === String(statement.id)) ? (
                      showEditStatement(statement)
                  ) : (                                

                      
                      /* ---------- HERE IS THE STATEMENT -------------------- */


                      /* Next: pre-line to ensure line-shift characters like '/n'  */
                    
                      <Card.Text style={{fontSize: "1.1rem", whiteSpace: "pre-line"}} className="mt-1 mb-3 pt-0 pl-2 pr-2">

                          {statement.statement}
                          
                      </Card.Text>                               


                  )}

                  </Row>

                  {/* ---------- HERE IS THE UDHR -------------------- */}
                  {/* Show udhr-component - the user has commented to */}


                  { (statement.statement_to_udhr > -1) ?                                
                      (
                          ( area != AREA_UDHR ) ? (                                                               
                          <Row>
                              <Udhr user={statement.user} article={statement.statement_to_udhr}> partOfStatement={true} </Udhr>
                          </Row>): null
                      ): null                                                              
                  }

                                                                        
                  <Row>
                      <Col>
                          <Switch>
                              <Route exact path="/home/statement/:id" 
                                  render={routeProps => (
                                      <Share
                                          isShareActive={isShareActive} 
                                          currentStatement={currentStatement} 
                                          statement={statement} 
                                          setShareActive={setShareActive} {...routeProps} /> )} />
                          </Switch>
                      </Col>
                  </Row>

                  {/* If the user presses reply - show the reply component here - above the statement toolbar, but below the statement */}
                  
                  {showReplyComponent(statement)}


                  {/* Builds the toolbar LIKE-SHARE-EDIT-DELETE- below the statement */}

                  <Row className="d-flex flex-row align-self-center mb-3">                
                      {showLikeButton(statement)}
                      {showReplyButton(statement)}
                      <Link to={"/home/statement/"+statement.id}>
                          <Button id={statement.id} className="text-right ml-1" size="sm" onClick={handleShare}>
                                  Share
                          </Button>
                      </Link>
                      {showEditButton(statement)}
                      {showDeleteButton(statement)}
                  </Row>

                  <div>                
                      {
                        statement.replied_tos.map((reply) => {
                        return (
                          <div key={reply.id}>
                                <Reply reply={reply} />                                              
                          </div>
                      );})
                      }                                         
                </div>
              </Card.Body>
          </Card>
      </ListGroup.Item>
  </div>
  );
};
export default Statement;