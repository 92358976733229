import React, { useState, useContext } from "react";
import axios from "axios";
import * as Io from "react-icons/io";
import { Form, Button, Card, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import FormWrapper from "../formWrapper";
import { passwordResetRequest } from "../passwordReset/passwordResetLinks";
import {
  handleInputChange,
  displayServerErrorMessagesInErrorDiv,
  displaySingleErrorMessageInErrorDiv,
  displayInputErrorMessages,
  hideAllInputErrorMessages
} from "../../../utils/formUtils";
import * as fieldNames from "./loginFormFieldNames";
import { register } from "../userAccessLinks";
import { API_LOGIN_URL } from "../../../../constants/apiUrl";
import { startUserSession, saveSettings } from "../../../../state/userAuthState/userAuthActions";
import { updateUserProfile } from "../../../../state/userProfileState/userProfileActions";
import { clearAlerts } from "../../../../state/alertsState/alertActions";
import { setLoadingAlertVisibility } from "../../../../state/alertsState/alertActions";
import { http, setAuthToken } from '../../../../../services/http-common';
import { userProfileLink } from '../../../user/UserProfileLinks';
import { useUserProfileSelector } from "../../../../state/typedSelectors";
import { GlobalContext } from "../../../../state/GlobalState";


interface UserLoginDetails {
  [fieldNames.PASSWORD]: string;
  [fieldNames.USERNAME]: string;
}

export default () => {

  // const { setAuthToken } = useContext(GlobalContext);

  const [userDetails, setUserDetails] = useState<UserLoginDetails>({
    [fieldNames.PASSWORD]: "",
    [fieldNames.USERNAME]: ""
  });
  
  const { first_login } = useUserProfileSelector(
    state => state.userProfileReducer
  );  
  
  // const { loadUser } = useContext(GlobalContext);

  const dispatch = useDispatch();
  
  const updateFormElement = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(e, userDetails, setUserDetails);

  return (
    <FormWrapper>
      <Card>
        <Card.Body>
          <Card.Title>Login</Card.Title>
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              hideAllInputErrorMessages([
                fieldNames.USERNAME,
                fieldNames.PASSWORD
              ]);
              let errorMessageFieldnames = [];
              if (userDetails[fieldNames.USERNAME].length === 0) {
                errorMessageFieldnames.push(fieldNames.USERNAME);
              }
              if (userDetails[fieldNames.PASSWORD].length === 0) {
                errorMessageFieldnames.push(fieldNames.PASSWORD);
              }
              if (errorMessageFieldnames.length > 0) {
                displayInputErrorMessages(errorMessageFieldnames);
                return;
              }
              const submitBtn = document.getElementById(
                fieldNames.SUBMIT
              ) as HTMLInputElement;
              submitBtn.disabled = true;
              (document.getElementById(
                fieldNames.FORM_ERROR_DIV_ID
              ) as HTMLElement).innerHTML = "";
              dispatch(setLoadingAlertVisibility("loading"));

              axios
                .post(API_LOGIN_URL, {
                  username: userDetails[fieldNames.USERNAME],
                  password: userDetails[fieldNames.PASSWORD]
                })
                .then(response => {

                  console.log("login success", response.data);
                  
                  dispatch(clearAlerts());
                  
                  dispatch(
                    startUserSession(
                      response.data.key,
                      userDetails.login_username,
                      response.data.pk,
                      response.data.first_name,
                      response.data.last_name,
                      response.data.email,
                      response.data.image,
                      response.data.online,
                      response.data.number_of_logins
                    ));

                  setAuthToken(response.data.key);

                  const newUser = {
                    id: response.data.pk,
                    username: response.data.username,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    gar_avatar_name: response.data.gar_avatar_name,
                    image: response.data.image,
                    last_seen: response.data.last_seen,
                    online: response.data.online,
                    created: response.data.created,
                    status: response.data.status,
                    date_of_birth: response.data.date_of_birth,
                    born_in: response.data.born_in,
                    living_in: response.data.living_in,
                    city: response.data.city,
                    bio: response.data.bio,
                    last_saved: response.data.last_saved,
                    profile_completed_percent: response.data.profile_completed_percent,
                    number_of_logins: response.data.number_of_logins,
                    education: response.data.education,
                    education_place: response.data.education_place,
                    occupation: response.data.occupation,
                    position: response.data.position,
                    first_login: response.data.first_login,
                    homepageViewAllowed: response.data.homepageViewAllowed,
                    homepageAddAllowed: response.data.homepageAddAllowed                    

                  }

                  dispatch(
                    updateUserProfile(
                      response.data.username,
                      response.data.first_name,
                      response.data.last_name,
                      response.data.email,
                      response.data.gar_avatar_name,
                      response.data.image,
                      response.data.last_seen,
                      response.data.online,
                      response.data.created,
                      response.data.status,
                      response.data.date_of_birth,
                      response.data.born_in,
                      response.data.living_in,
                      response.data.city,
                      response.data.bio,
                      response.data.last_saved,
                      response.data.profile_completed_percent,
                      response.data.number_of_logins,
                      response.data.education,
                      response.data.education_place,
                      response.data.occupation,
                      response.data.position,
                      response.data.first_login,
                      response.data.homepageViewAllowed,
                      response.data.homepageAddAllowed                    
                )  
                  );

                  // loadUser(response.data);

                  if (response.data.first_login) {
                    console.log("true", first_login);
                    dispatch(push(userProfileLink.link));
                  } else {
                    console.log("false", first_login);
                    dispatch(push("/"));
                  }     
                                    
                })
                .catch(error => {

                  if (error["response"] !== undefined) 
                  
                  /*
                  * The request was made and the server responded with a
                  * status code that falls out of the range of 2xx
                  */
            
                  {
                    console.log("JSON", error.toJSON());

                    displayServerErrorMessagesInErrorDiv(
                      fieldNames.FORM_ERROR_DIV_ID,
                      error.response.data
                    );
                    submitBtn.disabled = false;
                  } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */

                    console.log("JSON1", error.toJSON());

                    displaySingleErrorMessageInErrorDiv(
                      fieldNames.FORM_ERROR_DIV_ID,
                      error.message
                    );

                    submitBtn.disabled = false;
                  
                  } else {
                    console.log("JSON", error.toJSON());
                  }
                  console.log("CONFIG", error.config);
                })
                // .finally(() =>
                //   dispatch(setLoadingAlertVisibility("finishing"))
                // );
            }}
          >
            <Form.Group controlId="formEmail">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id={`${fieldNames.USERNAME}-prepend`}>
                    <Io.IoMdPerson />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name={fieldNames.USERNAME}
                  value={userDetails[fieldNames.USERNAME]}
                  type="text"
                  placeholder="Username"
                  onChange={updateFormElement}
                />
              </InputGroup>
              <Form.Text
                className="d-none text-danger"
                id={fieldNames.USERNAME + "-error"}
              >
                Please enter a Username
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formPassword">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id={`${fieldNames.PASSWORD}-prepend`}>
                    <Io.IoMdLock />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name={fieldNames.PASSWORD}
                  value={userDetails[fieldNames.PASSWORD]}
                  type="password"
                  placeholder="Password"
                  onChange={updateFormElement}
                />
              </InputGroup>
              <Form.Text
                className="d-none text-danger"
                id={fieldNames.PASSWORD + "-error"}
              >
                Please enter a Password
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="submit">
              <Button id={fieldNames.SUBMIT} variant="primary" type="submit">
                Submit
              </Button>
                <div className="pt-3" id={fieldNames.FORM_ERROR_DIV_ID}>
                  {/* errors inserted here */}
                </div>
            </Form.Group>
          </Form>
          <Card.Text className="">
            Don't have an account?{" "}
            <Link to={register.link} className="st-link">
              {register.displayName}
            </Link>
          </Card.Text>
          <Card.Text>
            Forgot your Password?{" "}
            <Link to={passwordResetRequest.link} className="st-link">
              {passwordResetRequest.displayName}
            </Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </FormWrapper>
  );
};
