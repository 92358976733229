export const UPDATE_PROFILE = "updateProfile";

export interface UserProfileState {
  username?: string | null;
  ufirst_name?: string | null;
  ulast_name?: string | null;
  uemail?: string | null;
  gar_avatar_name?: string | null;
  image?: string | null;
  last_seen?: string | null;
  online?: string | null;
  created?: string | null;
  status?: string | null;
  date_of_birth?: string | null;
  bio?: string | null;
  born_in?: string | null;
  living_in?: string | null;
  city?: string | null;
  last_saved?: string | null;
  profile_completed_percent?: string | null;
  number_of_logins?: string | null;
  education?: string | null;
  education_place?: string | null;
  occupation?: string | null;
  position?: string | null;
  first_login?: string | null;
  homepageViewAllowed?: string | null;
  homepageAddAllowed?: string | null;
}

export interface UpdateProfileAction {
  type: typeof UPDATE_PROFILE;
  payload: UserProfileState;
}
