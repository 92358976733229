import React from "react";
import { Route, Switch} from "react-router-dom";
import HomePage from "../views/HomePage";
import StatePage from "../views/StatePage";
import Search from '../search/Search';
import SearchPage from "../search/SearchPage";
import GroupProfilePage from "../groups/GroupProfilePage";
import PageNotFound from "../generic/PageNotFound";
import UserPageFull from "../views/user/UserPageFull";
import UserProfilePage from "../user/UserProfilePage";
import { homeLink, stateLink, searchLink, groupsLink } from "../views/contentPageLinks";
import { userProfileLink, userLink } from "../user/UserProfileLinks";
import DataComponent from '../../../services/DataComponent';
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers';
import DataFriendsComponent from '../../../services/DataFriendsComponent';

export default (props) => {

  const location = props.location;
  
  return (
    <React.Fragment>

      {/* <DataFriendsComponent /> */}

      <Switch location={location}>
        
        <Route path={homeLink.link} component={HomePage} />
        <Route path={searchLink.link}>
              
              {/* // search component on mobile devices */}
              <div className="d-flex flex-row d-md-none justify-content-center">
                    <Search />
              </div>
              
              <SearchPage />

        </Route> 
        <Route path={stateLink.link} component={StatePage} />

        <Route path={groupsLink.link}>
            <GroupProfilePage />
        </Route>

        <Route path={userProfileLink.link}>
          <UserProfilePage />
        </Route> 

        <Route path="/user/:id" component={UserPageFull} />


      </Switch>
    </React.Fragment>
  );
};
