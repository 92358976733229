import React, { useEffect, useState, useContext } from 'react';
import FollowList from './FollowList';
import withListLoading from '../utils/WithListLoading';
import axios from 'axios';
import Friend from '../friends/Friend'
import Friends from '../friends/Friend'
import UserStick from '../user/UserStick'
import FollowingStick from './FollowingStick'
import ListGroup from 'react-bootstrap/ListGroup';
import { useUserAuthSelector } from "../../state/typedSelectors";
import { GlobalContext } from "../../state/GlobalState";

function Follows({list, isFollowers}) {
    
  // const ListLoading = withListLoading(FollowList);
    
  const [follows, setFollows] = useState([]);

  const { followees, followers, friends, blockers } = useContext(GlobalContext);

  const { authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );    

  useEffect(() => {
    if (authToken) {

      if (isFollowers) {
        setFollows(followers);
      } else
      {
        setFollows(followees);
      };
      console.log("In Follows", follows)
    }
  
  }, [followers, followees])

  let followsHeadingText = "";
  followsHeadingText += ((!list) && (isFollowers)) ? "Here are your followers:" : "You are currently following:";
  
  const followsHeading =  (list) ? 
    (<h4 className="mb-2" style={{color: "grey", textAlign: "center"}} >
      Followers
    </h4>)  : (
       (<h4 className="mb-5" style={{color: "grey", textAlign: "center"}} >
       {followsHeadingText}
       </h4>)  
    );
  
  return (
    <div className="Follow">
            
      <ListGroup>
      
        {followsHeading}             

        {
          (follows) ?

          (follows.map((follow) => (
            <div key={follow.pk}>

            { (list) ? <Friend user={follow} userObject="followers"/> : <FollowingStick user={follow} isFollowers={isFollowers} />}

            </div>
          ))): null
          
          
          }

      </ListGroup>
    </div>
  );
}

export default Follows;