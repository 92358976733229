export const AREA_PRIVATE = 1;
export const AREA_FRIENDS = 2;
export const AREA_GROUP = 3;
export const AREA_PROJECT = 4;
export const AREA_PUBLIC = 5;
export const AREA_UDHR = 6;

// The first five constants corrosponds to the PostgreSQL/Django model/table
// Statement and the field/column "post_to". Used to retrieve relevant statements
// pertaining to the GAR217A area-model.

export const UPLOAD_TO_USER = "uploadToUser";
export const UPLOAD_TO_GROUP = "uploadToGroup";
export const UPLOAD_TO_PROJECT = "uploadToProject";
export const UPLOAD_TO_ARRANGEMENT = "uploadToArrangement";
export const UPLOAD_TO_STATEMENT = "uploadToStatement";
export const UPLOAD_TO_REPLY = "uploadToReply";