import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import {timeSince} from '../utils/dateTime'
import ReactRoundedImage from "react-rounded-image";
import { API_MEDIA_URL } from "../../constants/apiUrl";


const Reply = ( {reply} ) => {

  
  let createdBy = {};
  
  try {
    if (!Boolean(reply.created_by)) {
      // console.log(reply);
      createdBy = {'username': '0', 'image': ''};
    } else {
      // console.log(reply);
      createdBy = reply.created_by;
    }
  } catch (err) {
    console.log(err);
  }

  let localImage = null;

  if (createdBy.image) {
    localImage = createdBy.image;
    if (localImage.search('http') === -1) {
      localImage = API_MEDIA_URL + createdBy.image;
      console.log("LocalTmage", localImage);
    } else {
      localImage = createdBy.image;
    } 
  } else
  {
      localImage = createdBy.image;
  }

 
  return(
  <Card  className="ml-4 mb-2 p-0 border-0">
    <Card.Body>
        <Card.Header className="border-0  p-0 m-0" size="sm">

        
        <div className="d-flex flex-row align-items-start">
        
        <ReactRoundedImage 
              image={localImage} 
              imageWidth="28"
              imageHeight="28"
              roundedSize={createdBy.online ? '6' : '0'}
              roundedColor="Green"
              
            />

            <div className="ml-2">
              <strong>{(createdBy.full_name) ? createdBy.full_name : createdBy.username}</strong> {timeSince(new Date(reply.created))} ago
            </div>

          </div>

        

      </Card.Header>            

     <Card.Text className="mt-2">
        {reply.reply}     
      </Card.Text> 
    
    
    
    </Card.Body>
  </Card>
  );

}

export default Reply;
