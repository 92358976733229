import React from "react";
import { Route, Switch } from "react-router-dom";

import RegisterPage from "./registration/registrationPage";
import LoginPage from "./login/loginPage";
import UserPage from "../user/UserPage";
import EmailConfirmationPage from "./registration/emailConfirmationPage";

import { login, register, emailConfirmation } from "./userAccessLinks";

export default () => {
  return (
    <React.Fragment>
        <Switch>
          <Route path={register.link} component={RegisterPage} />
          <Route path={login.link} component={LoginPage} />
          <Route path={emailConfirmation.link} component={EmailConfirmationPage} />
        </Switch>
    </React.Fragment>
  );
};
