import { http, httpForm } from "./http-common";

class FollowersDataService {

  getAllFollowers(id) {
    return http.get(`/follows/followers/?user_id=${id}`);
  }

  getAllFollowees(id) {
      return http.get(`/follows/follows/?user_id=${id}`);
    }

  addFollowee(data) {
      return httpForm.post("/follows/", data);
    }

  removeFollowee(id) {
      return http.delete(`/follows/${id}/`);
    }

  block(id) {
      return http.get(`/friends/${id}/block/`);
    }

  unblock(id) {
      return http.get(`/friends/${id}/block/`);
    }


}

export default new FollowersDataService();