import React from "react";
import { http, httpForm } from "../../services/http-common";
import styled from "styled-components";
import MainMenu from "./navBar/MainMenu";
import AlertsContainer from "./alerting/alertsContainer";
import SessionChecker from "./utils/sessionChecker";
import BodyRoutes from "./views/bodyRoutes";
import FeedRoutes from "./feed/FeedRoutes";
import FooterNew, { footerHeight } from "./footer/FooterNew";
import ScrollToTopOnRouteChange from "./utils/scrollToTopOnRouteChange";
import RedirectToHome from "./utils/redirectToHome";
import MainMenuRoutes from "./navBar/MainMenuRoutes";
import ErrorBoundary from './utils/ErrorBoundary'
import { GlobalProvider } from '../state/GlobalState';
import { DontShowWhenLoggedIn, ShowOnlyWhenLoggedIn } from './utils/elementWrappers/authBasedVisibilityWrappers'
import DataComponent from '../../services/DataComponent'


const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0;
  position: relative;
  .content-wrapper {
    padding-bottom: ${footerHeight};
  }
`;

function App() {

  // console.log('userAgent: ', window.navigator.userAgent);

  return (

    <React.Fragment>   
      <ErrorBoundary>
        <GlobalProvider>  
          <ContentWrapper>
              <RedirectToHome />
              <SessionChecker />
              <MainMenu />        
              <AlertsContainer />
              <MainMenuRoutes />
              <BodyRoutes />
                <FooterNew />
          </ContentWrapper>
        </GlobalProvider>
      </ErrorBoundary>
    </React.Fragment>


  );
}

export default App;
