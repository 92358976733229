import * as types from "./groupProfileTypes";

export const updateGroupProfile = (
  pk: string,
  id: string,
  user: {id: string, gar_avatar_name: string, username: string, email: string, created: string},
  owner: string,
  admin: string,
  name: string,
  established: string,
  description: string,
  code: string,
  image: string,
  country: string,
  city: string,
  homepageViewAllowed: string,
  homepageAddAllowed: string
): types.UpdateGroupProfileAction => {
  console.log("updateGroupProfile" , id, owner, name)
  return {
    type: types.UPDATE_GROUP_PROFILE,
    payload: {
      pk: pk,
      id: id,
      user: user,
      owner: owner,
      admin: admin,
      name: name,
      established: established,
      description: description,
      code: code,
      image: image,
      country: country,
      city: city,
      homepageViewAllowed: homepageViewAllowed,
      homepageAddAllowed: homepageAddAllowed

    }
  };
};