import { http, httpForm } from "./http-common";

class DataService {
    getAll() {
      return http.get("/users");
    }
  
    get(id) {
      return http.get(`/users/user/${id}/`);
    }
  
    update(id, data) {
      return http.patch(`/users/user/${id}/`, data);
    }

    deleteUser(id) {
      return http.delete(`/users/user/delete/${id}/`);
    }


    delete(id) {
      return http.delete(`/users/user/statement/${id}`);
    }

  }
  
  export default new DataService();