import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUserAuthSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import ListGroup from 'react-bootstrap/ListGroup';
import Friend from "./Friend";
import {API_USER_LIST, API_FRIENDS_URL} from "../../constants/apiUrl"
import FriendsDataService from '../../../services/FriendsDataService'
import FriendRequestStick from './FriendRequestStick'
import { useLocation } from "react-router-dom";

const FriendRequests = ( { objectType, user_id } ) => {
    
  let location = useLocation();

  console.log("FriendsRequest USER ID: ", user_id);

  const [friendRequests, setFriendRequests] = useState([]);

  const { authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );    

  useEffect(() => {
    
    if (authToken) {
       FriendsDataService.getFriendRequests(user_id)
      .then(response => {         
        setFriendRequests(response.data);
        console.log("Friend Requests", response.data);
      })
      .catch(error => console.log(error)); 
    }
  
  }, [])

  return (
    <div id="friend-requests">
      <ListGroup id="friend-requests-listgroup">
        <h4 style={{textAlign: "center", color: "grey"}} className="mt-3 mb-3" >{(friendRequests.length > 0)  ? (<h1> requests:</h1>) : (<p>No pending requests</p>)} </h4>
        {friendRequests.map((friend) => (
            <div id={`friend-stick-div-${friend.id}`}>
              <FriendRequestStick friend={friend.from_user} objectType={objectType} user_id={user_id} />
            </div>
          ))}
      </ListGroup>
    </div>
  );
}

export default FriendRequests;