import React from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, Col, Row } from "react-bootstrap";
import { gar217aLink, productsLink, companyLink } from "../footer/FooterLinks";
import { TermsOfUseLink, DataPolicyLink, GuidelinesLink, CreditsLink } from '../views/contentPageLinks';

export const footerHeight = `1rem`;

export default () => (

  <Navbar
        bg="white"
        // expand="md"
        id="nav-bar-main-style"
        style={{height: "150px"}}
        collapseOnSelect
      >
          <Container className="pt-2 pb-2 d-flex flex-row justify-content-center">
            <div></div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="nav-toggle"
            />
            <Navbar.Collapse id="basic-navbar-nav" className="flex-end">
              <Nav activeKey={""} className=" w-100">
                <Row className="d-block w-100 ml-1">
                  
                  <Col className="d-flex flex-column align-items-center">

                  <Row className="text-center">
                    (c) Copyright 2000-2021 GAR217A Svein Engebretsen. All rights reserved.
                  </Row>

                  <Row className="d-flex flex-row justify-content-center">
                  
                  {/* <LinkContainer to={gar217aLink.link}>
                    <Nav.Link  eventKey={gar217aLink.link}>
                      {gar217aLink.icon}
                      {gar217aLink.displayName}
                    </Nav.Link>
                  </LinkContainer> */}

                  <LinkContainer to={DataPolicyLink.link}>
                    <Nav.Link  eventKey={DataPolicyLink.link}>
                      {DataPolicyLink.icon}
                      {DataPolicyLink.displayName}
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to={TermsOfUseLink.link}>
                    <Nav.Link  eventKey={TermsOfUseLink.link}>
                      {TermsOfUseLink.icon}
                      {TermsOfUseLink.displayName}
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to={GuidelinesLink.link}>
                    <Nav.Link  eventKey={GuidelinesLink.link}>
                      {GuidelinesLink.icon}
                      {GuidelinesLink.displayName}
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to={CreditsLink.link}>
                    <Nav.Link  eventKey={CreditsLink.link}>
                      {CreditsLink.icon}
                      {CreditsLink.displayName}
                    </Nav.Link>
                  </LinkContainer>

                  </Row>

                </Col>
                </Row>
              </Nav>
            </Navbar.Collapse>
          </Container>
      </Navbar>
);