import React from "react";
import { Route, Switch } from "react-router-dom";
import Gar217aPage from "./Gar217aPage"
import ProductsPage from "./ProductsPage"
import CompanyPage from "./CompanyPage"
import {gar217aLink, productsLink, companyLink} from "./FooterLinks";
import { TermsOfUseLink, DataPolicyLink, CreditsLink, GuidelinesLink } from '../views/contentPageLinks';
import DataPolicy from "../generic/DataPolicy";
import TermsOfUse from "../generic/TermsOfUse";
import Credits from "../generic/Credits";
import CommunityGuidelines from "../generic/CommunityGuidelines"


export default () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={gar217aLink.link} component={Gar217aPage} />
        <Route exact path={productsLink.link} component={ProductsPage} />
        <Route exact path={companyLink.link} component={CompanyPage} />
        <Route exact path={DataPolicyLink.link} component={DataPolicy} />
        <Route exact path={TermsOfUseLink.link} component={ TermsOfUse } />
        <Route exact path={CreditsLink.link} component={ Credits } />
        <Route exact path={GuidelinesLink.link} component={ CommunityGuidelines } />
      </Switch>
    </React.Fragment>
  );
};