import React from "react";
import { Route, Switch } from "react-router-dom";
import Udhr from "../udhr/Udhr";
import Statement from "../statements/Statement";

export default (props) => {

  const location = props.location;

  return (
    <React.Fragment>
        <Switch location={location}>                  
          <Route exact path="/home/udhr/:id" component={Udhr} />
        </Switch>
    </React.Fragment>

  );
};