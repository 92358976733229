import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../web/state/GlobalState";
import FriendsDataService from './FriendsDataService';
import FollowersDataService from './FollowersDataService';
import { useUserAuthSelector } from "../web/state/typedSelectors";
import StatementsDataService from './StatementsDataService';
import GroupsDataService from './GroupsDataService';


export const DataComponent = () => {

    const { loadFriends, loadFollowers, loadFollowees, loadBlockers, loadStatements, loadGroups } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    const { authToken, sessionActive, userid } = useUserAuthSelector(
        state => state.userAuthReducer
      );
      
    useEffect(() => {
       
      // const ac = new AbortController();
      let data = new FormData();
      data.append('user_id', userid);

      if (sessionActive === true) {

              Promise.all([
                FriendsDataService.getAll(userid), 
                FollowersDataService.getAllFollowers(userid),
                FollowersDataService.getAllFollowees(userid),
                FriendsDataService.blocking(userid), 
                StatementsDataService.getAll(userid),
                GroupsDataService.getAll(userid)
                ]).then((response) => {
                  loadFriends(response[0].data);
                  loadFollowers(response[1].data);
                  loadFollowees(response[2].data);
                  loadBlockers(response[3].data);
                  loadStatements(response[4].data);
                  loadGroups(response[5].data);
                  console.log("DataComponent", response);
                })
                .catch(e =>  console.error(e));  

          }

        return () => {

        }


          }, [authToken])

         

    

    return(       
        <>
        <div></div>
      </>);
}

export default DataComponent;