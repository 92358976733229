import React from 'react'
import { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import {API_PROFILE_URL, API_URL, API_MEDIA_URL, API_STATIC} from "../../constants/apiUrl";
import UploadPhoto from "../photos/UploadPhoto"
import GroupProfileForm from "./GroupProfileForm"
import { Button, Container, Col, Row, Card, Table, CardDeck } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector, useUserProfileSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../state/GlobalState";
import { UPLOAD_TO_GROUP } from '../../constants/GlobalConstants';
import { composeWithDevTools } from 'redux-devtools-extension';

const GroupProfile = () => {

  const { loadPersons, persons } = useContext(GlobalContext);

  const { userid, authToken} = useUserAuthSelector(
    state => state.userAuthReducer
  );

  // const { username, ufirst_name, ulast_name, uemail, date_of_birth, status, gar_avatar_name,
  //   created, born_in, living_in, city, bio, last_saved } = useUserProfileSelector(
  //   state => state.userProfileReducer
  // );  
 
  const { pk, id, user, owner, admin, name, estabilshed, description, code, image, country, city } = useGroupProfileSelector(
    state => state.groupProfileReducer
  );    


  
  // The next is a hack, since I currently did not know what paramteres to set in Django.
  let localImage = null;

  if (image) {
    localImage = image;
    if (localImage.search('http') === -1) {
      localImage = API_MEDIA_URL + image;
      console.log("LocalTmage", localImage);
    } else {
      localImage = image;
    } 
  }

 console.log("localImage", localImage);
 
  let history = useHistory();
  
  const handleClose = (e) => {
    history.push("/home/user/");
  }    

  var user_since_date = new Date(user.created);
  // var user_born_date = new Date(date_of_birth);

  const uGarAvatarName = ( 
    <tr>
        <td><Card.Text>GarAvatarId:</Card.Text></td>
        <td><Card.Text>{user.gar_avatar_name}</Card.Text></td>
    </tr>
    );
    
  const uName = (name) ? 
    (
      <tr>
        <td><Card.Text>Name:</Card.Text></td>
        <td><Card.Text>{name}</Card.Text></td>
      </tr>) :
  
    (
      <tr>
        <td><Card.Text>Username:</Card.Text></td>
        <td><Card.Text>{user.username}</Card.Text></td>
      </tr>)
  

  const uUsername = (user.username) ? (
    <tr>
      <td><Card.Text>Username:</Card.Text></td>
      <td><Card.Text>{user.username}</Card.Text></td>
    </tr>): null

  const uCreated = (user.created) ? (
    <tr>
      <td><Card.Text>User since:</Card.Text></td>
      <td><Card.Text>{user_since_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null


 const uCountry = (country) ? 
  (<tr>
    <td><Card.Text>Country:</Card.Text></td>
    <td><Card.Text>{country}</Card.Text></td>
  </tr>): null

  const uCity = (city) ? 
  (<tr>
    <td><Card.Text>City:</Card.Text></td>
    <td><Card.Text>{city}</Card.Text></td>
  </tr>): null
  
  const uDescription = (description) ? 
  (
    <div className="text-center mb-3">
      <Card.Text>About:</Card.Text>
      <Card.Text>{description}</Card.Text>
    </div>
  ): null

  const uCode = (composeWithDevTools) ? 
  (
    <div className="text-center">
      <Card.Text>Code of Conduct:</Card.Text>
      <Card.Text>{code}</Card.Text>
    </div>
  ): null

    return (

      <React.Fragment>                                    
              <Container className="d-flex justify-content-center w-100">
                      <Card className="w-100 pt-3">

                      <Card.Title className="pl-4 mb-0 text-secondary">{name}</Card.Title>

                        <Row className="w-100 mb-2 d-flex justify-content-center">
                          
                          <Card.Body className="d-flex flex-column-reverse flex-md-row">
                            
                            <Col sm={6}>
                                <Table style={{fontSize: "0.9rem"}} size="sm">
                                  <tbody>
                                    {uGarAvatarName}
                                    {uName}
                                    {uUsername}
                                    {uCreated}
                                    {uCountry}
                                    {uCity}
                                    </tbody>
                                </Table>                           
                              
                                <div className="d-flex align-items-start">
                                    <GroupProfileForm />
                                   <UploadPhoto uploadTo={UPLOAD_TO_GROUP}/>           
                                </div>

                            </Col>
                            
                            <Col className=" d-flex flex-column justify-content-start align-items-center">
                              
                              <Card.Img className="mb-4 border border-secondary" src={localImage}  alt={'/static/profile_image.png'} style={{width: '165px', height: '165px'}} />


                              {uDescription}
                              {uCode}
                                                       
                            </Col>

                          </Card.Body>
  
                          </Row>
  
                      </Card>     

                  </Container>
                      
      </React.Fragment>

    );
  };

export default GroupProfile;