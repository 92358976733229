import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

export default () => 

{
    return(
        <Container className="px-0 mx-0">
            <Row className="px-0 d-flex justify-content-center">
                <Col xs={12} md={8} className="mx-0" >
                    <Card className="w-100 p-2 text-left border-0">
                        <Card.Title className="text-center">Data Policy</Card.Title>
                        
<p>Effective date: May 8, 2021</p>

<p>Hello everyone!</p>

<p>GAR217A  ('us', 'we', or 'our') operates the GAR217A.com website (hereinafter referred to as the 'Service').

This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.

We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Data Policy, the terms used in this Data Policy have the same meanings as in our Terms of Use.
</p>

<h5 className=" mb-3">Definitions</h5>

<h6>Service</h6>

<p>
Service means the https://www.gar217a.com website.
</p>

<h6>Personal Data</h6>

<p>
Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
</p>

<h6>Usage Data</h6>


<p>
Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
</p>

<h6>Cookies</h6>
<p>Cookies are small files stored on your device (computer or mobile device), that are commonly used as anonymous unique identifiers.
</p>

<h6>Data Controller</h6>
<p>
Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.

For the purpose of this Data Policy, we are a Data Controller of your Personal Data.
</p>

<h6>Data Processors (or Service Providers)</h6>

<p>
Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.

We may use the services of various Service Providers in order to process your data more effectively.
</p>

<h6>Data Subject (or User)</h6>

<p>
Data Subject is any living individual who is using our Service and is the subject of Personal Data.
</p>

<h5>Information Collection and Use</h5>

<p>
We collect several different types of information for various purposes to provide and improve our Service to you.
</p>

<h6 className="mb-2">Types of Data Collected</h6>

<p><b>Personal Data</b></p>

<p>
While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ('Personal Data'). Personally identifiable information may include, but is not limited to:
</p>

<ul>
<li>Name</li>
<li>Address</li>
<li>Email address</li>
<li>Date of Birth</li>
<li>Education</li>
<li>Work information</li>
<li>General information about yourself (bio)</li>
<li>Personal information you provide to GAR217A.com in comments, articles etc.</li>
</ul>

<p><b>Usage Data</b></p>
<p>
We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ('Usage Data').

This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
</p>

<p><b>Location Data</b></p>

<p>
We may use and store information about your location if you give us permission to do so ('Location Data'). We use this data to provide features of our Service, to improve and customize our Service.
You can enable or disable location services when you use our Service at any time, through your device settings.
</p>

<h5>Tracking & Cookies Data</h5>

<p>We may use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.

Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.

You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
</p>

<div>
<p>Examples of Cookies we use or you should expect us to use later on:</p>

<ul>

<li>Session Cookies – We use Session Cookies to operate our Service.</li>

<li>Preference Cookies – We use Preference Cookies to remember your preferences and various settings.</li>

<li>Security Cookies – We use Security Cookies for security purposes.</li>

<li>Advertising Cookies – Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>

</ul>
</div>  

<h5>Use of Data</h5>

GAR217A uses the collected data for various purposes:

<ul>

<li>To provide and maintain our Service.</li>

<li>To notify you about changes to our Service.</li>

<li>To allow you to participate in interactive features of our Service when you choose to do so.</li>

<li>To provide customer support.</li>

<li>To gather analysis or valuable information so that we can improve our Service.</li>

<li>To monitor the usage of our Service.</li>

<li>To detect, prevent and address technical issues.</li>

</ul>

<h5>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h5>
<p>If you are from the European Economic Area (EEA), GAR217A legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>

<p>GAR217A may process your Personal Data because:</p>
<ul>

<li>We need to perform a contract with you.</li>

<li>You have given us permission to do so.</li>

<li>The processing is in our legitimate interests and it is not overridden by your rights.</li>

<li>For payment processing purposes.</li>

<li>To comply with the law.</li>

</ul>

<h5>Retention of Data</h5>

<p>
GAR217A will retain your Personal Data only for as long as is necessary for the purposes set out in this Data Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.

GAR217A will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.

</p>
<h5>Transfer of Data</h5>
<p>
Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.

Please note that we transfer the data, including Personal Data, to Irland and process it there. Amazon email services, are processed in Utah, USA.

Your consent to this Data Policy followed by your submission of such information represents your agreement to that transfer.

GAR217A will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
</p>

<h5>Disclosure of Data</h5>

<h6>Business Transaction</h6>
<p>

If GAR217A is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Data Policy.
</p>

<h6>Disclosure for Law Enforcement</h6>
<p>
Under certain circumstances, GAR217A may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
</p>
<h6>Legal Requirements</h6>
<p>
GAR217A may disclose your Personal Data in the good faith belief that such action is necessary to:
</p>
<ul>
    <li>To comply with a legal obligation</li>
    <li>To protect and defend the rights or property of GAR217A</li>
    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
    <li>To protect the personal safety of users of the Service or the public</li>
    <li>To protect against legal liability</li>
</ul>
    
    
<h5>Security of Data</h5>
<p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

<h5>Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h5>

<p>
If you are a resident of the European Economic Area (EEA), you have certain data protection rights. GAR217A aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.

If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
</p>

In certain circumstances, you have the following data protection rights:

<ul>

<li>The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</li>

<li>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</li>

<li>The right to object. You have the right to object to our processing of your Personal Data.</li>

<li>The right of restriction. You have the right to request that we restrict the processing of your personal information.</li>

<li>The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</li>

<li>The right to withdraw consent. You also have the right to withdraw your consent at any time where GAR217A relied on your consent to process your personal information.</li>

<li>Please note that we may ask you to verify your identity before responding to such requests.</li>

<li>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</li>

</ul>

<h5>Service Providers</h5>

<p>
We may employ third party companies and individuals to facilitate our Service ('Service Providers'), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.

These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
</p>

<h5>Amazon</h5>
<p>
For registration and other email services we Amazon SES, see their Data Policy https://aws.amazon.com/privacy/. 
</p>

<h5>PythonAnywhere.com</h5>
<p>
GAR217A.com is hosted on PythonAnywhere.com. See their Data Policy at https://www.pythonanywhere.com/privacy_v2/. 
</p>


<h5>Analytics</h5>
<p>
We may use third-party Service Providers to monitor and analyse the use of our Service.
</p>
<h5>Advertising</h5>
<p>
We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.
</p>
<h5>Links to Other Sites</h5>
<p>
Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.

We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
</p>
<h5>Children's Privacy</h5>
<p>
Our Service does not address anyone under the age of 13 ('Children').

We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
</p>
<h5>Changes to this Privacy Policy</h5>
<p>
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

We will let you know through a prominent notice on our Service, prior to the change becoming effective and update the 'effective date' at the top of this Privacy Policy.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</p>

<h5>Contact Us</h5>
<p>
If you have any questions about this Privacy Policy, please contact us by email: privacy-policy@gar217a.com.
</p>
                   </Card>
                </Col>
            </Row>
        </Container>
    );
}