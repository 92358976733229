import { http, httpForm } from "./http-common";

import {  API_STATEMENT_ADD_URL, 
        API_REPLY_ADD_URL, 
        API_REACTION_ADD_URL,
        API_REACTION_URL} from "../web/constants/apiUrl";

class GroupsDataService {
    getAll(id) {
      return http.get(`/groups/?user_id=${id}`);
    }

    getAllToUDHR(udhr) {
      return http.get(`/groups/udhr/?article=${udhr}`);
    }

    create(id, data) {
      return httpForm.post(`groups/${id}/`, data);
    }
    

    createGroup(id, data) {
      return httpForm.post(`/group/add/${id}/`, data);

    }

    update(id, data) {
      return http.patch(`/groups/${id}/`, data);
    }

    remove(id) {
      return http.delete(`/groups/${id}/`);

    }

  }

  export default new GroupsDataService();