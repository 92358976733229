import React, { useEffect, useState } from 'react';
import NgoList from './NgoList';
import withListLoading from '../utils/WithListLoading';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';

function Ngos() {
  
  const ListLoading = withListLoading(NgoList);
    
  // {id: "b1c114b1-f236-42bd-93a1-09911607c987", password: "pbkdf2_sha256$216000$kbmF1dIXvPSD$xUWF7iYwpZ+KqVqUgG0oNFbzhiqP2B5F4EwMdXv9TGs=", last_login: "2021-01-02T19:03:11.977911", uuid1: "c69f141d-4d24-11eb-a71f-001a7dda7107", username: "KongenAvKjelsaas",}

  const [ngosState, setNgosState] = useState({
    loading: false,
    ngos: null
  });

  useEffect(() => {
    setNgosState({ loading: true });
    const apiUrl = `http://127.0.0.1:8000/api/ngo/`;
    // const apiUrl = `http://127.0.0.1:8000/api/users/user/b1c114b1-f236-42bd-93a1-09911607c987`;
    //const apiUrl = `http://127.0.0.1:8000/api/dj-rest-auth/user/?id="admin" "Authorization: Token 97c20be482f740d929167251b84e2f661f6ed170"`;
    axios.get(apiUrl).then((response) => {
      const allNgos = response.data;
      setNgosState({ loading: false, ngos: allNgos });
    });
  }, [setNgosState]);
  
  return (
      <div className='ngo-list'>      
        <ListGroup>
          <h4 style={{textAlign: 'center'}}>NGO</h4>
          <ListLoading isLoading={ngosState.loading} repos={ngosState.ngos} />
        </ListGroup>
      </div>
  );
}

export default Ngos;