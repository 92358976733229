import { useSelector, TypedUseSelectorHook } from "react-redux";
import { RouterState } from "connected-react-router";
import { UserState } from "./userAuthState/userAuthTypes";
import { AlertState } from "./alertsState/alertTypes";
import { UserProfileState } from "./userProfileState/userProfileTypes";
import { GroupProfileState } from "./groupProfileState/groupProfileTypes";
import { SettingsInterface } from "./userAuthState/userAuthTypes";
import * as reducerNames from "./reducerNames";

interface RouterStateSelector {
  [reducerNames.ROUTE_REDUCER]: RouterState;
}

export const useRouterSelector: TypedUseSelectorHook<RouterStateSelector> = useSelector;

interface AlertStateSelector {
  [reducerNames.ALERTS_REDUCER]: AlertState;
}

export const useAlertsSelector: TypedUseSelectorHook<AlertStateSelector> = useSelector;

interface UserStateSelector {
  [reducerNames.USER_AUTH_REDUCER]: UserState;
}

export const useUserAuthSelector: TypedUseSelectorHook<UserStateSelector> = useSelector;


interface UserProfileSelector {
  [reducerNames.USER_PROFILE_REDUCER]: UserProfileState;
}

export const useUserProfileSelector: TypedUseSelectorHook<UserProfileSelector> = useSelector;
interface UserProfileSelector {
   [reducerNames.USER_PROFILE_REDUCER]: UserProfileState;
}

interface UserSettingsSelector {
  [reducerNames.USER_SETTINGS_REDUCER]: SettingsInterface;
}

export const useUserSettingsSelector: TypedUseSelectorHook<UserSettingsSelector> = useSelector;
interface UserProfileSelector {
   [reducerNames.USER_SETTINGS_REDUCER]: UserProfileState;
}


interface GroupProfileSelector {
  [reducerNames.GROUP_PROFILE_REDUCER]: GroupProfileState;
}

export const useGroupProfileSelector: TypedUseSelectorHook<GroupProfileSelector> = useSelector;