import React, { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../state/GlobalState";
import StatementsDataService from "../../../services/StatementsDataService";
import { useUserAuthSelector, useUserSettingsSelector } from "../../state/typedSelectors";
import { Nav, ListGroup, Image, Card, Dropdown, DropdownButton, Button, Row, Col } from "react-bootstrap";
import Statement from './Statement';
import Reply from './Reply';
import Share from './Share';
import {timeSince} from '../utils/dateTime'
import { Link, Route, Switch } from "react-router-dom";
import { AddStatement } from '../statements/addStatement';
import { AddReply } from '../statements/addReply';
import { EditStatement } from './EditStatement';
import * as Io from "react-icons/io";
import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { useRouteMatch } from "react-router-dom";
import Udhr from "../udhr/Udhr";
import ReactRoundedImage from "react-rounded-image";
import LoginModal from '../views/userAccess/login/LoginModal'
import { stringify } from "query-string";
import {AREA_PUBLIC, AREA_PRIVATE, AREA_UDHR} from '../../constants/GlobalConstants'

export const UDHRStatements = ({ area, identifier, setGotResult, category, thisEditStatement }) => {

    // UDHRStatement is used to retrieve statements "What people say" related to a single <Udhr /> component
    // This is due to recursivity issues. Since Udhr's may be part of a statement, then retrival of a statement
    // would lead to retrival of all statements related to that udhr, againg with relation to udhr and so on....
    // For that reason ..... UDHRStatements calls <Statement /> with area.prop set to AREA_UDHR, 
    // causing <Statement /> NOT to display any statement_to_udhr <Udhr />. In addition, UDHRStatements uses
    // GlobalState udhr_statements to hold statements to be shown, not GlobalState statements.
    // We may need to to something about statements STATE later on.

    // props:
    
    // area - AREA_PRIVATE, AREA_PUBLIC, AREA_UDHR is used to retrive relevant statements to the area the user
    // is operating in, and specifically to prevent <Udhr />'s to be displayed when statements are retrived in
    // the "What people say section", see above.

    // identifier - For now equal to the article to select statements from, i.e. get all statements with 
    // statement_to_udhr = identifier.

    // setGotResult - Callback from <Udhr /> used to set State gotResult on <Udhr />. This is for better user experience.
    // When User clicks on "What people say" in the <Udhr /> they will be given a message if no statements exisits to the <Udhr />, 
    // as opposed to a click on the link results in no visible action.
    
    let disableFunctions = false;
    // DisableFunctions is used to prevent an anonymous user to like, share, reply to Statemens in the public area
    // Instead - a modal is rasied to give the use oppertunity to log in  or sign in.
    
    const [showLoginModal, setShowLoginModal] = useState(false);
    // Controls the display of a <LoginModal /> when the user clicks on any interactebale item in AREA_PUBLIC

    const { udhr_statements, addUDHRStatement, editUDHRStatement, removeUDHRStatement, loadUDHRStatements, learn_statements, loadLearnStatements } = useContext(GlobalContext);
    
    const { username, first_name, last_name, email, userid, sessionActive, authToken, loginTime, image, online }= useUserAuthSelector(
        state => state.userAuthReducer
      );

    const [isReplyActive, setReplyActive] = useState(false);
    const [isEditActive, setEditActive] = useState(false);
    const [isShareActive, setShareActive] = useState(false);
    const [currentStatement, setCurrentStatement] = useState(null);
    const [likes, setLikes] = useState(null);
    const [liked, setLiked] = useState(false);
    const [reaction, setReaction] = useState(0);
    // const [learnStatements, loadLearnStatements] = useState([]);

    useEffect(() => {
        if (sessionActive === true) {
            // console.log("UseEfffect", "UDHRStatements")
            // load Statements related to the UDHR assigned in the prop "identifier"
            console.log("useEffect", category)
            retrieveStatements();

        }
      }, [identifier]);

          
    const retrieveStatements = () => {

        // Categories = discuss, learn, stores etc
        let localCategory = category;
        if (!!category) {
            localCategory = category;
        } else {
            localCategory = 1;
        }
        
        if (sessionActive === true) {
            console.log("IDENTIFIER", identifier);
            StatementsDataService.getAllFromCategoryToUDHR(identifier, category)            
            .then(response => {

                if (category === 1) {
                    console.log("loadUDHRStatements, userEffect", response.data);
                    loadUDHRStatements(response.data);
                } else {
                    console.log("loadLearnStatements 10!!!!!, userEffect", response.data);
                    loadLearnStatements(response.data);
                }
                
                if (response.data.length === 0) {
                    setGotResult(false);
                } else {
                    setGotResult(true);
                }
            })
            .catch(e => {
            console.log(e);
            });
        } else {
            console.log("NOT SessionActive");
        }

    };

    const handleShare = (e) => {
        
        if (!disableFunctions) {
            setCurrentStatement(e.currentTarget.id);
            setShareActive(true);
        } else 
        {
            e.preventDefault();
        }
    }

    const handleReply = (e) => {

        if (!sessionActive === true) {
            setShowLoginModal(true);  
        } else {

        setCurrentStatement(e.currentTarget.id);
        setReplyActive(true);
        }
    }

    const handleLike = (e) => { 

            if (!sessionActive === true) {
                setShowLoginModal(true);  
            } else {

        const statementId = parseInt(e.currentTarget.dataset.id);
        const currentNoLikes = parseInt(e.currentTarget.dataset.likes_count);
        let newNoLikes = currentNoLikes+1;
        
        setCurrentStatement(e.currentTarget.dataset.id);

        const newReaction = {
            userid: userid,
            reaction_to: e.currentTarget.dataset.id,
            reaction: 1
          };                         

        StatementsDataService.createReaction(newReaction)
            .then(response => {
                const statementUpdatedNoOfLikes = {likes_count: newNoLikes};                
                StatementsDataService.update(statementId, statementUpdatedNoOfLikes)
                .then(response => {
                    retrieveStatements();
                    console.log("statements retrieved")
                    })   
                .catch(error => {
                    if (error["response"] !== undefined) {
                        console.log(error.response);
                    } else {
                    }
                    });
            })
            .catch(error => {
            if (error["response"] !== undefined) {
                console.log(error.response);
            } else {
            }
            })
        }
    }
   
    const handleEdit = (e) => {
        setCurrentStatement(e.currentTarget.id);
        setEditActive(true);
    }
    
    const showReplyComponent = (statement) => {
        console.log("thisEditStatement UDHRStatements", thisEditStatement);
        if (isReplyActive && currentStatement === String(statement.id))
            return <AddReply statement={statement} setReplyActive={setReplyActive} thisEditStatement={thisEditStatement}/>;
    }
      

    const showEditStatement = (statement) => {
        if (isEditActive && currentStatement === String(statement.id))
            return <EditStatement statement={statement} setEditActive={setEditActive}/>;
    }

    const showShareComponent = (statement) => {
        if (isShareActive && currentStatement === String(statement.id))
            return <Share statement={statement} setShareActive={setShareActive}/>;
    }

    const showLikeButton = (statement) => {
        if (userid != String(statement.user.pk))
            return (
                <Button className="text-right" size="sm" data-id={statement.id} data-likes_count={statement.likes_count} data-statement={statement} onClick={handleLike}>
                    Like
                </Button>
            )
    }

    const showReplyButton = (statement) => {
        if (userid != String(statement.user.pk))
            return (
                <Button id={statement.id} data-id={statement.id} className="text-right ml-1" size="sm" onClick={handleReply}>
                    Reply
                </Button>    
            )
    }


    const showDeleteButton = (statement) => {
        // Show a deleteButton if user owns the statement
        if (userid === String(statement.user.pk))
            return (
                <Button id={statement.id} className="text-right ml-1" size="sm" onClick={() => {
                    StatementsDataService.delete(statement.id);
                    removeUDHRStatement(statement.id)}}>
                Delete
                </Button>

            )
    }

    const showEditButton = (statement) => {
        if (userid === String(statement.user.pk))
            return (
                <Button id={statement.id} className="text-right ml-1" size="sm" onClick={handleEdit}>
                Edit
            </Button>
            )
    }

    // Keeps the statements to be shown
    // let udhrStatements = null;
    let udhrStatementsExist = true;

    const udhrStatements = (category === 10) ? learn_statements : udhr_statements;
    
    // learnStatements has to be tested first!

    // console.log("check condition of learnStatements", (learn_statements.length > 0));

    // if (!learn_statements) {
    //     // console.log("learn_statements", learn_statements);
    //     udhrStatementsExist = false;
    // } else {
    //     if (learn_statements.length > 0) {
    //         // console.log("learn_statement SET", learn_statements);
    //         udhrStatementsExist = true;
    //         udhrStatements = learn_statements;
    //         console.log("learn_statements", learn_statements)
    //     }       
    // }
   

    // if (!udhrStatementsExist) {
    //     if ((udhr_statements) && (udhr_statements.length > 0)) {
    //         udhrStatementsExist = true;
    //         udhrStatements = udhr_statements;
    //         console.log("udhrStatements", udhr_statements);
    //     }
    // }

    // if (!udhr_statements || udhr_statements.length === 0) return null; //<p>No statements, sorry</p>;

    // console.log("UDHRStatement, render, category", category);
    // console.log("UDHRStatement, render, udhrStatements", udhrStatementsExist, udhrStatements, learn_statements, udhr_statements);

    return (
        <Fragment>     

        {(udhrStatementsExist) ? (
          
            <Col sm={12} md={12} className="p-0 d-flex justify-content-center">
                <ListGroup as="ul" variant="flush" className="p-0 w-100 justify-content-center">
                    
                    {udhrStatements.map(udhrStatements => (
                        <Statement key={udhrStatements.id} statement={udhrStatements} area={AREA_UDHR} thisEditStatement={thisEditStatement} /> 
                    ))}

                </ListGroup>
            </Col>
        ) : (
          <p>No data</p>
        )}
      </Fragment>
    );
  };