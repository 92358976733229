import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, ListGroup, Image } from "react-bootstrap";
import ReactRoundedImage from "react-rounded-image";
import { useUserAuthSelector } from "../../state/typedSelectors";
import {API_STATIC} from "../../constants/apiUrl"

function Friend({ user, userObject }) {

  // const localObject = userObject;

  // console.log(userObject);
 
  return( 
      <ListGroup.Item key={user.pk} id={user.pk} className="list-group-item p-0 m-1 border-0">
        <LinkContainer className="m-0" to={{ pathname: `/home/user/${user.pk}`, state: { userObject } }}> 
            <Nav.Link className="p-0" eventKey={`/home/user/${user.id}`}>
            <div className="d-flex align-self-end justify-content-center">

            { (user.image) ? (

              <ReactRoundedImage 
              image={API_STATIC+user.image} 
              imageWidth="56"
              imageHeight="56"
              borderRadius="15"
              roundedSize={user.online ? '6' : '0'}
              roundedColor="Green"
            />
            
            ): null

            }

            </div>
          </Nav.Link>
        </LinkContainer>
      </ListGroup.Item>
  );
}

Friend.propTypes = {
  user: PropTypes.object.isRequired
};

export default Friend;
