import React, { useState } from "react";
import axios from "axios";
import * as Io from "react-icons/io";
import { Form, Button, Card, InputGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
// import { GlobalContext } from "../../../../state/GlobalState";
import FormWrapper from "../formWrapper";
import { passwordResetRequest } from "../passwordReset/passwordResetLinks";
import {
  handleInputChange,
  displayServerErrorMessagesInErrorDiv,
  displaySingleErrorMessageInErrorDiv,
  displayInputErrorMessages,
  hideAllInputErrorMessages
} from "../../../utils/formUtils";
import * as fieldNames from "./loginFormFieldNames";
import { register } from "../userAccessLinks";
import { API_LOGIN_URL } from "../../../../constants/apiUrl";
import { startUserSession, saveSettings } from "../../../../state/userAuthState/userAuthActions";
import { updateUserProfile } from "../../../../state/userProfileState/userProfileActions";
import { clearAlerts } from "../../../../state/alertsState/alertActions";
import { setLoadingAlertVisibility } from "../../../../state/alertsState/alertActions";
import { SettingsInterface } from "../../../../state/userAuthState/userAuthTypes";
import { http, setAuthToken } from '../../../../../services/http-common';


export default ({setShowLoginModal}) => {

  const [show, setShow] = useState(true);
  const [userDetails, setUserDetails] = useState({[fieldNames.PASSWORD]: "", [fieldNames.USERNAME]: ""});

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setShowLoginModal(false);
  }

  
  const updateFormElement = (e) =>
    handleInputChange(e, userDetails, setUserDetails);

  return (

    <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Welcome to GAR217A!</Modal.Title>
          </Modal.Header>
          <Modal.Body>

        <div className="d-flex flex-column align-items-center">
          <p><h4>Log in or sign up to get access.</h4></p><br></br>
          <p><h1>Waiting for you!</h1></p>
        </div>
    
    <FormWrapper>
      <Card>
        <Card.Body>
          <Card.Title>Login</Card.Title>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              hideAllInputErrorMessages([
                fieldNames.USERNAME,
                fieldNames.PASSWORD
              ]);
              let errorMessageFieldnames = [];
              if (userDetails[fieldNames.USERNAME].length === 0) {
                errorMessageFieldnames.push([fieldNames.USERNAME]);
              }
              if (userDetails[fieldNames.PASSWORD].length === 0) {
                errorMessageFieldnames.push([fieldNames.PASSWORD]);
              }
              if (errorMessageFieldnames.length > 0) {
                displayInputErrorMessages(errorMessageFieldnames);
                return;
              }
              const submitBtn = document.getElementById(
                fieldNames.SUBMIT
              );
              submitBtn.disabled = true;
              (document.getElementById(fieldNames.FORM_ERROR_DIV_ID)).innerHTML = "";
              dispatch(setLoadingAlertVisibility("loading"));
              axios
                .post(API_LOGIN_URL, {
                  username: userDetails[fieldNames.USERNAME],
                  password: userDetails[fieldNames.PASSWORD]
                })
                .then(response => {
                  console.log("loginPage, response.data", response.data);
                  
                  dispatch(clearAlerts());
                  
                  dispatch(
                    startUserSession(
                      response.data.key,
                      userDetails.login_username,
                      response.data.pk,
                      response.data.first_name,
                      response.data.last_name,
                      response.data.email,
                      response.data.image,
                      response.data.online,
                      response.data.number_of_logins
                    ));

                  setAuthToken(response.data.key);
                  
                  dispatch(
                    updateUserProfile(
                      response.data.username,
                      response.data.first_name,
                      response.data.last_name,
                      response.data.email,
                      response.data.gar_avatar_name,
                      response.data.image,
                      response.data.last_seen,
                      response.data.online,
                      response.data.created,
                      response.data.status,
                      response.data.date_of_birth,
                      response.data.born_in,
                      response.data.living_in,
                      response.data.city,
                      response.data.bio,
                      response.data.last_saved
                )  
                  );

                  dispatch(push("/"));
                })
                .catch(error => {
                  if (error["response"] !== undefined) 
                  

                  {

                    displayServerErrorMessagesInErrorDiv(
                      fieldNames.FORM_ERROR_DIV_ID,
                      error.response.data
                    );
                    submitBtn.disabled = false;
                  } else if (error.request) {

                    displaySingleErrorMessageInErrorDiv(
                      fieldNames.FORM_ERROR_DIV_ID,
                      error.message
                    );

                    submitBtn.disabled = false;
                  
                  } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('ErrorMESSAGE', error.message);
                    console.log("JSON", error.toJSON());
                  }
                  console.log("CONFIG", error.config);
                })
                .finally(() =>
                  dispatch(setLoadingAlertVisibility("finishing"))
                );
            }}
          >
            <Form.Group controlId="formEmail">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id={`${fieldNames.USERNAME}-prepend`}>
                    <Io.IoMdPerson />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name={fieldNames.USERNAME}
                  value={userDetails[fieldNames.USERNAME]}
                  type="text"
                  placeholder="Username"
                  onChange={updateFormElement}
                />
              </InputGroup>
              <Form.Text
                className="d-none text-danger"
                id={fieldNames.USERNAME + "-error"}
              >
                Please enter a Username
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formPassword">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id={`${fieldNames.PASSWORD}-prepend`}>
                    <Io.IoMdLock />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name={fieldNames.PASSWORD}
                  value={userDetails[fieldNames.PASSWORD]}
                  type="password"
                  placeholder="Password"
                  onChange={updateFormElement}
                />
              </InputGroup>
              <Form.Text
                className="d-none text-danger"
                id={fieldNames.PASSWORD + "-error"}
              >
                Please enter a Password
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="submit">
              <Button id={fieldNames.SUBMIT} variant="primary" type="submit">
                Submit
              </Button>
                <div className="pt-3" id={fieldNames.FORM_ERROR_DIV_ID}>
                </div>
            </Form.Group>
          </Form>
          <Card.Text className="">
            Don't have an account?{" "}
            <Link to={register.link} className="st-link">
              {register.displayName}
            </Link>
          </Card.Text>
          <Card.Text>
            Forgot your Password?{" "}
            <Link to={passwordResetRequest.link} className="st-link">
              {passwordResetRequest.displayName}
            </Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </FormWrapper>

    </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>

  );
};
