import axios from "axios";
import { getAge } from '../../../utils/dateTime';
import { push } from "connected-react-router";
import { login } from "../userAccessLinks";
import {
  setSuccessAlerts,
  setLoadingAlertVisibility
} from "../../../../state/alertsState/alertActions";

import {
  hideAllInputErrorMessages,
  displayInputErrorMessages,
  displayServerErrorMessagesInErrorDiv,
  toggleSubmitButton
} from "../../../utils/formUtils";
import {
  USERNAME,
  PASSWORD,
  EMAIL,
  LEGAL_AGREEMENT,
  SUBMIT,
  FORM_ERROR_DIV_ID,
  DATE_OF_BIRTH
} from "./fieldNames";
import { UserRegistrationForm } from "./registrationPage";
import { API_REGISTRATION_URL } from "../../../../constants/apiUrl";
import { emailRegex, passwordRegex } from "../../../../constants/regex";
import { REPEATED_PASSWORD } from "../../../groups/GroupProfileFormFieldNames";

interface RegistrationPayload {
  username: string;
  dateOfBirth: string;
  password1: string;
  password2: string;
  email: string;
}

const getFaultyInputFieldNames = (formState: UserRegistrationForm) => {


  console.log(getAge(formState.dateOfBirth));

  const faultyInputFieldNames: Array<String> = [];

  if (formState.username.length < 6 || /\s/g.test(formState.username)) {
    faultyInputFieldNames.push(USERNAME);
  }
  if (getAge(formState.dateOfBirth) < 13) {
    faultyInputFieldNames.push(DATE_OF_BIRTH);
  }
  if (!passwordRegex.test(formState.password)) {
    faultyInputFieldNames.push(PASSWORD);
  }
  if (!emailRegex.test(formState.email)) {
    faultyInputFieldNames.push(EMAIL);
  }
  if (!formState.legalAgreement) {
    faultyInputFieldNames.push(LEGAL_AGREEMENT);
  }
  return faultyInputFieldNames;
};

export default (
  event: React.FormEvent<HTMLFormElement>,
  formInputValues: UserRegistrationForm,
  reduxActionDispatch: Function
) => {
  event.preventDefault();
  const formKeys = [];
  for (let key in formInputValues) {
    formKeys.push(key);
  }
  hideAllInputErrorMessages(formKeys);
  const faultyInputFieldNames = getFaultyInputFieldNames(formInputValues);
  if (faultyInputFieldNames.length > 0) {
    displayInputErrorMessages(faultyInputFieldNames);
  } else {
    const registrationPayload: RegistrationPayload = {
      username: formInputValues.username,
      dateOfBirth: formInputValues.dateOfBirth,
      email: formInputValues.email,
      password1: formInputValues.password,
      password2: formInputValues.repeatPassword
    };
    
    let errorMessageFieldnames = [];
    if (formInputValues[USERNAME].length === 0) {
      errorMessageFieldnames.push(USERNAME);
    }
    if (formInputValues[DATE_OF_BIRTH].length === 0) {
      errorMessageFieldnames.push(DATE_OF_BIRTH);
    }
    if (formInputValues[EMAIL].length === 0) {
      errorMessageFieldnames.push(EMAIL);
    }
    if (formInputValues[PASSWORD].length === 0) {
      errorMessageFieldnames.push(PASSWORD);
    }

    
    if (errorMessageFieldnames.length > 0) {
      displayInputErrorMessages(errorMessageFieldnames);
      return;
    }

    
    
    toggleSubmitButton(SUBMIT);
    (document.getElementById(FORM_ERROR_DIV_ID) as HTMLElement).innerHTML = "";
    reduxActionDispatch(setLoadingAlertVisibility("loading"));
    axios
      .post(API_REGISTRATION_URL, registrationPayload)
      .then(response => {
        reduxActionDispatch(push(login.link));
        reduxActionDispatch(
          setSuccessAlerts([
            `We sent an email to ${formInputValues.email} - please confirm it to activate your account!`
          ])
        );
      })
      .catch((error: any) =>
        {
          // console.log("submission - error", error);
          // console.log("submission - error.response.data", error.response.data);
          displayServerErrorMessagesInErrorDiv(FORM_ERROR_DIV_ID, error.response.data)
          toggleSubmitButton(SUBMIT);
        }
      )
      .finally(() => {
        reduxActionDispatch(setLoadingAlertVisibility("finishing"))
      }
      );
  }
};
