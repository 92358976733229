import React, { useContext } from 'react';
import { GlobalContext } from "./GlobalState";

export default (state, action) => {

  // const { blockers } = useContext(GlobalContext);

    switch (action.type) {        
      
// ****************************************   STATEMENTS ********************************************

      case "ADD_STATEMENT":
        const activeStatements = 'statements';
        
      const newStatements = (activeStatements==='statements') ? ([action.payload, ...state.statements]) :
      ([action.payload, ...state.udhr_statements]);

      const newStatementsState = (activeStatements==='statements') ? 
      {
        ...state,
        statements: newStatements
      } : {
        ...state,
        user_statements: newStatements
      } ;

        return newStatementsState;

      case "EDIT_STATEMENT":
        const updatedStatement = action.payload;
        
        console.log("STATEMENT updatedStatement", updatedStatement);
        console.log("STATEMENT state.statements", state.statements);
  
        const updatedStatements = state.statements.map(statement => {
          if (statement.id === updatedStatement.id) {
            // console.log("updatedStatement", updatedStatement);
            return updatedStatement;
          }
          return statement;
        });
  
        return {
          ...state,
          statements: updatedStatements
        };

        case "REMOVE_STATEMENT":
        return {
          ...state,
          statements: state.statements.filter(
            statement => statement.id !== action.payload
          )
        };

      case "LOAD_STATEMENTS":
        console.log("LOAD_STATMENTS", action.payload);
          return {
            ...state,
            statements: action.payload
          };


// ****************************************   USER_STATEMENTS ********************************************


// case "ADD_USER_STATEMENT":
//   const newStatements = [action.payload, ...state.user_statements];
//   return {
//     ...state,
//     user_statements: newStatements
//   };

// case "EDIT_USER_STATEMENT":
//   const updatedStatement = action.payload;
  
//   console.log("updatedStatement", updatedStatement);
//   console.log("state.statements", state.user_statements);

//   const updatedStatements = state.user_statements.map(statement => {
//     if (statement.id === updatedStatement.id) {
//       return updatedStatement;
//     }
//     return statement;
//   });

//   return {
//     ...state,
//     user_statements: updatedStatements
//   };


//   case "REMOVE_USER_STATEMENT":
//   return {
//     ...state,
//     user_statements: state.user_statements.filter(
//       statement => statement.id !== action.payload
//     )
//   };

// case "LOAD_USER_STATEMENTS":
//     return {
//       ...state,
//       user_statements: action.payload
//     };

// ****************************************   UDHR_STATEMENTS  ********************************************

case "ADD_UDHR_STATEMENT":
  const newUDHRStatements = [action.payload, ...state.udhr_statements];
  return {
    ...state,
    udhr_statements: newUDHRStatements
  };

case "EDIT_UDHR_STATEMENT":
  const updatedUDHRStatement = action.payload;
  
  // console.log("updatedUDHRStatement", updatedUDHRStatement);
  // console.log("state.udhr_statements", state.udhr_statements);

  const updatedUDHRStatements = state.udhr_statements.map(udhr_statement => {
    if (udhr_statement.id === updatedUDHRStatement.id) {
      return updatedUDHRStatement;
    }
    return udhr_statement;
  });

  return {
    ...state,
    udhr_statements: updatedUDHRStatements
  };


  case "REMOVE_UDHR_STATEMENT":
  return {
    ...state,
    udhr_statements: state.udhr_statements.filter(
      udhr_statement => udhr_statement.id !== action.payload
    )
  };



  case "LOAD_UDHR_STATEMENTS":
          return {
            ...state,
            udhr_statements: action.payload
          };
  


// ****************************************   LEARN_STATEMENTS  ********************************************

case "EDIT_LEARN_STATEMENT":
  const updatedLearnStatement = action.payload;
  
  console.log("updatedLearnStatement", updatedLearnStatement);
  console.log("state.learn_statements", state.learn_statements);

  const updatedLearnStatements = state.learn_statements.map(learn_statement => {
    if (learn_statement.id === updatedLearnStatement.id) {
      return updatedLearnStatement;
    }
    return learn_statement;
  });

  return {
    ...state,
    learn_statements: updatedLearnStatements
  };

  case "LOAD_LEARN_STATEMENTS":
        // console.log("LOAD_LEARN_STATEMENTS", action.payload);
          return {
            ...state,
            learn_statements: action.payload
          };
  



// ****************************************   USERS  ********************************************


        case "LOAD_USERS":
          // console.log("LOAD_USERS", state);
          return {
            ...state,
            users: action.payload
          };  

          case "LOAD_USER":
            console.log("LOAD_USER", state);
            return {
              ...state,
              user: action.payload
            };  
  
// ****************************************   BLOCKERS  ********************************************


          case "LOAD_BLOCKERS":
            console.log("LOAD_BLOCKERS")
            return {
              ...state,
              blockers: action.payload
            };  
  
          case "UNBLOCK_USER":
            {
              console.log("UNBLOCK_USER: blockers before reduction", state.blockers);

              const newBlockers = {
                ...state,
                blockers: state.blockers.filter(
                  block => block.pk !== action.payload.pk
                )
              }

              console.log("UNBLOCK_USER: newBlockers after reduction", newBlockers);

              return newBlockers;
            }
    
            case "BLOCK_USER":
              
              // When you block a user. The user should be added to the list of blockers,
              // and extracted from the STATE of friends and followees.
              // Then, how do I extract theese guys from the STATE of friends and followees?
              // I could run the query on friends and followees, or I can extract 
              // the blocked user from friends and followees state.
              // Could this be done by triggering a removeFriend and removeFollowee action?
              // Or should I manipulate the state in the reducer it self?


              const newBlockers = [action.payload, ...state.blockers];
              console.log("BLOCK: newBlockers", newBlockers);

              return {
                ...state,
                blockers: newBlockers
              };
    
              // ****************************************   FRIENDS  ********************************************


          case "LOAD_FRIENDS":
            return {
              ...state,
              friends: action.payload
            };
    
          case "ADD_FRIEND":
            const newFriends = [action.payload, ...state.friends];
            return {
              ...state,
              friends: newFriends
            };

          case "EDIT_FRIEND":
            const updatedFriend = action.payload;
            console.log("updatedStatement", updatedFriend);
            console.log("state.statements", state.friends);
      
            const updatedFriends = state.friends.map(friend => {
              if (friend.pk === updatedFriend.pk) {
                return updatedFriend;
              }
              return friend;
            });
      
            console.log("updatedFriendS", updatedFriends);
      
            return {
              ...state,
              friends: updatedFriends
            };

          case "REMOVE_FRIEND":
            return {
              ...state,
              friends: state.friends.filter(
                friend => friend.pk !== action.payload.pk
              )
            };
    
    
    
  // ****************************************   FOLLOWERS  ********************************************

  case "REMOVE_FOLLOWER":
    return {
      ...state,
      followers: state.followers.filter(
        follower => follower.pk !== action.payload.pk
      )
    };

  case "ADD_FOLLOWER":
    
    const newFollowers = [action.payload, ...state.followers];
    
    return {
      ...state,
      followers: newFollowers
    };

  case "LOAD_FOLLOWERS":
  return {
    ...state,
    followers: action.payload
  };

    // ****************************************   FOLLOWEES  ********************************************

  case "LOAD_FOLLOWEES":
    return {
      ...state,
      followees: action.payload
    };

  case "REMOVE_FOLLOWEE":
    console.log("REMOVE_FOLLOWEE", action.payload);
    return {
      ...state,
      followees: state.followees.filter(
        followee => followee.pk !== action.payload.pk
      )
    };

  case "ADD_FOLLOWEE":
    console.log("ADD_FOLLOWEE", action.payload);
    const newFollowees = [action.payload, ...state.followees];
    return {
      ...state,
      followees: newFollowees
    };



// ****************************************   GROUPS ********************************************
      

case "ADD_GROUP":
  const newGroups = [action.payload, ...state.groups];
  return {
    ...state,
    groups: newGroups
  };

case "EDIT_GROUP":
  const updatedGroup = action.payload;
 
  const updatedGroups = state.groups.map(group => {
    if (group.id === updatedGroup.id) {
      // console.log("updatedStatement", updatedStatement);
      return updatedGroup;
    }
    return group;
  });

  return {
    ...state,
    groups: updatedGroups
  };

  case "REMOVE_GROUP":
    console.log("REMOVE_GROUP", action.payload, state.groups);
    return {
      ...state,
      groups: state.groups.filter(
        group => group.id !== action.payload
      )
    };

  case "LOAD_GROUPS":
    console.log("Groups action.payload", action.payload)
    return {
      ...state,
      groups: action.payload
    };



    case "ADD_GROUP_MEMBER":
      const newMembers = [action.payload, ...state.group_members];
      return {
        ...state,
        group_members: newMembers
      };

      case "REMOVE_GROUP_MEMBER":
        return {
          ...state,
          group_members: state.group_members.filter(
            member => member.pk !== action.payload.pk
          )
        };


      case "LOAD_GROUP_MEMBERS":
      console.log("Group members action.payload", action.payload)
      return {
        ...state,
        group_members: action.payload
      };

      case "LOAD_GROUP_MEMBERSHIP_REQUESTS":
    return {
      ...state,
      group_membership_requests: action.payload
    };

    case "REMOVE_GROUP_FOLLOWER":
      return {
        ...state,
        group_followers: state.group_followers.filter(
          follower => follower.pk !== action.payload.pk
        )
      };
  
    case "ADD_GROUP_FOLLOWER":
      
      const newGroupFollowers = [action.payload, ...state.group_followers];
      
      return {
        ...state,
        group_followers: newGroupFollowers
      };
  
  
  case "LOAD_GROUP_FOLLOWERS":
    return {
      ...state,
      group_followers: action.payload
    };

    
    case "REMOVE_GROUP_FOLLOWEE":
    
    console.log("REMOVE_GROUP_FOLLOWEE old State", state.group_followees);

    const res1 = {
      ...state,
      group_followees: state.group_followees.filter(
        followee => followee.pk !== action.payload.pk
      )
    };

    console.log("REMOVE_GROUP_FOLLOWEE - new State", res1);

    return res1;

  case "ADD_GROUP_FOLLOWEE":
    console.log("ADD_FOLLOWEE", action.payload);
    const newGroupFollowees = [action.payload, ...state.group_followees];
    return {
      ...state,
      group_followees: newGroupFollowees
    };

  
    case "LOAD_GROUP_FOLLOWEES":
    return {
      ...state,
      group_followees: action.payload
    };

    
  case "LOAD_GROUP_BLOCKERS":
    return {
      ...state,
      group_blockers: action.payload
    };

  case "BLOCK_GROUP":
   
      const newBlocks = [action.payload, ...state.group_blockers];
      console.log("BLOCK: newBlockers", newBlocks);

      return {
        ...state,
        group_blockers: newBlocks
      };

  case "UNBLOCK_GROUP":
    {
      console.log("UNBLOCK_GROUP: blockers before reduction", state.group_blockers);
      
      const newGroupBlockers = {
        ...state,
        group_blockers: state.group_blockers.filter(
          block => block.pk !== action.payload.pk
        )
      }

      console.log("UNBLOCK_USER: newBlockers after reduction", newGroupBlockers);

      return newGroupBlockers;
    }
  

//***************************************  UDHR GROUPS */

case "LOAD_UDHR_GROUPS":
  console.log("NB! UDHR_GROUPS action.payload", action.payload)
  return {
    ...state,
    udhr_groups: action.payload
  };
//***************************************  SEARCH  STRING*/

case "SET_SEARCH_STRING":
  console.log("searchString action.payload", action.payload)
  return {
    ...state,
    searchString: action.payload
  };

//************************************ PROJECTS  */

  case "LOAD_GROUP_PROJECTS":
    return {
      ...state,
      group_projects: action.payload
    };

      
    default:
    return state;
  }

};
