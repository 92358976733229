import React from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { userProfileLink, accountSettingsLink, friendsLink, friendRequestsLink, followersLink, followsLink, blockLink, groupsLink, projectsLink } from "./UserProfileLinks";

const StyledLink = styled(Nav.Link)`
color: grey;
font-size: 1.0rem;
margin-top: 2px;
${props => props.active && `
    //color: blue;
    //border-bottom: 3px solid green;
  `}
`;

export default () => {
  
  return (

<>


         <Nav defaultActiveKey="/profile">
             <Navbar
               bg="white"
               id="connection-menu-main-style"
               className="d-flex flex-column p-0 align-items-start"
              collapseOnSelect
             >

              <LinkContainer to={userProfileLink.link}>
                <StyledLink eventKey={userProfileLink.link}>
                  {userProfileLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={accountSettingsLink.link}>
                <StyledLink eventKey={accountSettingsLink.link}>
                  {accountSettingsLink.displayName}
                </StyledLink>
              </LinkContainer>


              <LinkContainer to={friendsLink.link}>
                <StyledLink eventKey={friendsLink.link}>
                  {friendsLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={friendRequestsLink.link}>
                <StyledLink eventKey={friendRequestsLink.link}>
                  {friendRequestsLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={followersLink.link}>
                <StyledLink eventKey={followersLink.link}>
                  {followersLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={followsLink.link}>
                <StyledLink eventKey={followsLink.link}>
                  {followsLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={blockLink.link}>
                <StyledLink eventKey={blockLink.link}>
                  {blockLink.displayName}
                </StyledLink>
              </LinkContainer>


 
              <LinkContainer to={groupsLink.link}>
                <StyledLink eventKey={groupsLink.link}>
                  {groupsLink.displayName}
                </StyledLink>
              </LinkContainer>
{/*
              <LinkContainer to={projectsLink.link}>
                <StyledLink eventKey={projectsLink.link}>
                  {projectsLink.displayName}
                </StyledLink>
            </LinkContainer>
 */}
  
             </Navbar>
            
           </Nav>

           
    </>


 );
};


