import React, { SyntheticEvent, useState } from "react";
import axios from "axios";
import { Modal, Form, Button, Card, CardGroup, InputGroup} from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";


import {
  handleInputChange,
  displayServerErrorMessagesInErrorDiv,
  displayInputErrorMessages,
  hideAllInputErrorMessages
} from "../utils/formUtils";

import * as fieldNames from "./GroupProfileFormFieldNames";
import { API_GROUP_URL } from "../../constants/apiUrl";
import { clearAlerts, setSuccessAlerts, setNegativeAlerts, setLoadingAlertVisibility } from "../../state/alertsState/alertActions";
import { useUserAuthSelector, useUserProfileSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import { updateGroupProfile } from "../../state/groupProfileState/groupProfileActions";

function formatJsonDate(jsonDate: string) {
  return new Date(jsonDate.split("T")[0]).toLocaleDateString();
}; 

const StyledInputGroup = styled(InputGroup)`
  font-size: 1.2rem;
  size: "sm";
${props => props.active && `
    // color: red;
    // border-bottom: 3px solid green;
  `}
`;

interface UserFormDetails {
[fieldNames.NAME]: string;
[fieldNames.USERNAME]: string;
[fieldNames.EMAIL]: string;
[fieldNames.ESTABLISHED]: string;
[fieldNames.DESCRIPTION]: string;
[fieldNames.CODE]: string;
[fieldNames.COUNTRY]: string;
[fieldNames.CITY]: string;
}

export default () => {

  let history = useHistory();

  const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

    const { authToken} = useUserAuthSelector(
      state => state.userAuthReducer
    );

    const { pk, id, user, owner, admin, name, established, description, code, image, country, city, homepageViewAllowed, homepageAddAllowed } = useGroupProfileSelector(
        state => state.groupProfileReducer
      );    


    console.log("USER", code, image, user);

    const establishedDate = (established) ? String(established) : "";
  
    const [userDetails, setUserDetails] = useState<UserFormDetails>({
        [fieldNames.NAME]: String(name),
        [fieldNames.USERNAME]: String(user.username),
        [fieldNames.EMAIL]: String(user.email),
        [fieldNames.ESTABLISHED]: establishedDate,
        [fieldNames.DESCRIPTION]: String(description),
        [fieldNames.CODE]: String(code),
        [fieldNames.COUNTRY]: String(country),
        [fieldNames.CITY]: String(city),
    });

    const dispatch = useDispatch();
    
    const updateFormElement = (e: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) =>
      handleInputChange(e, userDetails, setUserDetails);
  
    const handleStatusSelect  = (eventKey: string) => {
      console.log(eventKey);
    }

    // console.log(formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH]));
    // console.log(userDetails[fieldNames.DATE_OF_BIRTH]);

    return (
      <>
        <Button variant="primary" size="sm" onClick={handleShow}>
          Edit profile
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Profile form</Modal.Title>
          </Modal.Header>
          <Modal.Body> 
  
          
                <Form 
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    
                    e.preventDefault();
                    
                    hideAllInputErrorMessages([
                    //   fieldNames.DATE_OF_BIRTH,
                    //   fieldNames.FIRSTNAME,
                    //   fieldNames.LASTNAME,
                      fieldNames.EMAIL
                    ]);
                    
                    let errorMessageFieldnames = [];
                    
                    // if (userDetails[fieldNames.DATE_OF_BIRTH].length === 0) {
                    //    errorMessageFieldnames.push(fieldNames.DATE_OF_BIRTH);
                    //  }

                    // if (userDetails[fieldNames.FIRSTNAME].length === 0) {
                    //   errorMessageFieldnames.push(fieldNames.FIRSTNAME);
                    // }
                    
                    // if (userDetails[fieldNames.LASTNAME].length === 0) {
                    //   errorMessageFieldnames.push(fieldNames.LASTNAME);
                    // }
                    
                    if (userDetails[fieldNames.NAME].length === 0) {
                        errorMessageFieldnames.push(fieldNames.NAME);
                      }

                    if (userDetails[fieldNames.EMAIL].length === 0) {
                      errorMessageFieldnames.push(fieldNames.EMAIL);
                    }
                    
                    if (errorMessageFieldnames.length > 0) {
                      displayInputErrorMessages(errorMessageFieldnames);
                      return;
                    }
                    
                    const submitBtn = document.getElementById(
                      fieldNames.SUBMIT
                    ) as HTMLInputElement;
                    submitBtn.disabled = true;
                    (document.getElementById(
                      fieldNames.FORM_ERROR_DIV_ID
                    ) as HTMLElement).innerHTML = "";
                    
                    dispatch(setLoadingAlertVisibility("loading"));
                   
                    let config = {
                      headers: {
                        "Authorization": `Token ${authToken}`
                      }
                    }

                    let data = new FormData();
                    
                    data.append("name", userDetails[fieldNames.NAME]);
                    data.append("email", userDetails[fieldNames.EMAIL]);                                      
                    data.append("established", userDetails[fieldNames.ESTABLISHED]);                                      
                    data.append("description", userDetails[fieldNames.DESCRIPTION]);                                      
                    data.append("code", userDetails[fieldNames.CODE]);                                      
                    data.append("country", userDetails[fieldNames.COUNTRY]);                                      
                    data.append("city", userDetails[fieldNames.CITY]);                                      
                    
                    console.log("", data);

                    axios
                    .patch(API_GROUP_URL+id+"/", data, config)
                      .then(response => {
                        
                        console.log(response.data);

                        dispatch(clearAlerts());
                        
                        

                        dispatch(
                          updateGroupProfile(
                            pk, 
                            id,
                            user,
                            owner,
                            admin, 
                            userDetails[fieldNames.NAME], 
                            userDetails[fieldNames.ESTABLISHED], 
                            userDetails[fieldNames.DESCRIPTION], 
                            userDetails[fieldNames.CODE], 
                            image,
                            userDetails[fieldNames.COUNTRY], 
                            userDetails[fieldNames.CITY], 
                            homepageViewAllowed,
                            homepageAddAllowed            
                          )  
                        );
                        
                        
                        dispatch(
                          setSuccessAlerts([
                            `Group profile updated!`
                          ])
                        );

                        submitBtn.disabled = false;

                        setShow(false);

                        })
                      .catch(error => {
                        if (error["response"] !== undefined) {

                          dispatch(
                            setNegativeAlerts([
                              `Something went wrong. Profile not updated.`
                            ])
                          );
  
                          console.log(error.response.data);

                          displayServerErrorMessagesInErrorDiv(
                            fieldNames.FORM_ERROR_DIV_ID,
                            [error.response.data]
                          );

                          submitBtn.disabled = false;
                        } else {
                          
                          console.log(error);

                          submitBtn.disabled = false;
                        }
                      })
                      .finally(() =>
                        dispatch(setLoadingAlertVisibility("finishing"))
                      );

                  }}
                >                  

                  
                  <Form.Group className="mb-1" controlId="formFirstname">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.NAME}-formcontrol`}
                          id={`${fieldNames.NAME}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            First name:
                        </label>                                            
                      </Col>

  
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          name={fieldNames.NAME}
                          value={userDetails[fieldNames.NAME]}
                          type="text"
                          placeholder="Name"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.NAME + "-error"}
                    >
                      Please enter the groups name
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formEmail">
                    <InputGroup size="sm">
                      
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.EMAIL}-formcontrol`}
                          id={`${fieldNames.EMAIL}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Email:
                        </label>                                            
                      </Col>

                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          // id={`${fieldNames.EMAIL}-formcontrol`}
                          name={fieldNames.EMAIL}
                          value={userDetails[fieldNames.EMAIL]}
                          type="email"
                          placeholder="Email address"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.EMAIL + "-error"}
                    >
                      Please enter your email address
                    </Form.Text>
                  
                  </Form.Group>


                  <Form.Group className="mb-1" controlId="formCountry">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.COUNTRY}-formcontrol`}
                          id={`${fieldNames.COUNTRY}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Country:
                        </label>                                            
                      </Col>

  
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          name={fieldNames.COUNTRY}
                          value={userDetails[fieldNames.COUNTRY]}
                          type="text"
                          placeholder="Country"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.COUNTRY + "-error"}
                    >
                      Please enter the country your group resides in
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formCity">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.CITY}-formcontrol`}
                          id={`${fieldNames.CITY}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            City:
                        </label>                                            
                      </Col>

  
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                        <Form.Control
                          size="sm"
                          name={fieldNames.CITY}
                          value={userDetails[fieldNames.CITY]}
                          type="text"
                          placeholder="City"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.CITY + "-error"}
                    >
                      Please enter the city your group resides in
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-1 " controlId="formEstablished">
                    <InputGroup size="sm">
                      
                      <Col className="m-0 p-0 d-flex flex-column">
                        <label 
                          htmlFor={fieldNames.ESTABLISHED + "-formcontrol"} 
                          id={`${fieldNames.ESTABLISHED}-prepend`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Established:
                        </label>                                            
                      </Col>

                      <Col className="d-flex flex-column justify-content-center">
                        <Form.Control
                          size="sm"
                          // id={fieldNames.DATE_OF_BIRTH + "-formcontrol"}
                          name={fieldNames.ESTABLISHED}
                          value={userDetails[fieldNames.ESTABLISHED]}
                          // value={formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH])}
                          type="date"
                          placeholder="Date of birth"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.ESTABLISHED + "-error"}
                    >
                      Please enter the date your group was established
                    </Form.Text>
                  </Form.Group> 



                  <Form.Group className="mb-1" controlId="formDescription">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-start align-items-start">
                        <label 
                          htmlFor={`${fieldNames.DESCRIPTION}-textarea`}
                          id={`${fieldNames.DESCRIPTION}-label`}  
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Group description:
                        </label>                                            
                      </Col>
                      
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                      <textarea 
                        rows={5} 
                        cols={40}
                        placeholder="Description..."                         
                        name={fieldNames.DESCRIPTION}
                        value={userDetails[fieldNames.DESCRIPTION]}
                        onChange={updateFormElement}
                        className="border border-secondary"
                        style={{borderColor: "grey"}}
                      />
                 
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.DESCRIPTION + "-error"}
                    >
                      Please enter a description of the group
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formCode">
                    <InputGroup size="sm">

                    <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-start">
                        <label 
                          htmlFor={`${fieldNames.CODE}-formcontrol`}
                          id={`${fieldNames.CODE}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Code of conduct:
                        </label>                                            
                      </Col>

  
                      <Col sm={0} md={8} className=" d-block d-flex flex-column justify-content-start">
                      <textarea 
                        rows={5} 
                        cols={40}
                        placeholder="Code of Conduct..."                         
                        name={fieldNames.CODE}
                        value={userDetails[fieldNames.CODE]}
                        onChange={updateFormElement}
                        style={{borderColor: "grey"}}
                      />
                      </Col>

                    </InputGroup>

                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.CODE + "-error"}
                    >
                      Please enter your groups Code of Conduct
                    </Form.Text>
                  </Form.Group>


{/* 
                  <Form.Group className="mb-1 " controlId="formDateOfBirth">
                    <InputGroup size="sm">
                      
                      <Col className="m-0 p-0 d-flex flex-column">
                        <label 
                          htmlFor={fieldNames.DATE_OF_BIRTH + "-formcontrol"} 
                          id={`${fieldNames.DATE_OF_BIRTH}-prepend`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Born:
                        </label>                                            
                      </Col>

                      <Col className="d-flex flex-column justify-content-center">
                        <Form.Control
                          size="sm"
                          // id={fieldNames.DATE_OF_BIRTH + "-formcontrol"}
                          name={fieldNames.DATE_OF_BIRTH}
                          value={userDetails[fieldNames.DATE_OF_BIRTH]}
                          // value={formatJsonDate(userDetails[fieldNames.DATE_OF_BIRTH])}
                          type="date"
                          placeholder="Date of birth"
                          onChange={updateFormElement}
                        />
                      </Col>

                    </InputGroup>
                    <Form.Text
                      className="d-none text-danger"
                      id={fieldNames.DATE_OF_BIRTH + "-error"}
                    >
                      Please enter your date of birth
                    </Form.Text>
                  </Form.Group>  */}

{/* 
                  <Form.Group className="mb-1" controlId="formStatus">
                    <InputGroup size="sm">
                      <Col sm={0} md={4} className="m-0 p-0 d-flex flex-column justify-content-end">
                        <label 
                          htmlFor={`${fieldNames.STATUS}-formcontrol`}
                          id={`${fieldNames.STATUS}-label`} 
                          style={{fontSize: "0.9rem"}}  
                          className="p-0"
                          >
                            Status:
                        </label>                                            
                      </Col>
 */}


                  <Modal.Footer>
                    
                    <Form.Group controlId="submit">
                    
                      <Button variant="primary" type="button" size="sm" onClick={handleClose}>
                        Close
                      </Button>
                      
                      <Button className="ml-1" id={fieldNames.SUBMIT} variant="primary" type="submit" size="sm">
                        Save Changes
                      </Button>
                    
                      <h3>
                        <div id={fieldNames.FORM_ERROR_DIV_ID}>
                        </div>
                      </h3>
                  
                    </Form.Group>

                  </Modal.Footer>

                  {/* <Form.Group controlId="submit">
                    <Button id={fieldNames.SUBMIT} variant="primary" type="submit" size="sm">
                      Save
                    </Button>
                  
                    <h3>
                      <div id={fieldNames.FORM_ERROR_DIV_ID}>
                      </div>
                    </h3>
                  
                  </Form.Group> */}

                </Form> 

          
              </Modal.Body>          
        </Modal>
      </>
    );
  }
  