import React from 'react'
import { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import {API_PROFILE_URL, API_URL, API_MEDIA_URL} from "../../../constants/apiUrl";
import UserBar from '../../user/UserBar'
import Friends from "../../friends/Friends"
import { Container, Col, Row, Card, Table, Nav, Button } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector, useUserProfileSelector } from "../../../state/typedSelectors";
import { useHistory } from "react-router-dom";
import { homeLink } from '../contentPageLinks'
import { LinkContainer } from "react-router-bootstrap";
import {Link} from "react-router-dom";
import { Statements } from '../../statements/Statements'
import { GlobalContext } from "../../../../web/state/GlobalState";
import { AddStatement } from '../../../components/statements/addStatement'
import { AREA_PUBLIC } from '../../../constants/GlobalConstants';
import DataService from '../../../../services/DataService';
import PageNotFound from '../../generic/PageNotFound';

const UserPageFull = ({ objectType, match, location }) => {

  let isGroups = false;
  
  const { params: { id } } = match;

  // objectType PROP and localObject Route PROP controls state of different users


  const { state: { localObject }} = location; 
  
  const [user, setUser] = useState({ user: [] });
  const [isFriend, setIsFriend] = useState(false);

  const { friends, followers, followees, users, blockers, groups, udhr_groups } = useContext(GlobalContext);

  // Debugging state
  // console.log ("UseePage, state, localObject, id", localObject, id);
  // console.log ("UserPage, objectType", objectType);
  // Select GlobalState to use for location of userdata to show
  // Objects (users, friends, followers) are set as links and buttons.
  
  let objects = null;

  console.log("objectType", objectType);
  console.log("localObject", localObject);
  
  const thisLocalObject = (objectType) ? objectType: localObject;
  
  switch (thisLocalObject) {
    case "friends": 
      objects = friends;
      break;
    case "followers":
      objects = followers;
      break;
    case "following":
        objects = followees;
        break;
    case "blocks":
      objects = blockers;
      break;
    case "users":
      objects = users;
      break;
    case "userSearch":
      objects = users;
      break;
    case "groups":
      isGroups = true;
      objects = groups;
      break;
    case "udhr_groups":
      isGroups = true;
      objects = udhr_groups;
      break;
    default:
      objects = friends;
    }




  const { authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );    
  
  const { ufirst_name, ulast_name } = useUserProfileSelector(
    state => state.userProfileReducer
  );

  let history = useHistory();
  
  const handleClose = (e) => {
    
    // history.push("/home/user/");

    history.goBack();
  }
  
  useEffect(() => {
    //  console.log(objects);
    
    function fetchData() {
      console.log("OBJECTS", objects);
      
      if (thisLocalObject === "friendRequests") {
        // console.log("Gets here!", id);
        DataService.get(id)
        .then(function (response) {
          // console.log(response);
          // console.log(JSON.stringify(response.data));
          setUser(response.data);
      })
      .catch(function (error) {
          // console.log(error);
      });    

      } else {     
          const [chosenFriend] = objects.filter(object => object.pk == match.params.id);
          console.log(objects);
          setUser(chosenFriend);}

          // Confirm the user is a friend
          const friendshipExist = friends.filter(friend => friend.pk == match.params.id);
          if (friendshipExist.length > 0) {
              setIsFriend(true);
          }
      }
  
  if (match.params.id !== 0)
    fetchData();

  }, [match.params.id]);
     

  const user_since_date = (user) ? new Date(user.created) : new Date();
  var user_born_date = (user) ? new Date(user.created): new Date();


  if ( !user ) return null; 
  
  
  const uGarAvatarName = ( 
    <thead>
        <th><Card.Text>GarAvatarId:</Card.Text></th>
        <th><Card.Text>{user.gar_avatar_name}</Card.Text></th>
    </thead>
    );

  const Name = (isGroups) ? user.name : ((user.full_name) ? (user.full_name) : (user.username))
  const Status = (isGroups) ? null : user.status;
  const Created = (user.created) ? user.created : null;
  const DateOfBirth = (isGroups) ? null : user.date_of_birth;
  const BornIn = (isGroups) ? null : user.born_in;
  const LivingIn = (isGroups) ? null : user.living_in;
  const City = user.city;
  const Bio = (isGroups) ? null : user.bio;
  const Education = (isGroups) ? null : user.education;
  const EducationPlace = (isGroups) ? null : user.education_place;
  const Occupation = (isGroups) ? null : user.occupation;
  const Workplace = (isGroups) ? null : user.workplace;
  const Position = (isGroups) ? null : user.position;
  const Description = (isGroups) ? user.description : null;
  const Country = (isGroups) ? user.country : null;
  const Established = (isGroups) ? user.established : null;
  const Code = (isGroups) ? user.code : null;
  const Username = (isGroups) ? user.username : user.username;

  console.log("USER: ", user);

  const uName = (!Name) ? null : 
    (
      <tr>
        <td><Card.Text>Name:</Card.Text></td>
        <td><Card.Text>{Name}</Card.Text></td>
      </tr>) 
  
  const uStatus = (Status) ? (
    <tr>
      <td><Card.Text>Status:</Card.Text></td>
      <td><Card.Text>{Status}</Card.Text></td>
    </tr>): null

  const uUsername = (Username) ? (
    <tr>
      <td><Card.Text>Username:</Card.Text></td>
      <td><Card.Text>{Username}</Card.Text></td>
    </tr>): null

  const uCreated = (Created) ? (
    <tr>
      <td><Card.Text>User since:</Card.Text></td>
      <td><Card.Text>{user_since_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null

  const uBorn = (DateOfBirth) ? 
    (<tr>
      <td><Card.Text>Born:</Card.Text></td>
      <td><Card.Text>{user_born_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null

  const uBornIn = (BornIn) ? 
  (<tr>
    <td><Card.Text>Born in:</Card.Text></td>
    <td><Card.Text>{BornIn}</Card.Text></td>
  </tr>): null
  
  const uLivingIn = (LivingIn) ? 
  (<tr>
    <td><Card.Text>Living in:</Card.Text></td>
    <td><Card.Text>{LivingIn}</Card.Text></td>
  </tr>): null

  const uCity = (City) ? 
  (<tr>
    <td><Card.Text>City:</Card.Text></td>
    <td><Card.Text>{City}</Card.Text></td>
  </tr>): null

  const uBio = (Bio) ? 
  (
    <div>
      <Card.Text>Bio:</Card.Text>
      <Card.Text>{Bio}</Card.Text>
    </div>
  ): null

  const uEducation = (Education) ? 
  (
    <div>
      <Card.Text>Education:</Card.Text>
      <Card.Text>{Education}</Card.Text>
    </div>
  ): null

  const uEducationPlace = (EducationPlace) ? 
  (
    <div>
      <Card.Text>Education place:</Card.Text>
      <Card.Text>{EducationPlace}</Card.Text>
    </div>
  ): null

  const uOccupation = (Occupation) ? 
  (
    <div>
      <Card.Text>Occupation:</Card.Text>
      <Card.Text>{Occupation}</Card.Text>
    </div>
  ): null

  const uWorkplace = (Workplace) ? 
  (
    <div>
      <Card.Text>Workplace:</Card.Text>
      <Card.Text>{Workplace}</Card.Text>
    </div>
  ): null

  const uPosition = (Position) ? 
  (
    <div>
      <Card.Text>Position:</Card.Text>
      <Card.Text>{Position}</Card.Text>
    </div>
  ): null


  const uDescription = (Description) ? 
  (<tr>
    <td><Card.Text>About:</Card.Text></td>
    <td><Card.Text>{Description}</Card.Text></td>
  </tr>): null

  const uCountry = (Country) ? 
  (<tr>
    <td><Card.Text>Country:</Card.Text></td>
    <td><Card.Text>{Country}</Card.Text></td>
  </tr>): null

  const uEstablished =  (Established) ?
  (
    <div>
      <Card.Text>Estabilshed:</Card.Text>
      <Card.Text>{Established}</Card.Text>
    </div>
  ) : null


  const uCode = (Code) ? 
  (<tr>
    <td><Card.Text>Code of conduct:</Card.Text></td>
    <td><Card.Text>{Code}</Card.Text></td>
  </tr>): null

  
  const closeButton = (
  
    <button 
    type="button" 
    className="text-right text-danger border-0 p-0 close" 
    aria-label="close"
    onClick={handleClose}
    style={{outline: "none"}}
    >
    <span aria-hidden="true">&times;</span>
  </button>
                     
      )
  
      let localImage = null;

      if (user.image) {
        localImage = user.image;
        if (localImage.search('http') === -1) {
          localImage = API_MEDIA_URL + user.image;
          // console.log("LocalTmage", localImage);
        } else {
          localImage = user.image;
        } 
      }


    return (


<React.Fragment>
        
        <Container className="mt-3"> 

         {(user.homepageViewAllowed === 2) ? (
           <h1 style={{color: "grey"}} className="text-center">This account is closed</h1>) : (

            <>
            <Row className="justify-content-center">
                <Col xs={12}>                   
                    <div id="mainDiv">

                    <Card shadow className="mb-4 p-3">
                    {closeButton}

                    <Row>
                      <Col>
                          <Card.Body>
                            <Table style={{fontSize: "0.9rem"}} size="sm">
                              {(isGroups) ? (

                                <tbody>
                                {uGarAvatarName}
                                {uName}
                                {uUsername}
                                {uCreated}
                                {uEstablished}
                                {uCity}
                                {uCountry}
                                {uDescription}
                                {uCode}
                              </tbody>


                              ) : (

                              <tbody>
                                {uGarAvatarName}
                                {uName}
                                {uStatus}
                                {uUsername}
                                {uCreated}
                                {uBorn}
                                {uBornIn}
                                {uLivingIn}
                                {uCity}
                              </tbody>

                              )}

                            </Table>
                           
                          </Card.Body>
                      </Col>
                      
                      <Col className="d-flex flex-column align-items-center">
                        <Card.Body>
                          <Card.Img className="mb-3" src={localImage}  alt={user.last_name} style={{width: '165px', height: '165px'}} />
                          <div className="mb-3">{uBio}</div>
                        </Card.Body>
                      </Col>
                      </Row>

                    </Card>
                  </div>
                </Col>    
            </Row>       
            
            <Row className="d-flex flex-row justify-content-center mt-5">
                        <Col xs={12} md={8} className="d-flex flex-column align-items-center">

                        {/* Allow adding statements if allow = 5/public or allow = 2 AND user is a friend that is not blocked. */}
                        {((user.homepageAddAllowed === 5) || ((user.homepageAddAllowed === 2) && (isFriend)) ) ?
                          <AddStatement udhr={null} objectState="user" user_id={id} /> : null
                        }
                          <Statements area={AREA_PUBLIC} user_id={id} objectState="user"/>
                        </Col>
            </Row>
            </>

           )} {/*Endof: homepageViewAllowed = true*/}

        </Container>

    </React.Fragment>



    );
  };

export default UserPageFull;