import React, { Fragment, useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../state/GlobalState";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { useUserAuthSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { InputGroup, Form, Modal, FormControl, Button } from "react-bootstrap";
import {API_URL, ROOT_URL} from '../../constants/apiUrl'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Share = (props, { match, location }) => {
  
  
  const statement = props.statement;
  console.log("share", props)  
  console.log("share", statement)  
  
  const [copied, setCopied] = useState(false);
  const [copyText, setCopyText] = useState('');  
  let history = useHistory();
  const shareInput = React.useRef();
  

  useEffect(() => {
    setCopyText(ROOT_URL+props.match.url);
}, []);
  
  
  const handleClose = e => {
    e.preventDefault();
    props.setShareActive(false);
    history.push(homeLink);
  }

if (!props.isShareActive) return <div />; 

const shareTag = props.currentStatement === String(statement.id) ? (

    <Fragment>
        <Form onReset={handleClose} className="mb-2">
                
                <InputGroup className="mt-2" style = {{width: '80%'}}>
                    <FormControl                                            
                        ref={shareInput} 
                        as="textarea" 
                        aria-label="With textarea" 
                        id={new Date().getTime().toString()+"-shareInput"}
                        defaultValue={ROOT_URL+props.match.url}
                        readOnly
                    />
                </InputGroup>
                
                
                <div style = {{width: '80%'}} className="text-right pt-2">                              
                    <CopyToClipboard 
                        text={copyText}
                        onCopy={() => setCopied(true)}>

                        <Button size="sm" type="submit" onClick={(e) => {e.preventDefault(); setCopied(true);}}>{(copied) ? ('Copied') : ('Copy to clipboard')}</Button>

                    </CopyToClipboard>
                    <Button size="sm" type="reset">Close</Button>
                </div>               
            
        </Form>
    </Fragment>
  ): <div />;

  return (
      <div>{shareTag}</div>
  );
}

export default Share;