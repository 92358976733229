import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useUserAuthSelector } from "../../state/typedSelectors";
import ListGroup from 'react-bootstrap/ListGroup';
import Friend from "./Friend";
import FriendsDataService from '../../../services/FriendsDataService'
import DataService from '../../../services/DataService'
import { GlobalContext } from "../../state/GlobalState";


const Friends = () => {
  
  // const [friends, setFriends] = useState([]);
  const { friends } = useContext(GlobalContext);

  const { authToken, userid } = useUserAuthSelector(
    state => state.userAuthReducer
  );    

  // useEffect(() => {
    
  //   if (authToken) {
    
  //     FriendsDataService.getAll()
  //     .then(response => {
         
  //       console.log("Friends", response.data);
  //       const newState = response.data; 
  //       setFriends(newState);

  //     })
  //     .catch(error => console.log(error)); 
  //   }
  
  // }, [setFriends, authToken])


  return (
    <div className="Friends">
      <ListGroup>
        <h4 style={{color: "grey", textAlign: "center"}} >Friends</h4>
        
        {(friends) ? 
            (friends.map((friend) => (
              <div key={friend.pk}>
                <Friend user={friend} userObject="friends" />
              </div>
            )
            )
             ) : null   
          }
      
      </ListGroup>
    </div>)
}

export default Friends;