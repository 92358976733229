import React, { useState } from 'react';
import { Container, Col, Row, Jumbotron, Button, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import * as linkItems from "../views/userAccess/userAccessLinks";


function Banner() {

  const [show, setShow] = useState(true);

  // CLOSE Button upper right on Jumbutron
  
  const handleClose = () => setShow(false);
    
  const closeButton = (    
  <button 
    type="button" 
    className="text-right text-danger p-0 border-0 close m-3" 
    aria-label="close"
    onClick={handleClose}
    style={{outline: "none"}}
  >
    <span aria-hidden="true">&times;</span>
  </button>                   
  )
  
    return(

      (setShow) ? (

  <Container className="mt-2">
        
    <Row className="justify-content-center">            

        <Col sm={12} className="justify-content-centre">

        {/* {closeButton} */}

          <Jumbotron className="text-center">


            <h5><p>These are the</p></h5>
            <h1>Universal Human Rights</h1>
              <h5><p><em>Read</em>, <em>share</em>, <em>discuss</em>, <em>engage</em> in and <em>use them!</em></p></h5>
              

            
            <div className="d-flex justify-content-center">
              <LinkContainer to={linkItems.login.link}>
                <Nav.Link eventKey={linkItems.login.link}>
                  <Button variant="primary">Log In</Button>
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to={linkItems.register.link}>
                <Nav.Link eventKey={linkItems.register.link}>
                  <Button>Sign up!</Button>
                </Nav.Link>
              </LinkContainer>
            </div>

          </Jumbotron>
        </Col>
     
    </Row>

    </Container>
      
      ): null

    ) 
  }

export default Banner;