import axios from "axios";
import * as types from "./userAuthTypes";
import {
  AUTH_TOKEN_KEY,
  USERNAME_KEY,
  USER_ID,
  LOGIN_TIME_KEY
} from "../../constants/localStorageKeys";
import { API_LOGOUT_URL } from "../../constants/apiUrl";

export const startUserSession = (
  authToken: string,
  username: string,
  userid: string,
  first_name: string,
  last_name: string,
  email: string,
  image: string,
  online: boolean,
  number_of_logins: string
): types.StartUserSessionAction => {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
  localStorage.setItem(USERNAME_KEY, username);
  localStorage.setItem(USER_ID, userid);
  localStorage.setItem(LOGIN_TIME_KEY, `${new Date()}`);
  return {
    type: types.START_USER_SESSION,
    payload: {
      username: username,
      userid: userid, 
      authToken: authToken,
      sessionActive: true,
      loginTime: `${new Date()}`,
      number_of_logins: number_of_logins,
      first_name: first_name,
      last_name: last_name,
      email: email,
      image: image,
      online: online
    }
  };
};

const endUserSession = (): types.EndUserSessionAction => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(USERNAME_KEY);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(LOGIN_TIME_KEY);
  return {
    type: types.END_USER_SESSION
  };
};

export const saveSettings = (
  settings: Partial<types.SettingsInterface>): 
  types.settingsActionTypes => {
  return {
      type: types.SAVE_SETTINGS,
      settings
  }
}

export const sendLogoutRequest = (authToken: string | null) => {
  return async (dispatch: Function) => {
    if (authToken !== null) {
      axios
        .post(
          API_LOGOUT_URL,
          {},
          {
            headers: { Authorization: `Token ${authToken}` }
          }
        )
        .catch(e => console.error(e));
    }
    dispatch(endUserSession());
  };
};

