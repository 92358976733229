import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

export default () => 

{
    return(
        <Container fluid>
            <Row className="d-flex justify-content-md-center">
                <Col xs={7}>
                    <Card bg="success" text="light" style={{ width: '100%'}}>
                        <Card.Title className="m-2">COMPANY Page</Card.Title>
                        <Card.Text className="m-2">More will follow</Card.Text>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}