import React from "react";
import User from "./User";
// import UserStick from "./UserStick";
import Stick from "../generic/stick/Stick";

function UserList({userList, stickType}) {
  console.log("Userlist", userList, stickType);
  return (
    <div>
        {
        userList.map(user => 
            <Stick stickObject={user} stickType={stickType} objectType="friends"/>
        )
        }
    </div>
  );
}

export default UserList;