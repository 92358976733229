import { LinkItem } from "../utils/navLinkInterface";


export const gar217aLink: LinkItem = {
    link: "/gar217a",
    displayName: "GAR217A",
  };

  
 export const productsLink: LinkItem = {
    link: "/products",
    displayName: "PRODUCTS",
  };

  export const companyLink: LinkItem = {
    link: "/company",
    displayName: "COMPANY"
  };