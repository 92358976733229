import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import {API_PROFILE_URL, API_URL, API_MEDIA_URL} from "../../../constants/apiUrl";
import UserBar from '../../user/UserBar'
import Friends from "../../friends/Friends"
import { Container, Col, Row, Card, Table, Nav, Button } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector, useUserProfileSelector } from "../../../state/typedSelectors";
import { useHistory } from "react-router-dom";
import { homeLink } from '../contentPageLinks'
import { LinkContainer } from "react-router-bootstrap";
import {Link} from "react-router-dom";
import { GlobalContext } from "../../../../web/state/GlobalState";

const UserPage = ( {objectType, match, location }) => {

  const { params: { id } } = match;
  
  const { state: { userObject }} = location;  
  
  const { authToken } = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { friends, followers, users, groups, udhr_groups } = useContext(GlobalContext);
  
  let objects = null;

  const localObject = (objectType) ? objectType: userObject;
  // Debugging state
  // console.log("location, state, userObject", location, userObject, location.state.userobject);

  // console.log ("objectType, userObject, localObject", objectType, userObject, localObject);

  // console.log(localObject);

  switch (localObject) {
    case "friends": 
      objects = friends;
      break;
    case "followers":
      objects = followers;
      break;
    case "users":
      objects = users;
      break;
    case "groups":
      objects = groups;
      break;
    case "udhr_groups":
        objects = udhr_groups;
        break;
    default:
      console.log("Why?", localObject)
      objects = friends;
  }
  
  const [user, setUser] = useState({ user: [] });

  let history = useHistory();

  const handleClose = (e) => {
    history.push("/home/user/");
  }

  useEffect(() => {
    console.log(objects);
    function fetchData() {
      console.log(objects);
      const [chosenFriend] = objects.filter(object => object.pk == match.params.id);
      console.log("chosenFriend", chosenFriend,  match.params.id );
      setUser(chosenFriend);
  }

  if (match.params.id !== 0)
    fetchData();

  }, [match.params.id]);


  const user_since_date = (user) ? new Date(user.created) : new Date();
  var user_born_date = new Date(user.date_of_birth);

  const uGarAvatarName = (
    <thead>
        <th><Card.Text>GarAvatarId:</Card.Text></th>
        <th><Card.Text>{user.gar_avatar_name}</Card.Text></th>
    </thead>
    );

  const uName = (user.first_name) ?
    (
      <tr>
        <td><Card.Text>Name:</Card.Text></td>
        <td><Card.Text>{user.first_name} {user.last_name}</Card.Text></td>
      </tr>) :

    (
      <tr>
        <td><Card.Text>Username:</Card.Text></td>
        <td><Card.Text>{user.username}</Card.Text></td>
      </tr>)

  const uStatus = (user.status) ? (
    <tr>
      <td><Card.Text>Status:</Card.Text></td>
      <td><Card.Text>{user.status}</Card.Text></td>
    </tr>): null

  const uUsername = (user.username) ? (
    <tr>
      <td><Card.Text>Username:</Card.Text></td>
      <td><Card.Text>{user.username}</Card.Text></td>
    </tr>): null

  const uCreated = (user.created) ? (
    <tr>
      <td><Card.Text>User since:</Card.Text></td>
      <td><Card.Text>{user_since_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null

  const uBorn = (user.date_of_birth) ?
    (<tr>
      <td><Card.Text>Born:</Card.Text></td>
      <td><Card.Text>{user_born_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null

  const uBornIn = (user.born_in) ?
  (<tr>
    <td><Card.Text>Born in:</Card.Text></td>
    <td><Card.Text>{user.born_in}</Card.Text></td>
  </tr>): null

  const uLivingIn = (user.living_in) ?
  (<tr>
    <td><Card.Text>Living in:</Card.Text></td>
    <td><Card.Text>{user.living_in}</Card.Text></td>
  </tr>): null

  const uCity = (user.city) ?
  (<tr>
    <td><Card.Text>City:</Card.Text></td>
    <td><Card.Text>{user.city}</Card.Text></td>
  </tr>): null

  const uBio = (user.bio) ?
  (
    <div>
      <Card.Text>Bio:</Card.Text>
      <Card.Text>{user.bio}</Card.Text>
    </div>
  ): null

  const closeButton = (

    <button
    type="button"
    className="text-right text-danger border-0 p-0 close"
    aria-label="close"
    onClick={handleClose}
    style={{outline: "none"}}
    >
    <span aria-hidden="true">&times;</span>
  </button>

      )
      
      // console.log(localObject);
      // console.log(userObject);

    return (

      <React.Fragment>

                <Card shadow className="mb-4 p-3">
                    {closeButton}

                    <Row>
                      <Col>
                          <Card.Body>
                            <Table style={{fontSize: "0.9rem"}} size="sm">
                              <tbody>
                                {uGarAvatarName}
                                {uName}
                                {uStatus}
                                {uUsername}
                                {uCreated}
                                {uBorn}
                                {uBornIn}
                                {uLivingIn}
                                {uCity}
                              </tbody>
                            </Table>

                          </Card.Body>
                      </Col>

                      <Col className="d-flex flex-column align-items-center">
                        <Card.Body>
                          <Card.Img className="mb-3" src={API_MEDIA_URL+user.image}  alt={user.last_name} style={{width: '165px', height: '165px'}} />
                          {uBio}
                        </Card.Body>
                      </Col>
                      </Row>

                      <Row className="w-100 d-flex justify-content-end">
                        <LinkContainer to={{ pathname: `/user/${user.pk}`, state: { localObject } }}> 
                          <Nav.Link>
                            <Button>
                              View full profile
                              </Button>
                            </Nav.Link>
                        </LinkContainer>
                      </Row>

                    </Card>

      </React.Fragment>

    );
  };

export default UserPage;