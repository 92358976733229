import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

export default () => 

{
    return(
        <Container>
            <Row className="d-flex justify-content-center">
                <Col xs={10}>
                    <Card className="w-100 p-4 text-left">                        
                        <Card.Title className="text-center pb-5">Terms of Use</Card.Title>

<p className="mt-5, mb-5">Effective date: May 8, 2021</p>

<p className="mb-5">
Hello everyone!
</p> 

<h5>
1. Introduction.
</h5>

<p>
GAR217A  ('us', 'we', or 'our') operates the GAR217A.com website (hereinafter referred to as the 'Service', 'GAR217A.com' or "Site").
</p>

<p>
GAR217A.com, is provided as a tool for people to inform themselves and others of the rights and duties within <i>The United Nations Declaration of Human Rights of 10. desember 1948 (UDHR)</i> and deriatives of UDHR.
</p>

<p>
Please read these terms of use carefully before using the Service, as they form a contract between you and GAR217A. 
</p>

<p>
THESE TERMS OF USE CONTAIN LIMITATIONS OF GAR217As LIABILITY IN SECTION 11.
</p>

<p>
GAR217A is free for all to use. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with our <a href="/helpcenter/datapolicy">Data Policy</a>. 
Unless otherwise defined in this Terms of Use, the terms used here have the same meanings as in the policy.
</p>

<h5>1. Accepting the Terms of Use</h5>

<p>
Read these Terms of Use, our <a href="/helpcenter/datapolicy">Data Policy</a> and our <a href="/helpcenter/communityguidelines">Community Guidelines</a> (collectively, the "Agreement") carefully before using GAR217A.com.
By using or accessing the Service, you ("you") agree to be bound by all the terms and conditions of this Agreement. 
If you don't agree to all the terms and conditions of this Agreement, you shouldn't, and aren't permitted to, use the Service. 
GAR217A's personalized Service cannot be provided and the terms of this Agreement cannot be performed without GAR217A  
processing information about you and other users. Processing of the information you share with GAR217A is essential to the 
personalized Service which we provide and which you expect, including personalized Content (as described below), 
and is a necessary part of our performance of the agreement we have with you.
</p>

<h5>2. Modifications to this Agreement</h5>

<p>
GAR217A reserves the right, and expect shortly to, modify this Agreement by
</p>
<p> 
(1) posting a revised Agreement on and/or through the Service and 
</p>
<p>
(2) providing notice to you that this Agreement has changed, generally via email where practicable, and otherwise through the Service (such as through a notification on your page).
</p>
<p>
Modifications will not apply retroactively. You are responsible for reviewing and becoming familiar with any modifications to this Agreement.
</p>

<p>
We may sometimes ask you to review and to explicitly agree to (or reject) a revised version of this Agreement. 
In such cases, modifications will be effective at the time of your agreement to the modified version of this Agreement. 
If you do not agree at that time, you are not permitted to use the Service.

In cases where we do not ask for your explicit agreement to a modified version of this Agreement, but otherwise provide notice as set forth above, the modified version of this Agreement will become effective fourteen days after we have posted the modified Agreement and provided you notification of the modifications. Your use of the Service following that period constitutes your acceptance of the terms and conditions of this Agreement as modified.
If you do not agree to the modifications, you are not permitted to use, and should discontinue your use of, the Service.

As GAR217A grows and improves, we might have to make changes to these Terms of Use. When we do, we'll let you know.
</p>

<h5>3. Use of the Service</h5>

<h6>Eligibility:</h6>

<p>
You may not use the Service, provide any personal information to GAR217A, or otherwise submit personal information through the Service (including, for example, a name, address, telephone number, or email address) if you are under the Minimum Age. 
The Minimum Age is (i) thirteen (13) (or the lower age that your country has provided for you to consent to the processing of your personal data) . 
You may only use the Service if you can form a binding contract with GAR217A and are not legally prohibited from using the Service. 
We will open (parts of) the service to all ages at a later stage.
</p>
<h6>Service Changes and Limitations:</h6>
<p>
The Service change frequently, and it's form and functionality may change without prior notice to you. GAR217A Company retains the right to create limits on and related to use of the Service in its sole discretion at any time with or without notice. GAR217A may also impose limits on certain Service or aspects of those Service or restrict your access to parts or all of the Service without notice or liability. GAR217A may change, suspend, or discontinue any or all of the Service at any time, including the availability of any product, feature, database, or Content (as defined below). GAR217A may also terminate or suspend Accounts (as defined below) at any time, in its sole discretion.
</p>
<p>
GAR217A.com is an ever-evolving platform. With new products, services, and features launching all the time, we need flexibility to make changes, impose limits, and occasionally suspend or terminate certain offerings (like features that flop). We can also terminate or suspend any account at any time. That sounds harsh, but we only use that power when we have a reason, as outlined in these Terms of Use, our <a href="/helpcenter/datapolicy">Data Policy</a> , and our <a href="/helpcenter/communityguidelines">Community Guidelines</a>.
</p>
<h6>Limitations on Automated Use:</h6>

<p>You may not, without express prior written permission, do any of the following while accessing or using the Service:</p>
<p>
(a) tamper with, or use non-public areas of the Service, or the computer or delivery systems of GAR217A and/or its service providers; 
</p>
<p>
(b) probe, scan, or test any system or network (particularly for vulnerabilities), or otherwise attempt to breach or circumvent any security or authentication measures;
</p>
<p>
(c) access or search or attempt to access or search the Service by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by GAR217A (and only pursuant to those terms and conditions) or unless permitted by GAR217A's robots.txt file or other robot exclusion mechanisms; 
</p>
<p>
(d) scrape the Service, and particularly scrape Content (as defined below) from the Service;
</p>
<p>
(e) use the Service to send altered, deceptive, or false source-identifying information, including without limitation by forging TCP-IP packet headers or email headers; or
</p>
<p>
(f) interfere with, or disrupt, (or attempt to do so), the access of any User, host or network, including, without limitation, by sending a virus to, spamming, or overloading the Service, or by scripted use of the Service in such a manner as to interfere with or create an undue burden on the Service.
</p>
<p>
Don't do bad things to GAR217A or to other users. Some particularly egregious examples of "bad things" are listed in this section.
</p>

<h5>4. Registration, GAR217A URLs, and Security</h5>
<p>
As a condition to using certain of the Service and in order for us to provide them to you, you may be required to create an account (an "Account"), by when your become a User, provide your age and an email address and select a password , which may serve as a default to your GAR217A page.

You agree to provide GAR217A with accurate, complete, and updated registration information, particularly your email address.
<p>
It's really important that the email address associated with your GAR217A account is accurate and up-to-date. 
If you ever forget your password - or worse, fall victim to a malicious phishing attack - 
a working email address is often the only way for us to recover your account.
</p>
<p>
You are also responsible for maintaining the confidentiality of your Account password and for the security of your Account, 
and you will notify GAR217A immediately of any actual or suspected loss, theft, or unauthorized use of your Account or Account password.
</p>
</p>

<h5>5. Privacy</h5>
<p>

For information about how GAR217A collects, uses, and shares your information, please review our <a href="/helpcenter/datapolicy">Data Policy</a>. By using the Service you agree you have read the <a href="/helpcenter/datapolicy">Data Policy</a>, which describes our collection, use, and sharing (as set forth in the Data Policy) of such information, including the transfer of this information to and from countries for storage, processing, and use by GAR217A and the GAR217A Affiliates (as defined below).
</p>

<h5>6. Content and User Content</h5>
<h6>
Definitions:
</h6>
<p>
For purposes of this Agreement: 
</p>
<p>
(1) the term "Content" means a creative expression and includes, without limitation, video, audio, photographs, images, illustrations, animations, logos, tools, written posts, replies, comments, information, data, text, graphics, any of which may be generated, provided, or otherwise made accessible on or through the Service; 
</p>
<p>
(2) the term "User Content" means Content that a User submits, transfers, or otherwise provides to the Service. Content includes, without limitation, all User Content.
</p>
<h6>
Your Rights in User Content:
</h6>
<p>
Users retain ownership and/or other applicable rights in User Content, and GAR217A and/or third parties retain ownership and/or other applicable rights in all Content other than User Content.
</p>
<p>
You retain ownership you have of any intellectual property you post to GAR217A.
</p>
<h6>
User Content License to GAR217A:
</h6>
<p>
When you provide User Content to GAR217A through the Service, you grant GAR217A a non-exclusive, worldwide, royalty-free, sublicensable, transferable right and license to use, host, store, cache, reproduce, publish, display (publicly or otherwise), perform (publicly or otherwise), distribute, transmit, modify, adapt (including, without limitation, in order to conform it to the requirements of any networks, devices, Service, or media through which the Service are available), and create derivative works of, such User Content. The rights you grant in this license are for the limited purposes of allowing GAR217A to operate the Service in accordance with their functionality, improve and promote the Service, and develop new Service. The reference in this license to "creat[ing] derivative works" is not intended to give GAR217A a right to make substantive editorial changes or derivations, but does, for example, enable reblogging, which allows GAR217A Users to redistribute User Content from one GAR217A Account to another in a manner that allows them to add their own text or other Content before or after your User Content.

When you upload your creations to GAR217A, you’re giving us permission to make them available in all the ways you would expect us to (for example, via your pages, RSS etc.). We never want to do anything with your work that surprises you.
</p>
<p>
Something else worth noting: Internet content, blogs etc. have gone on to spawn books, films, albums, brands, and more. Any royalties or reimbursement you get for your creations are, needless to say, entirely yours. It's your work, and we're proud to be a part (however small) of what you accomplish.
</p>
<p>
You also agree that this license includes the right for GAR217A to make all publicly-posted Content available to third parties selected by GAR217A, so that those third parties can syndicate and/or analyze such Content on other media and Service.
</p>
<p>
An example of what it means to "make all publicly-posted Content available" to a GAR217A partner for distribution or analysis would be licensing your GAR217A "feed," a live feed of all public activity on GAR217A, to partners like search engines.
</p>
<p>
Note also that this license to your User Content continues even if you stop using the Service, primarily because of the social nature of Content shared through GAR217A’s Service - when you post something publicly, others may choose to comment on it, making your Content part of a social conversation that can’t later be erased without retroactively censoring the speech of others.
</p>
<p>
One thing you should consider before posting: When you make something publicly available on the Internet, it becomes practically impossible to take down all copies of it.
</p>
<p>
You also agree that you will respect the intellectual property rights of others, and represent and warrant that you have all of the necessary rights to grant us this license for all User Content you transfer to us.
</p>
<h5>
6. Compliance with Community Guidelines:
</h5>
<p>
You agree that you won't violate GAR217A's <a href="/helpcenter/communityguidelines">Community Guidelines</a>.
</p>

<h5>7. Termination and Deletion:</h5>

<p>
On termination of your Account, or upon your deletion of particular pieces of User Content from the Service, GAR217A shall make reasonable efforts to make such User Content inaccessible and cease use of it and, if required by the applicable Privacy Policy, delete your Account data and / or User Content unless permitted or required to keep this data in accordance with law; however, you acknowledge and agree that: (a) deleted User Content may persist in caches or backups for a reasonable period of time and (b) copies of or references to the User Content may not be entirely removed (due to the nature of reblogging, for example). You can delete your account at any time here.
</p>

<h5>8. Use of Trademarks</h5>
<p>
Any use of GAR217A's trademarks, branding, logos, and other such assets in connection with the Service should be approved by GAR217A.
</p>

<h5>9. Warranty Disclaimer; Service Available on an "AS IS" Basis</h5>

<p>
Your access to and use of the Service or any Content is at your own risk. YOU UNDERSTAND AND AGREE THAT THE Service ARE PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, GAR217A DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. 
</p>
<p>
GAR217A makes no representations or warranties of any kind with respect to the Service, including any representation or warranty that the use of the Service will 
(a) be timely, uninterrupted or error-free or operate in combination with any other hardware, software, system, or data, 
(b) meet your requirements or expectations, 
(c) be free from errors or that defects will be corrected, 
(d) be free of viruses or other harmful components; or 
(e) be entirely secure or that the information you share with us will be secure. GAR217A also makes no representations or warranties of any kind with respect to Content; User Content, in particular, is provided by and is solely the responsibility of, the Users providing that Content. No advice or information, whether oral or written, obtained from GAR217A or through the Service, will create any warranty not expressly made herein.
</p>

<h5>10. Other Disclaimers</h5>

<p>
Materials provided on this Site are provided “as is”, without warranty of any kind, either express or implied, including, without limitation, warranties of merchantability, fitness for a particular purpose and non-infringement. GAR217A specifically does not make any warranties or representations as to the accuracy or completeness of any such Materials. The use of this Site is at the User’s sole risk.
</p>
<p>
You specifically acknowledges and agrees that the GAR217A is not liable for any conduct of any user of the Service.
</p>
<p>
This site may contain advice, opinions and statements of various information providers. GAR217A does not represent or endorse the accuracy or reliability of any advice, opinion, statement or other information provided by any information provider, any user of this Site or any other person or entity. Reliance upon any such advice, opinion, statement, or other information shall also be at the your own risk. Neither GAR217A  nor its affiliates, nor any of their respective agents, employees, information providers or content providers, shall be liable to you or anyone else for any inaccuracy, error, omission, interruption, deletion, defect, alteration of or use of any content herein, or for its timeliness or completeness.
</p>
<p>
As a condition of use of this Site, you agree to indemnify the GAR217A and its affiliates from and against any and all actions, claims, losses, damages, liabilities and expenses (including reasonable attorneys’ fees) arising out of your use of this Site, including, without limitation, any claims alleging facts that if true would constitute a breach by you of these Terms . If you are dissatisfied with any Material on this Site or with any of its Terms, your sole and exclusive remedy is to discontinue using the Site.
</p>
<p>
You agree to use the Service only to send and receive messages and materials that are proper. By way of example and not as a limitation, you agree that when using the site, you shall not do any of the following:
</p>

<p>
(a) Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others;
</p>
<p>
(b) Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information;
</p>
<p>
(c) Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless you owns or controls the rights thereto or has received all consents therefor as may be required by law;
</p>
<p>

(d) Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded;
</p>
<p>
(e) Falsify the origin or source of software or other material contained in a file that is uploaded;
</p>
<p>
(f) Advertise or offer to sell any goods or Service, or conduct or forward surveys, contests or chain letters, or download any file posted by another user that the User knows, or reasonably should know, cannot be legally distributed in such manner.
</p>

<h5>11. Time Limitation on Claims and Releases From Liability</h5>

<p>
You agree that any claim you may have arising out of or related to this Agreement or your relationship with GAR217A must be filed within two months after such claim arose; otherwise, your claim is permanently barred.
</p>
<p>
You further release, to the fullest extent permitted by law, GAR217A and its employees, agents, consultants, directors, shareholders, any other person or entity that directly or indirectly controls, is under common control with, or is directly or indirectly controlled by, GAR217A (the “GAR217A Affiliates”) from responsibility, liability, claims, demands, and/or damages (actual and consequential) of every kind and nature, known and unknown (including but not limited to claims of negligence), arising out of or related to the following:
</p>
<ol>
<li>
Disputes between Users, including those between you and other Users.
</li>
<li>
The acts of third parties generally (i.e., individuals or entities who are not GAR217A or a GAR217A Affiliate), including third party sites and Service.
</li>
<li>
Disputes concerning any use of or action taken using your Account by you or a third party.
</li>
<li>
Claims relating to the unauthorized access to any data communications relating to, or Content stored under or relating to, your Account, including but not limited to unauthorized interception, use, or alteration of such communications or your Content. For the sake of clarity, this includes any and all claims related to the security of your Account credentials.
</li>
</ol>

<h5>12. Limitation of Liability</h5>
<p>
GAR217A is for scocial-, educational- and information sharing purposes only. Nothing of the content, is or should be regarded as legal advise in a given case.
If your need legal help, please seek professional advice.</p>
<p>
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, GAR217A AND THE GAR217A AFFILIATES SHALL NOT BE LIABLE FOR: (A) ANY INDIRECT, INCIDENTAL, EXEMPLARY PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; (B) LOSS OF: PROFITS, REVENUE, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES; (C) DAMAGES RELATING TO YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS OR USE THE Service; (D) DAMAGES RELATING TO ANY CONDUCT OR CONTENT OF ANY THIRD PARTY OR User USING THE Service, INCLUDING WITHOUT LIMITATION, DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OR CONTENT; AND/OR (E) DAMAGES IN ANY MANNER RELATING TO ANY CONTENT. THIS LIMITATION APPLIES TO ALL CLAIMS, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, WHETHER OR NOT GAR217A HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND FURTHER WHERE A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.

TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL LIABILITY OF GAR217A AND THE GAR217A AFFILIATES, FOR ANY CLAIM UNDER THIS AGREEMENT, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE GREATER OF ONE HUNDRED DOLLARS (US $100.00) OR THE AMOUNT YOU PAID US TO USE THE APPLICABLE SERVICE(S).
</p>
<h5>13. Exclusions to Warranties and Limitation of Liability</h5>
<p>
Some jurisdictions may not allow the exclusion of certain warranties or the exclusion/limitation of liability as set forth in Section 14, so the limitations above may not apply to you, but will reamin in force to their fullest extent.
</p>

<h5>14. Termination</h5>
<p>
Either party may terminate this Agreement at any time by notifying the other party. GAR217A may terminate or suspend your access to or ability to use any and all Service immediately, without prior notice or liability, for any reason or no reason, including but not limited to if you breach any of the terms or conditions of this Agreement. In particular, GAR217A may immediately terminate or suspend Accounts that have been flagged for repeat copyright infringement.

Upon termination of your access to or ability to use a Service, including but not limited to suspension of your Account on a Service, your right to use or access that Service and any Content will immediately cease. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, and limitations of liability. Termination of your access to and use of the Service shall not relieve you of any obligations arising or accruing prior to such termination or limit any liability that you otherwise may have to GAR217A or any third party and shall not relieve you of your rights if you are a user in the European Economic Area which are described in the <a href="/helpcenter/datapolicy">Data Policy</a>.
</p>
<h5>15. Choice of Law and Venue</h5>
<p>
You and GAR217A agree that we will resolve any claim or controversy at law or equity that arises out of this Agreement or the Service in accordance with this Section or as you and GAR217A otherwise agree in writing. Before resorting to formal dispute resolution, we strongly encourage you to contact us to seek a resolution.

Law and Forum for Legal Disputes:

This Agreement shall be governed in all respects by the laws of Norway as they apply to agreements entered into and to be performed entirely within Norway between Norwegian residents, without regard to conflict of law provisions. You agree that any claim or dispute you may have against GAR217A must be resolved exclusively by a state or federal court located in Follo tingrett, Ski, except as otherwise agreed by the parties. You agree to submit to the personal jurisdiction of the courts located within Nordre Follo, Viken for the purpose of litigating all such claims or disputes.
</p>

<h5>16. Miscellaneous</h5>

<p>

This Agreement, as modified from time to time, constitutes the entire agreement between you and GAR217A with respect to the subject matter hereof. This Agreement replaces all prior or contemporaneous understandings or agreements, written or oral, regarding the subject matter hereof and constitutes the entire and exclusive agreement between the parties. The failure of either party to exercise, in any way, any right provided for herein shall not be deemed a waiver of any further rights hereunder. If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain enforceable and in full force and effect. This Agreement is not assignable, transferable, or sublicensable by you except with GAR217A's prior written consent. GAR217A may assign this Agreement in whole or in part at any time without your consent. No agency, partnership, joint controllership, joint venture, or employment is created as a result of this Agreement and you do not have any authority of any kind to bind GAR217A in any respect whatsoever. Any notice to GAR217A that is required or permitted by this Agreement shall be in writing and shall be deemed effective upon receipt, when delivered in person by nationally recognized overnight courier or mailed by first class, registered or certified mail, postage prepaid, to
</p>
<address>
GAR217A,
Smalsundet 24,
1452 Nesodden,
Norway
</address>


<h5>17. Copyright Policy</h5>

<h6>
Reporting Instances of Copyright Infringement:
</h6>

<p>
If you believe that Content residing or accessible on or through the Service infringes a copyright, please send a notice of copyright infringement containing the following information to the Designated Agent at the address below:
</p>

<h6>
Identification of the work or material being infringed.
</h6>

<p>
Identification of the material that is claimed to be infringing, including its location, with sufficient detail so that GAR217A is capable of finding it and verifying its existence.
</p>
<p>
Contact information for the notifying party (the "Notifying Party"), including name, address, telephone number, and email address.
</p>
<p>
A statement that the Notifying Party has a good faith belief that the material is not authorized by the copyright owner, its agent or law.
</p>
<p>
A statement made under penalty of perjury that the information provided in the notice is accurate and that the Notifying Party is authorized to make the complaint on behalf of the copyright owner.
</p>
<p>
A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed.
</p>
<p>
Please also note that the information provided in a notice of copyright infringement may be forwarded to the User who posted the allegedly infringing content. After removing material according to law, GAR217A will immediately notify the User responsible for the allegedly infringing material that it has removed or disabled access to the material. GAR217A will terminate, under appropriate circumstances, the Accounts of Users who are repeat copyright infringers, and reserves the right, in its sole discretion, to terminate any User for actual or apparent copyright infringement.
</p>
<h6>
Submitting a Counter-Notification:
</h6>
<p>
If you believe you are the wrongful subject of a Coupyright Infringement notification, you may file a counter-notification with GAR217A by providing the following information to the Designated Agent at the address below:
</p>
<ul>
<li>
The specific URLs of material that GAR217A has removed or to which GAR217A has disabled access.
</li>
<li>
Your name, address, telephone number, and email address.
</li>
</ul>

<p>
Upon receipt of a valid counter-notification, GAR217A will forward it to Notifying Party who submitted the original notification. The original Notifying Party (or the copyright holder he or she represents) will then have ten (10) days to notify us that he or she has filed legal action relating to the allegedly infringing material. If GAR217A does not receive any such notification within ten (10) days, we may restore the material to the Service.
</p>
<h6>
Designated Agent:
</h6>
<address>
GAR217A
Smalsundet 24
1452 Nesoddtangen
Norway
Att.: Legal department
</address>
<h5>18. Open Source Disclosures</h5>
<p>
You can find disclosures related to our use of open source software packages at the following location: </p>

<a href="/helpcenter/credits">https://www.GAR217A.com/helpcenter/credits</a>


                   </Card>
                </Col>
            </Row>
        </Container>
    );
}