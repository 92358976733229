// For checking if a string is empty, null or undefined I use:

function isEmpty(str) {
    return (!str || str.length === 0 );
}

// For checking if a string is blank, null or undefined I use:

function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

// For checking if a string is blank or contains only white-space:

String.prototype.isEmpty = function() {
    return (this.length === 0 || !this.trim());
};