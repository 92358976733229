import * as userTypes from "./userAuthTypes";
import {
  AUTH_TOKEN_KEY,
  USERNAME_KEY,
  USER_ID,
  LOGIN_TIME_KEY
} from "../../constants/localStorageKeys";
import { createNoSubstitutionTemplateLiteral } from "typescript";

export const initialState: userTypes.UserState = {
  username: localStorage.getItem(USERNAME_KEY),
  userid: localStorage.getItem(USER_ID),
  sessionActive:
    localStorage.getItem(USERNAME_KEY) !== null &&
    localStorage.getItem(AUTH_TOKEN_KEY) !== null,
  authToken: localStorage.getItem(AUTH_TOKEN_KEY),
  loginTime: localStorage.getItem(LOGIN_TIME_KEY),
  first_name: null,
  last_name: null,
  email: null,
  image: "",
  online: false,
  number_of_logins: null,
};

const loggedOutState: userTypes.UserState = {
  username: null,
  userid: null,
  sessionActive: false,
  authToken: null,
  loginTime: null,
  first_name: null,
  last_name: null,
  email: null,
  image: "",
  online: false,
  number_of_logins: null
};

export const settingsInitialState: userTypes.SettingsInterface = {
  image: "",
  online: false
};


const settings = (state = settingsInitialState, action: userTypes.settingsActionTypes): userTypes.SettingsInterface => {
  switch (action.type) {
      case userTypes.SAVE_SETTINGS:
          return { ...state, ...action.settings }
      default:
          return state
     }
  }

export default (
  previousState: userTypes.UserState = initialState,
  action: userTypes.StartUserSessionAction | userTypes.EndUserSessionAction
): userTypes.UserState => {
  switch (action.type) {
    case userTypes.START_USER_SESSION:
      {
        console.log("SUS previousState", previousState); 
        console.log("SUS action.payload", action.payload); 
        return action.payload;}
    case userTypes.END_USER_SESSION:
      {
          console.log("SUS previousState", previousState); 
          return loggedOutState;
      }
  }
  return previousState;
};


