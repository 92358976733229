import React from "react";
import * as Io from "react-icons/io";

import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { LinkItem } from "../utils/navLinkInterface";

export const groupProfileLink: LinkItem = {
  link: "/group/profile",
  displayName: "Profile",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };  

export const groupSettingsLink: LinkItem = {
link: "/group/profile/settings",
displayName: "Account",
icon: <IconWrapper Icon={Io.IoIosHome} />
};

export const groupMembersLink: LinkItem = {
  link: "/group/profile/members",
  displayName: "Members",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };

export const groupRequestMembershipLink: LinkItem = {
  link: "/group/profile/requests",
  displayName: "Membership requests",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };

export const groupFollowersLink: LinkItem = {
  link: "/group/profile/followers",
  displayName: "Followers",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };

export const groupFollowsLink: LinkItem = {
  link: "/group/profile/follows",
  displayName: "Following",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };

export const groupBlockLink: LinkItem = {
  link: "/group/profile/blocks",
  displayName: "Blocks",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };

export const groupProjectsLink: LinkItem = {
  link: "/group/profile/projects",
  displayName: "Projects",
  icon: <IconWrapper Icon={Io.IoIosHome} />
  };