import React from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { userProfileLink, followersLink, followsLink, blockLink, projectsLink } from "../user/UserProfileLinks";
import {  groupProfileLink, 
          groupSettingsLink, 
          groupMembersLink, 
          groupRequestMembershipLink,
          groupFollowersLink, 
          groupFollowsLink, groupBlockLink, groupProjectsLink } from './groupProfileLinks';

const StyledLink = styled(Nav.Link)`
color: grey;
font-size: 1.0rem;
margin-top: 2px;
${props => props.active && `
    //color: blue;
    //border-bottom: 3px solid green;
  `}
`;

export default () => {
  
  return (

<>


         <Nav defaultActiveKey="/group/profile">
             <Navbar
               bg="white"
               id="connection-menu-main-style"
               className="d-flex flex-column p-0 align-items-start"
              collapseOnSelect
             >

              <LinkContainer to={groupProfileLink.link}>
                <StyledLink eventKey={groupProfileLink.link}>
                  {groupProfileLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={groupSettingsLink.link}>
                <StyledLink eventKey={groupSettingsLink.link}>
                  {groupSettingsLink.displayName}
                </StyledLink>
              </LinkContainer>


              <LinkContainer to={groupMembersLink.link}>
                <StyledLink eventKey={groupMembersLink.link}>
                  {groupMembersLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={groupRequestMembershipLink.link}>
                <StyledLink eventKey={groupRequestMembershipLink.link}>
                  {groupRequestMembershipLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={groupFollowersLink.link}>
                <StyledLink eventKey={groupFollowersLink.link}>
                  {groupFollowersLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={groupFollowsLink.link}>
                <StyledLink eventKey={groupFollowsLink.link}>
                  {groupFollowsLink.displayName}
                </StyledLink>
              </LinkContainer>

              <LinkContainer to={groupBlockLink.link}>
                <StyledLink eventKey={groupBlockLink.link}>
                  {groupBlockLink.displayName}
                </StyledLink>
              </LinkContainer>
{/* 
              <LinkContainer to={groupProjectsLink.link}>
                <StyledLink eventKey={groupProjectsLink.link}>
                  {groupProjectsLink.displayName}
                </StyledLink>
            </LinkContainer> */}
  
             </Navbar>
            
           </Nav>

           
    </>


 );
};


