import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import {UDHR_ARTICLES} from '../../constants/UdhrArticles.js'

function MobileUdhrBar() {

    return(


    <div className="d-md-none d-flex flex-row flex-wrap justify-content-center">
    <DropdownButton className="" variant="secondary" id="dropdown-basic-button" title="Choose a Human Right here!">
        {UDHR_ARTICLES.map(article => (  
        <LinkContainer key={article.id} to={`/home/udhr/${article.id}`}>
            <Dropdown.Item style={{fontSize: "0.3rem"}} key={article.id.toString()} value={article.id}>
            {/* <Dropdown.Item key={article.id.toString()} value={article.id} href={`/home/udhr/${article.id}`}> */}
            {}
            {article.id + ' ' + article.bttnText}
            </Dropdown.Item>
        </LinkContainer>   
        ))}
    </DropdownButton>
    </div>        

    );
}
    
export default MobileUdhrBar;