import React from "react";
import { Route, Switch } from "react-router-dom";
// import UserPage from "../user/UserPage";
import UserPage from "../views/user/UserPage";
import UserStick from "../user/UserStick";
import Udhr from "../udhr/Udhr";
import Statement from "../statements/Statement";
import Reply from "../statements/Reply";
import Velcome from "../velcome/Velcome";
import UserProfileForm from "../views/user/UserProfileForm";
import { DontShowWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers';
import { profileLink } from "../views/contentPageLinks";

export default (props) => {

  const location = props.location;

  return (
    <React.Fragment>
        <Switch location={location}>                  
          <Route exact path="/home/">  
          </Route>         
          <Route exact path="/home/user/:id" component={UserPage} />         
          <Route exact path="/home/udhr/:id" component={Udhr} />
          <Route exact path="/home/statement/:id">
          </Route>         
        </Switch>
    </React.Fragment>
  );
};