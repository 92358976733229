import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Button, Container, Col, Row, Card, Form, ButtonGroup, ToggleButton, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useUserAuthSelector, useUserProfileSelector, useUserSettingsSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import { updateGroupProfile } from "../../state/groupProfileState/groupProfileActions";
import { Link } from "react-router-dom";


import { useHistory } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { GlobalContext } from "../../state/GlobalState";
import UserCloseAccount from '../user/UserCloseAccount';
import CreateGroup from '../groups/CreateGroup';
import DataService from '../../../services/DataService';
import GroupsDataService from '../../../services/GroupsDataService';
import { AREA_FRIENDS, AREA_PUBLIC } from '../../constants/GlobalConstants';

const UserProfileSettings = () => {

  const { username, first_name, last_name, email, userid, authToken} = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { pk, id, user, owner, admin, name, description, code, image, homepageViewAllowed, homepageAddAllowed } = useGroupProfileSelector(
    state => state.groupProfileReducer
  );    


  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const radios = [
    { name: "Members", value: AREA_FRIENDS },
    { name: "Public", value: AREA_PUBLIC },
  ];
  
  const userObject = "friends";

  let history = useHistory();

  const dispatch = useDispatch();


  useEffect(() => {
    // console.log("homepageViewAllowed", homepageViewAllowed)
    // setRadioValue(homepageViewAllowed)
  }, [])
  
  const handleClose = (e) => {
    history.push("/home/user/");
  }

  const handlePageViewRadioChange = (e) => {
    
    console.log("HANDLE_PAGE_VIEW", e.currentTarget.value);
    console.log("HANDLE_PAGE_VIEW", e.currentTarget.value);

    let data = {
      homepageViewAllowed: e.currentTarget.value,
      last_saved: String(new Date(Date.now()))
    }

    console.log("data", data);

    const response = GroupsDataService.update(id, data)
    .then((response) => {
            console.log("setProfile", response.data);
            // setRadioValue(e.currentTarget.value);

            dispatch(
              updateGroupProfile(
                pk, 
                id,
                user,
                owner,
                admin,
                name,
                description, 
                code,
                image,
                response.data.homepageViewAllowed, 
                response.data.homepageAddAllowed
              )  
            );


          })
          .catch(e => {
          console.log(e.response);
          });  


  }

  const handlePageAddRadioChange = (e) => {
    console.log("handlePageAddRadioChange");

    let data = {
      homepageAddAllowed: e.currentTarget.value,
      last_saved: String(new Date(Date.now()))
    }

    const response = GroupsDataService.update(id, data)
    .then((response) => {
            console.log("setProfile", response.data);
            // setRadioValue(e.currentTarget.value);

 
            dispatch(
              updateGroupProfile(
                pk, 
                id,
                user,
                owner,
                admin,
                name,
                description, 
                code,
                image,
                response.data.homepageViewAllowed, 
                response.data.homepageAddAllowed
              )  
            );
            

  })
  .catch(e => {
  console.log(e.response);
  });  

  }

  


    return (

      <React.Fragment>             


              <Container className="d-flex flex-row justify-content-center w-100">
                      
                    <Card className="p-2 w-100 border-0">

                      <Card.Title style={{color: "grey"}}>Account: </Card.Title>                          
                        
                        <Card.Body className="d-flex flex-column w-100">

                        <Row>

                          <Col md={6}>

                            <Row className="w-100 d-flex justify-content-center">
                                <Col className="d-flex flex-column align-items-start">

                                
                                <Form>
                                  <Form.Group>

                                  <Card.Text as="h6">People who can view content on our homepage:</Card.Text>


                                <ButtonGroup toggle>
                                  {radios.map((radio, index) => (
                                    <ToggleButton
                                      key={index}
                                      type="radio"
                                      name="radio"
                                      value={radio.value}
                                      checked={homepageViewAllowed === radio.value}
                                      onChange={e => handlePageViewRadioChange(e)}
                                    >
                                      {radio.name}
                                    </ToggleButton>
                                  ))}
                                </ButtonGroup>

                              </Form.Group>

                                </Form>

                          
                                  </Col>

                            </Row>

                            <Row className="mt-2">
                              <Col md={6}>
                                <Card.Text as="h6">People who can post on our homepage:</Card.Text>

                                <ButtonGroup toggle>
                                  {radios.map((radio, index) => (
                                    <ToggleButton
                                      key={index}
                                      type="radio"
                                      name="radio"
                                      value={radio.value}
                                      checked={homepageAddAllowed === radio.value}
                                      onChange={e => handlePageAddRadioChange(e)}
                                    >
                                      {radio.name}
                                    </ToggleButton>
                                  ))}
                                </ButtonGroup>

                              </Col>
                            </Row>        

                          </Col>
                          
                          
                          <Col md={6} className="d-flex flex-column mt-3">

                            <Row style={{backgroundColor: "lightgrey"}} className="w-100 p-1 mb-2">
                            <Card.Title style={{color: "white"}}>
                              Info:
                            </Card.Title>
                            </Row>
                            <Row>
                            <Card.Text>
                              Homepage is the page you see when Viewing another user or group.
                              {/* Preview your own    */}
                              {/* <div>       */}
                                {/* <Link className="m-0" to={{ pathname: `/home/user/${user.pk}`, state: { userObject } }}>  */}
                                {/* </Link> */}
                              {/* </div> */}
                            </Card.Text>
                            <Card.Text>
                              Member is any user who is a member of your group.
                            </Card.Text>
                            <Card.Text>
                              Public is everybody - including your groups followers and members.
                            </Card.Text>
                            </Row>
                            
      


                          </Col>

                          </Row>


                          <Row className="w-100 mt-5 align-self-center">
                                <div>                                   
                                  <UserCloseAccount />
                                </div>
                            </Row>
  
                        </Card.Body>
                          

                    </Card>     

                  </Container>
                      
      </React.Fragment>

    );
  };

export default UserProfileSettings;