
import {UDHR_ARTICLES} from '../../constants/UdhrArticles.js'
import React, {useState, useEffect, useContext } from "react";
// import {Link} from "react-router-dom";
import { Tab, Col, Row, Button, Card, Nav, Modal } from "react-bootstrap";
import ReplyCard from '../statements/ReplyCard';
import { useUserAuthSelector } from "../../state/typedSelectors";
import { useHistory } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { AddStatement } from '../statements/addStatement';
import { UDHRStatements } from '../statements/UDHRStatements';
import { Statements } from '../statements/Statements';
import ShareUDHR from './ShareUDHR'
import LoginModal from '../views/userAccess/login/LoginModal'
import { AREA_UDHR, AREA_PUBLIC, AREA_PRIVATE } from '../../constants/GlobalConstants'
import { CATEGORY_ARTICLE, CATEGORY_PLAIN } from '../../constants/Categories'
import StickList from '../generic/stick/StickList'
import { STICKTYPE_USER_SEARCH, STICKTYPE_ENGAGE } from '../../constants/stickTypes'
import { GlobalContext } from "../../state/GlobalState";
import GroupsDataService from '../../../services/GroupsDataService'
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import {  groupsLink } from "../user/UserProfileLinks";


const StyledLink = styled(Nav.Link)`
color: grey;
font-size: 1.0rem;
margin-top: 2px;
${props => props.active && `
    //color: blue;
    //border-bottom: 3px solid green;
  `}
`;


function Udhr(props) {
 
  // let udhrProps = props;
  let article = -1;
  let part_of_statement = false;
  let commentLink = null;
  let shareLink = null;
  let followLink = null;
  let engageLink = null; 
  let learnLink = null;

  const { sessionActive }= useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { groups, editUDHRStatement, editLearnStatement, loadUdhrGroups } = useContext(GlobalContext);

  // Used to show a login modal, when user is not logged in
  const [showLoginModal, setShowLoginModal] = useState(false);


  const [gotResult, setGotResult] = useState(false);
  const [key, setKey] = useState('');
  const [udhr_groups, setUdhrGroups] = useState([]);


  // Encourage users to log in and engage by disable functions in PUBLIC area.
  let disableFunctions = false;

  if (props.area === AREA_PUBLIC ) disableFunctions = true;
  if (props.area === AREA_UDHR ) disableFunctions = true;
 
  // Set the UDHR ARTICLE to be displayed.
  // Check for article id in standard props or as part of a react-router url

  if (props.article > -1) {
    // Article is set using props.
    article = props.article
    // This UDHR is part of a statement, since Statement is
    // the only Component issuing articleID as a prop. //Not best coding
    part_of_statement = true
  } else {
    // ArticleId is suspected to be located as part 
    // of URL.
    if (props.match) 
      article = props.match.params.id;
  }

  // If part_of_statement is set via props,
  // use that value, otherwise set to false? 
  if ( props.partOfStatement ) {
    part_of_statement = true;
  } 

  if (!props.location) {
          part_of_statement = true;
        }

  const articleHeaderStart = 'The Universal Declaration of Human Rights ';
  const articleHeaderEnding = (article > 0 ) ? ('Article '+String(article)) : 'Preamble';

  let history = useHistory();
  const dispatch = useDispatch();
  
  
  
  useEffect(() => {
    // console.log("useEffect,key", key);
    return () => {
      // console.log("useEffect2");
    }
  }, [key, setKey])

  
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(push(homeLink.link));
  }

  const handleSelect = (key) => {
    console.log("handleSelect, key", key);
    if (!sessionActive) {
      setKey("");
      setShowLoginModal(true);
    } else {
      console.log("handleSelect, key", key);
      switch (key) {
        case "#linkLearn":
          console.log("handleSelect, key", key);
          break;
        case "#linkEngage":
          console.log("handleSelect, key", key);
          // get groups

          console.log("HANDLESelect props.article", article)
          GroupsDataService.getAllToUDHR(article)
          .then(function (response) {
      
            // Set the state of the StickButton
            console.log(response.data);
            setUdhrGroups(response.data);
            loadUdhrGroups(response.data);
            
          })
            .catch(function (error) {
            console.log(error);
          });


          break;
        default:
          console.log("handleSelect, key, default", key);
          break;
      }
      setKey(key);

    }
  }
  
// ***************************  SET TABS to be displayed when NOT in statement

  if ( !part_of_statement ) {
    
    // If the Udhr is NOT part of a statement, show Comment and Share links
    commentLink =  (
    
    <Nav.Item as="button" className="border-0 ml-1 mt-1" style={{backgroundColor: "#fd7e14", color: "white"}}>
        <Nav.Link className="text-white" eventKey="#linkComment">    
        Comment
      </Nav.Link>
    </Nav.Item>);

     shareLink =  (
     <Nav.Item as="button" className="border-0 ml-1 mt-1" style={{backgroundColor: "#17a2b8", color: "white"}}>
        <Nav.Link className="text-white" eventKey="#linkShare">
          Share
        </Nav.Link>
      </Nav.Item>);

    followLink =  (
      <Nav.Item as="button" className="border-0 mt-1" style={{backgroundColor: "#ffc107", color: "white"}}>
        <Nav.Link className="text-white" eventKey="#linkFollow">
        Discuss!
        </Nav.Link>
      </Nav.Item>);

  engageLink =  (
      <Nav.Item as="button" className="border-0 ml-1 mt-1" style={{backgroundColor: "#20c997", color: "white"}}>
        <Nav.Link className="text-white" eventKey="#linkEngage">
        Engage
        </Nav.Link>
      </Nav.Item>);

  learnLink =  (
  <Nav.Item as="button" className="border-0 ml-1 mt-1" style={{backgroundColor: "#5bc0de", color: "white"}}>
    <Nav.Link className="text-white" eventKey="#linkLearn">
    Learn
    </Nav.Link>
  </Nav.Item>);


} 
 
  function handleError () {
    let x = false
  }
  
  // CLOSE Button upper right on the card. Not showing if Udhr is part of statement
  
  const closeButton = (!part_of_statement) ? (    
    <button 
      type="button" 
      className="text-right text-danger p-0 border-0 close" 
      aria-label="close"
      onClick={handleClose}
      style={{outline: "none"}}
    >
      <span aria-hidden="true">&times;</span>
    </button>                   
    ): null

  
  // 
  const articleText = UDHR_ARTICLES[article]["text"].split('\n').map((item, key) => {
    return <p key={key}>{item}</p>}) 
  

  return (  
      
      <Card  className="shadow bg-white p-md-3 p-1 mb-3">        

        {closeButton}

        <Card.Body style={{backgroundColor: "White"}} className="p-0 m-2">        
          
          <Row className="justify-content-center ml-2 mr-2">

          {(part_of_statement && !(props.area===AREA_PUBLIC)) ?
          ( 
            <div className= "align-self-start mr-auto">
            <p style={{fontSize: "0.8rem"}} className="text-danger text-left mt-0 mb-1 p-0">
              {props.user.username} shared this right with you:
            </p>
            </div>
          ): null
          }

            <Card.Title style={{fontSize: "1.2rem", color: "grey"}} className="udhrText mt-0 mb-3 d-flex flex-row w-100">
              
              <Row className= "d-none d-md-flex flex-row w-100 justify-content-center flex-shrink pl-3">
                <Col sm={8} className=" ml-0 pl-0">{ articleHeaderStart} </Col>
                <Col sm={4} className="text-right">{ articleHeaderEnding}</Col>
              </Row>

              <Row className= "d-md-none d-flex flex-column w-100 justify-content-center flex-shrink pl-3">
                <Col sm={8} style={{fontSize: "0.9rem", color: "grey"}} className="ml-0 pl-0">{ articleHeaderStart} </Col>
                <Col sm={4} className=" ml-0 mt-2 pl-0 text-left">{ articleHeaderEnding}</Col>
                <Col sm={4} className=" ml-0 mt-2 pl-0 text-left">{ UDHR_ARTICLES[article].bttnText }</Col>
              </Row>

            </Card.Title>


            <div className="w-100">

              { (article >-1) ? (
                UDHR_ARTICLES[article]["text"].split('\n').map((item, key) => {
                  return <p style={{fontSize: "1.4rem"}} key={key}>{item}</p>
                  })                      
                  ): null}

            </div>




          </Row>

        {/* </Card.Body> */}
      
          <>


            <Tab.Container                                   
                as="a" 
                id="list-group-tabs" 
                defaultActiveKey=""
                activeKey={key}
                onSelect={handleSelect}
                >


              
              {/* Show errormessage if user clicks before log in */}

              {(showLoginModal) ? (<LoginModal show={showLoginModal} setShowLoginModal={setShowLoginModal} />) : null}
              
              <Row className="pl-0 ml-0 mr-4">
                
                <Col className="ml-0 pl-0 mt-3 d-flex flex-row flex-wrap justify-content-start">      
                  
                  <Nav>

                    {followLink}

                    {engageLink}

                    {learnLink}

                    {commentLink}
                    
                    {shareLink}

                    

                    {/* <Nav.Item>
                      <Nav.Link eventKey="#linkFollow">Follow</Nav.Link>
                    </Nav.Item>

                     <Nav.Item>
                      <Nav.Link eventKey="#linkProjects">Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkLaw">Law</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkCases">Cases</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkReport">Report</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkComplaint">Complaint</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkComplaint">What does it mean?</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkComplaint">Stories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkComplaint">Put your rights to work</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="#linkComplaint">I need help</Nav.Link>
                    </Nav.Item> */}

                  </Nav>
                </Col>
            </Row>
            
            <Row> 
                <Col>
                  <Tab.Content 
                    className="mt-4 w-100"
                  >                
{/* 

                  TODO : Comming functionality

                   <Tab.Pane eventKey="#linkLaw">
                      <Card>
                        <Card.Title>Law</Card.Title>
                        </Card>
                    </Tab.Pane>

                    <Tab.Pane eventKey="#linkCases">
                      Cases
                    </Tab.Pane>
                    <Tab.Pane eventKey="#linkReport">
                      Report
                    </Tab.Pane>
                    <Tab.Pane eventKey="#linkComplaint">
                      Complaint
                    </Tab.Pane> */}


                    <Tab.Pane eventKey="#linkShare" className="pl-2" >
                        <ShareUDHR article={article} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="#linkProjects">
                    Projects
                    </Tab.Pane>
                    


                    <Tab.Pane
                      
                      className="pl-2" 
                      eventKey="#linkFollow"
                      onEnter={(e) => console.log("Tab.Pane Follow", e, e.active)}
                      >                      

                        {
                          (!disableFunctions && !part_of_statement) ? (                      
                            <UDHRStatements area={AREA_UDHR} identifier={article} setGotResult={setGotResult} category={1} thisEditStatement={editUDHRStatement}/>                       
                        ): null 
                        
                        }
                      
                      {(!gotResult) ? <Card.Text>Join the discussion. Start a thread by adding a "Comment"!</Card.Text> : null}
                      {(!gotResult) ? <Card.Text>Share your opionion, knowledge, story or anything else you would like to share! Click on "Comment".</Card.Text> : null}

                    </Tab.Pane>


                        
                    <Tab.Pane 
                      className="pl-2" 
                      eventKey="#linkEngage"
                      onEnter={(e) => console.log(e.active)}
                      >
                      {/* <p>Write a short comment about this UDHR to your friends and followers. Or you may consider writing a longer piece. Mark it as legal, opinion, story, science, political to better reach your audience.
                      Do you have a video, pictures etc. that fits for  this UDHR? Mark your comment as "shared"!</p> */}
                      
                      Engage! Join groups. Create groups!
                  

                     <StickList stickType={ STICKTYPE_ENGAGE } stickListText="" objectList={udhr_groups} objectType="udhr_groups" />

                    </Tab.Pane>
                    

                    <Tab.Pane 
                      className="pl-2" 
                      eventKey="#linkLearn"
                      onEnter={(e) => console.log("Tab.Pane Follow", e, e.active)}
                      >
                      {/* <p>Write a short comment about this UDHR to your friends and followers. Or you may consider writing a longer piece. Mark it as legal, opinion, story, science, political to better reach your audience.
                      Do you have a video, pictures etc. that fits for  this UDHR? Mark your comment as "shared"!</p> */}
                      {
 

                        (!disableFunctions && !part_of_statement) ? (                   
                            <UDHRStatements area={AREA_UDHR} identifier={article} setGotResult={setGotResult} category={10} thisEditStatement={editLearnStatement}/>                      
                          ): null 

       

                          }
                          {(!gotResult) ? <Card.Text>Learn from people all over the world!</Card.Text> : null}
                        {(!gotResult) ? <Card.Text>Contribute with your expertice! Go to "Comment" and check "Learn".</Card.Text> : null}


                    </Tab.Pane>
                    
                    
                    <Tab.Pane 
                      className="px-2" 
                      eventKey="#linkComment"
                      onEnter={(e) => console.log(e.active)}
                      >
                      {/* <p>Write a short comment about this UDHR to your friends and followers. Or you may consider writing a longer piece. Mark it as legal, opinion, story, science, political to better reach your audience.
                      Do you have a video, pictures etc. that fits for  this UDHR? Mark your comment as "shared"!</p> */}
                      
                      <AddStatement udhr={article} objectState="user"/>

                    </Tab.Pane>

                  </Tab.Content>
                  </Col>            
                <div style={{height: "20px"}}></div>  
              </Row>
            </Tab.Container>
            </>
            </Card.Body>

      </Card>
    );
};

export default Udhr;
