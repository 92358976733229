import React, {Component} from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import {UDHR_ARTICLES} from '../../constants/UdhrArticles.js'
import { LinkContainer } from "react-router-bootstrap";
import { Container, Col, Row, DropdownButton, Dropdown, Alert, Carousel } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import Udhr from '../udhr/Udhr';
import { AREA_PUBLIC } from '../../constants/GlobalConstants.js';
import  MobileUdhrBar  from './MobileUdhrBar.js';


class UdhrBar extends Component {

  constructor(props) {
    
    super(props);
    this.state = {
      value: 0,
      buttons: []
    }   
    
    this.handleChange = this.handleChange.bind(this);

  }
  
  componentDidMount() {

    // Create UDHRButtons according to the setup in UDHR_ARTICLES
    
    const UdhrButtons = UDHR_ARTICLES.map(article => {
      return {
        id: article.id
      };
    });
    
    // create a new "State" object without mutating 
    // the original State object. 
    const newState = Object.assign({}, this.state, {
      buttons: UdhrButtons
    });

    // store the new state object in the component's state
    this.setState(newState);
    
   }

  handleChange(val, e) {
    this.setState({value: val});
    // setValue(val); If using Hooks
    this.props.onUdhrChange(e.currentTarget.value);
  }

  render() {
    return (
      
      
      <Container className="pb-2">            
        
        <ShowOnlyWhenLoggedIn>
          <Row className="justify-content-center">
            <Col xs={12} xl={12} className="text-center">
              <Alert variant="secondary"><b>These are the Universal Human Rights. Select on any of them to discuss-, engange and learn! </b></Alert> 
            </Col>                   
          </Row>
        </ShowOnlyWhenLoggedIn>

        <Row className="justify-content-center">
          <Col xs={12} xl={12}>                   
            
              
              
              {/* <div id="buttons">    */}

              {/* <!-- dropdown for mobile --> */}
              {/* d-xs-flex flex-row justify-content-center */}
              
              <div className="d-md-none d-flex flex-row flex-wrap justify-content-center p-0">

              <MobileUdhrBar />
                {/* <Carousel className="m-0" variant="secondary" id="dropdown-basic-button">  */}

                  {/* {UDHR_ARTICLES.map(article => (      */}
                      

                      {/* <Carousel.Item className="w-100" key={article.id.toString()} value={article.id}> */}
                         {/* <Carousel.Caption style={{color: 'black' }}> */}
                             {/* <Udhr article={article.id} area={ AREA_PUBLIC } partOfStatement={false} />  */}
                         {/* </Carousel.Caption>  */}
                       {/* </Carousel.Item>  */}
                    {/* ))} */}
                {/* </Carousel> */}

              </div>

              <div id="buttons">   

              {/* <!-- togglebuttongroup for desktop --> */}
              <ToggleButtonGroup  className="d-none d-md-flex flex-row flex-wrap justify-content-center" type="radio" name="udhr-buttons" value={this.state.value} onChange={this.handleChange}>
                {UDHR_ARTICLES.map(article => (     
                  <LinkContainer key={article.id} to={{ pathname: `/home/udhr/${article.id}`, state: {partOfStatement: false}}}>


                  <ToggleButton title="Click to view this right" style={{color: "#333", backgroundColor: "#1266F1"}} className="rounded-0 mr-1 mt-1" size="sm" key={article.id.toString()} value={article.id}>
                    {article.id + ' ' + article.lgBttnText}
                  </ToggleButton>

                  </LinkContainer>
                  ))}
              </ToggleButtonGroup>


            </div>

          </Col>

        </Row>
      </Container>
       
    ) }
}

export default UdhrBar;        