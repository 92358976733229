import React from "react";
import * as Io from "react-icons/io";

import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { LinkItem } from "../utils/navLinkInterface";
  
 
export const accountSettingsLink: LinkItem = {
  link: "/user/profile/account",
  displayName: "Account",
};

export const userProfileLink: LinkItem = {
  link: "/user/profile",
  displayName: "Profile",
};

export const userLink: LinkItem = {
  link: "/user/:id",
  displayName: "User",
};

export const friendsLink: LinkItem = {
    link: "/user/profile/friends",
    displayName: "Friends",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

  export const friendRequestsLink: LinkItem = {
    link: "/user/profile/requests",
    displayName: "Friend requests",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

  export const followsLink: LinkItem = {
    link: "/user/profile/follows",
    displayName: "Following",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

  export const followersLink: LinkItem = {
    link: "/user/profile/followers",
    displayName: "Followers",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

  export const blockLink: LinkItem = {
    link: "/user/profile/blocks",
    displayName: "Blocks",
    icon: <IconWrapper Icon={Io.IoIosHome} />
  };

  export const groupsLink: LinkItem = {
    link: "/user/profile/groups",
    displayName: "Groups",
    icon: <IconWrapper Icon={Io.IoIosPeople} />
  };

  export const projectsLink: LinkItem = {
    link: "/user/profile/projects",
    displayName: "Projects",
  };

