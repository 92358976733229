import React, {useState, useContext, useEffect} from "react";
import axios from 'axios';
import {Form, FormControl, Col, Button} from 'react-bootstrap'
import Friends from '../friends/Friends'
import UserDataService from '../../../services/UserDataService'
import { useHistory } from "react-router-dom";
import {searchLink} from '../views/contentPageLinks'
import { GlobalContext } from "../../state/GlobalState";
import LoginModal from '../views/userAccess/login/LoginModal'
import { useUserAuthSelector } from "../../state/typedSelectors";

function Search() {
     
    const { searchString, setSearchString } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    // const [searchString, setSearchString] = useState('');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const { loadUsers, users } = useContext(GlobalContext);

    const { sessionActive }= useUserAuthSelector(
        state => state.userAuthReducer
      );

    let history = useHistory();
   
        useEffect(() => {
   
            goSearch();
      }, [searchString]);
    
    const goSearch = () => {
        
        if (sessionActive) {

        setLoading(true);
        
        console.log(searchString);

        UserDataService.getUserByName(searchString)
        .then(response => {
            loadUsers(response.data);
        })
        .catch(e => {
        console.log(e);
        });
    
        setLoading(false);
    }
    };
    
    const onChangeHandler = async e => {
        console.log(e.target.value)
        setSearchString(e.target.value);
      };
    

    const handleClick = (e) => {
        if (!sessionActive) {
            setShowLoginModal(true);  
        } else {
        history.push("/search/");
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            console.log(e);
            e.preventDefault();


            if (!sessionActive) {
                setShowLoginModal(true);  
            } else {
            history.push("/search");
            }
        } else
            console.log(e.currentTarget.value);
    }

    return (

        <>

            {(showLoginModal) ? (<LoginModal show={showLoginModal} setShowLoginModal={setShowLoginModal} />) : null}

            {/* <Col className="col-7 d-none d-md-flex flex-row justify-content-center p-1"> */}
                    
                    <Form inline>    
                        <FormControl
                            value={searchString}
                            onChange={e => onChangeHandler(e)} 
                            size="sm" 
                            type="text" 
                            placeholder="Search for persons and groups" 
                            className="mr-sm-2" 
                            style={{width: "300px"}}
                            onKeyPress={handleKeyPress}
                            className="mt-1"
                            />
                        
                        <Button 
                            size="sm" 
                            variant="primary"
                            onClick={(searchString) ? handleClick : null}
                            className="mt-1 ml-1 text-right"
                        >
                            Search
                        </Button>
                    </Form>
                                    
            {/* </Col> */}

        </>

    );
}

export default Search;