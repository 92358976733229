import React, { useEffect, useContext } from "react";
import Stick from "./Stick";
import { GlobalContext } from "../../../state/GlobalState";


import FriendsDataService from "../../../../services/FriendsDataService";
import Friends from "../../friends/Friends";

function StickList({ stickType, stickListText, objectList, objectType }) {

  // console.log(stickType, stickListText, objectList);
 
  return (

    <>
    <div className="text-center text-secondary mt-3 mb-3"> <h1 style={{fontSize: "1.6rem"}} > {stickListText} </h1> </div>

    <div> 
        {
        (objectList) ? objectList.map(object => 
            <Stick key={object.pk} stickObject={object} stickType={stickType} objectType={objectType} />
        ) : null
        }
    </div>
    </>
  );
}

export default StickList;