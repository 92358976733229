import React from 'react';
import { Container, Col, Row} from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers';
import UserProfileLink from './UserProfileLink'

function UserBar(){

  return(

        <Container>
          <Row className="justify-content-end">
            <Col xs={12} >
                <ShowOnlyWhenLoggedIn>
                        {/* <UserNameAndPicture />
                        <UserProfileLink /> */}
                </ShowOnlyWhenLoggedIn>
              </Col>
          </Row>
      </Container>

    )

  }


export default UserBar;