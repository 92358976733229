import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../web/state/GlobalState";
import FriendsDataService from './FriendsDataService';
import FollowersDataService from './FollowersDataService';
import { useUserAuthSelector, useGroupProfileSelector } from "../web/state/typedSelectors";
import StatementsDataService from './StatementsDataService';
import GroupsDataService from './GroupsDataService';


export const GroupDataComponent = () => {

    const { loadGroups, loadGroupMembers, loadGroupFollowers, loadGroupFollowees, loadGroupBlockers, group_members, group_followers } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    const { authToken, sessionActive, userid } = useUserAuthSelector(
        state => state.userAuthReducer
      );
      
    const { pk, id, user, owner, admin, name, description, code, image } = useGroupProfileSelector(
      state => state.groupProfileReducer
    );

      useEffect(() => {
       
      // const ac = new AbortController();

      if (sessionActive === true) {
              console.log("HEISANN, MONTEBELLO!", pk, id, user, owner, admin, name, description, code, image);
              console.log("IS THIS THE CORRECT USER? :", user.id, id, pk, user, owner)  ;
              Promise.all([
                FriendsDataService.getAllId(pk), 
                FollowersDataService.getAllFollowers(pk),
                FollowersDataService.getAllFollowees(pk),
                FriendsDataService.blocking(pk),
                ]).then((response) => {     
                  console.log("HOPPSANN!", group_members);
                  loadGroupMembers(response[0].data);
                  loadGroupFollowers(response[1].data);
                  loadGroupFollowees(response[2].data);
                  loadGroupBlockers(response[3].data);
                  console.log("TJOBING!", response.data, group_followers);                    
                  console.log("group_mebmer!", response[0].data);                    
                })
                .catch(e =>  {
                  console.log("DETTE GIKK IKKE BRA...");
                  console.error(e); 
                } );  

          }

        return () => {

        }


          }, [authToken])

         

    

    return(       
        <>
        <div></div>
      </>);
}

export default GroupDataComponent;