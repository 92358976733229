import React, { useContext, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import HomePage from "../views/HomePage"
import Following from "../follow/Follows"
import FriendRequests from '../friends/FriendRequests'
import FriendsList from '../friends/FriendsList'
import StickList from '../generic/stick/StickList'
import UserProfile from "../views/user/UserProfile"
import UserProfileSettings from "./UserProfileSettings"
import { userProfileLink, accountSettingsLink, friendsLink, blockLink, friendRequestsLink, followsLink, followersLink, groupsLink, projectsLink } from "./UserProfileLinks";
import { userLink } from "../views/contentPageLinks";
import Follows from "../follow/Follows";
import Groups from "../groups/Groups";
import Project from "../project/Project";
import CreateGroup from '../groups/CreateGroup';
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector } from "../../state/typedSelectors";

import { 
  STICKTYPE_USER_SEARCH,
  STICKTYPE_FRIENDREQUESTS, 
  STICKTYPE_FRIENDS,
  STICKTYPE_FOLLOWERS,
  STICKTYPE_FOLLOWEES,
  STICKTYPE_BLOCKS,
  STICKTYPE_GROUPS,
  STICKTYPE_PROJECTS
 } from "../../constants/stickTypes";

 import { GlobalContext } from "../../state/GlobalState";

export default ({ match, location }) => {

  const { users, friends, friend_requests, followers, followees, blockers, groups } = useContext(GlobalContext);

  const { userid } = useUserAuthSelector(
    state => state.userAuthReducer
  );


  useEffect(() => {
    //effect
    return () => {
      //cleanup
    }
  }, [location])

  return (
    <React.Fragment>

      <Switch>
        
        <Route exact path={userProfileLink.link} component={UserProfile} />
        <Route exact path={accountSettingsLink.link} component={UserProfileSettings} />
        <Route exact path={friendsLink.link}>
          <StickList stickType={STICKTYPE_FRIENDS} stickListText="Your friends:" objectList = {friends} objectType="friends" />
        </Route> 
        <Route exact path={friendRequestsLink.link}>
          {/* <StickList stickType={STICKTYPE_FRIENDREQUESTS} objectList = {friendRequests} /> */}
          <FriendRequests objectType="friends" user_id={userid}/>
        </Route>
        <Route exact path={followersLink.link}>
          <StickList stickType={STICKTYPE_FOLLOWERS} stickListText="Your followers:" objectList={followers} objectType="friends" />
        </Route>
        <Route exact path={followsLink.link}>
          <StickList stickType={STICKTYPE_FOLLOWEES} stickListText="Your are currently following:" objectList = {followees} objectType="friends" />
        </Route>
        <Route path={blockLink.link}>
          <StickList stickType={STICKTYPE_BLOCKS} stickListText="Users your have blocked:" objectList = {blockers}  objectType="friends" />
        </Route>

        <Route path={groupsLink.link}>


        {(groups.length > 0) ? (
            <div className="text-right"> <CreateGroup /> </div>
          ) : (null) }    

          <StickList stickType={STICKTYPE_GROUPS} stickListText="Your groups:" objectList = {groups}  objectType="friends" />

          {(groups.length === 0) ? (
            <div className="text-center mt-5"> <CreateGroup /> </div>
          ) : (null) }


        </Route>
        <Route path={projectsLink.link} component={Project} />        
      
      </Switch>

    </React.Fragment>
  );
};
