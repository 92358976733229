import axios from "axios";
import { API_URL } from '../web/constants/apiUrl'

export const setAuthToken = token => {
  if (token) {
  //applying token
  
    const authString = `Token ${token}`

  http.defaults.headers.common['Authorization'] = authString;
  httpForm.defaults.headers.common['Authorization'] = authString;

  console.log("http", http.defaults.headers.common);
  
  } else {
  //deleting the token from header
  delete http.defaults.headers.common['Authorization'];
  delete httpForm.defaults.headers.common['Authorization'];
  }
 }

export const http = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
  });

  export const httpForm = axios.create({
    baseURL: API_URL,
    headers: {
      "content-type": "multipart/form-data"
    }
    });
  
export const getReq = async (source, endpoint) => {
  try {
    const { response } = await http.get(endpoint, {
      cancelToken: source.token,
    });
    return response;
  } catch (err) {
    if (axios.isCancel(err)) {
      return "axios request cancelled";
    }
    return err;
  }
};
    