import React from 'react'
import { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import {API_PROFILE_URL, API_URL, API_MEDIA_URL} from "../../../constants/apiUrl";
import UserBar from '../../user/UserBar'
import Friends from "../../friends/Friends"
import Follows from "../../follow/Follows"
import FollowList from "../../follow/FollowList"
import FollowersHorizontalList from "../../follow/FollowersHorizontalList"
import UserList from "../../user/UserList"
import UserPicture from "../../user/UserPicture"
import UploadPicture from "../../photos/UploadPicture"
import UploadPhoto from "../../photos/UploadPhoto"
import ProfileForm from "./ProfileForm"
import { Button, Container, Col, Row, Card, Table, CardDeck } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector, useUserProfileSelector, useUserSettingsSelector } from "../../../state/typedSelectors";
import { useHistory } from "react-router-dom";
import {homeLink} from '../contentPageLinks'
import Loader from "../../utils/Loader"
import { GlobalContext } from "../../../state/GlobalState";
import { UPLOAD_TO_USER } from '../../../constants/GlobalConstants';

const UserProfile = (props) => {

  const { loadPersons, persons } = useContext(GlobalContext);

  const { username, first_name, last_name, email, userid, authToken} = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { ufirst_name, ulast_name, uemail, date_of_birth, status, gar_avatar_name, image,
    created, born_in, living_in, city, bio, last_saved } = useUserProfileSelector(
    state => state.userProfileReducer
  );  

  let localImage = null;
  
  if (image) {
    localImage = image;
    if (localImage.search('http') === -1) {
      localImage = API_MEDIA_URL + image;
      // console.log("LocalTmage", localImage);
    } else {
      localImage = image;
    } 
  }

  console.log(localImage);
 
  const [user, setUser] = useState({ user: [] });
  
  let history = useHistory();
  
  const handleClose = (e) => {
    history.push("/home/user/");
  }    

  var user_since_date = new Date(created);
  var user_born_date = new Date(date_of_birth);

  const uGarAvatarName = ( 
    <tr>
        <td><Card.Text>GarAvatarId:</Card.Text></td>
        <td><Card.Text>{gar_avatar_name}</Card.Text></td>
    </tr>
    );
    
  const uName = (ufirst_name) ? 
    (
      <tr>
        <td><Card.Text>Name:</Card.Text></td>
        <td><Card.Text>{ufirst_name} {ulast_name}</Card.Text></td>
      </tr>) :
  
    (
      <tr>
        <td><Card.Text>Username:</Card.Text></td>
        <td><Card.Text>{username}</Card.Text></td>
      </tr>)
  
  const uStatus = (status) ? (
    <tr>
      <td><Card.Text>Status:</Card.Text></td>
      <td><Card.Text>{status}</Card.Text></td>
    </tr>): null

  const uUsername = (username) ? (
    <tr>
      <td><Card.Text>Username:</Card.Text></td>
      <td><Card.Text>{username}</Card.Text></td>
    </tr>): null

  const uCreated = (created) ? (
    <tr>
      <td><Card.Text>User since:</Card.Text></td>
      <td><Card.Text>{user_since_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null

  const uBorn = (date_of_birth) ? 
    (<tr>
      <td><Card.Text>Born:</Card.Text></td>
      <td><Card.Text>{user_born_date.toLocaleDateString()}</Card.Text></td>
    </tr>): null

  const uBornIn = (born_in) ? 
  (<tr>
    <td><Card.Text>Born in:</Card.Text></td>
    <td><Card.Text>{born_in}</Card.Text></td>
  </tr>): null
  
  const uLivingIn = (living_in) ? 
  (<tr>
    <td><Card.Text>Living in:</Card.Text></td>
    <td><Card.Text>{living_in}</Card.Text></td>
  </tr>): null

  const uCity = (city) ? 
  (<tr>
    <td><Card.Text>City:</Card.Text></td>
    <td><Card.Text>{city}</Card.Text></td>
  </tr>): null

  const uBio = (bio) ? 
  (
    <div className="text-center">
      <Card.Text>Bio:</Card.Text>
      <Card.Text>{bio}</Card.Text>
    </div>
  ): null


    return (

      <React.Fragment>                                    
              <Container className="d-flex justify-content-center w-100">
                      <Card className="w-100 pt-3">

                      <Card.Title className="pl-4 mb-0 text-secondary">{ufirst_name + ' ' + ulast_name}</Card.Title>

                        <Row className="w-100 mb-2 d-flex justify-content-center">
                          
                          <Card.Body className="d-flex flex-column-reverse flex-md-row">
                            
                            <Col sm={6}>
                                <Table style={{fontSize: "0.9rem"}} size="sm">
                                  <tbody>
                                    {uGarAvatarName}
                                    {uName}
                                    {uStatus}
                                    {uUsername}
                                    {uCreated}
                                    {uBorn}
                                    {uBornIn}
                                    {uLivingIn}
                                    {uCity}
                                  </tbody>
                                </Table>                           
                              
                                <div className="d-flex align-items-start">
                                  <ProfileForm />
                                  <UploadPhoto uploadTo={UPLOAD_TO_USER} />                                
                                </div>

                            </Col>
                            
                            <Col className=" d-flex flex-column justify-content-start align-items-center">
                              
                              <Card.Img className="mb-4 " src={localImage}  alt={ulast_name} style={{width: '165px', height: '165px'}} />
                              {uBio}
                                                       
                            </Col>

                          </Card.Body>
  
                          </Row>
  
                      </Card>     

                  </Container>
                      
      </React.Fragment>

    );
  };

export default UserProfile;