import React, { useState, useContext } from 'react';
import StickActions from './StickActions';
import { Button } from 'react-bootstrap';
import { GlobalContext } from "../../../state/GlobalState";
import FollowersDataService  from '../../../../services/FollowersDataService';
import FriendsDataService  from '../../../../services/FriendsDataService';
import { useUserAuthSelector, useGroupProfileSelector } from "../../../state/typedSelectors";

import { 
    ACTION_ACCEPT_FRIENDREQUEST,
    ACTION_REJECT_FRIENDREQUEST,    
    ACTION_ADD_FOLLOWEE,
    ACTION_ADD_FRIENDREQUEST, ACTION_BLOCK, ACTION_REMOVE_FOLLOWEE, ACTION_REMOVE_FRIEND, ACTION_UNBLOCK,
    ACTION_ADD_FOLLOWER, ACTION_REMOVE_FOLLOWER, ACTION_ADD_GROUP, ACTION_REMOVE_GROUP
  } from "../../../constants/stickTypes";

  import { 
    STICKTYPE_USER_SEARCH,
    STICKTYPE_FRIENDREQUESTS,
    STICKTYPE_FRIENDS,
    STICKTYPE_FOLLOWERS,
    STICKTYPE_FOLLOWEES,
    STICKTYPE_BLOCKS,
    STICKTYPE_GROUPS,
    STICKTYPE_PROJECTS
  } from "../../../constants/stickTypes";

export const StickButton = ({stickObject, stickType, objectType, action, stateAction, text, setText, color, setColor}) => {

    const [set, setSetState] = useState(false);

    const { userid } = useUserAuthSelector(
        state => state.userAuthReducer
      );    


    const { pk, user } = useGroupProfileSelector(
            state => state.groupProfileReducer
          );  

    let user_id = null;

    let ThisAddFollowee = null;
    let ThisRemoveFollowee = null;
    let ThisLoadFollowees = null;
    let ThisBlock = null;
    let ThisUnblock = null;
    let ThisLoadBlockers = null;
    let ThisAddFriend = null;
    let ThisRemoveFriend = null;
    let ThisLoadFriends = null;
    let ThisAddFollower = null;
    let ThisRemoveFollower = null;
    let ThisLoadFollowers = null;
        
    const { addFollowee, removeFollowee, loadFollowees, block, 
        unblock, loadBlockers, addFriend, removeFriend, loadFriends, addFollower,
        loadFollowers, removeFollower,  
        addGroup, removeGroup, loadGroups,
        addGroupMember, removeGroupMember, loadGroupMembers,
        addGroupFollower, removeGroupFollower, loadGroupFollowers,
        addGroupFollowee, removeGroupFollowee, loadGroupFollowees,
        blockGroup, unblockGroup, loadGroupBlockers
        } = useContext(GlobalContext);

         
    switch (objectType) {
        
        case "groups":

            // console.log("groups, stickButton");

            ThisAddFollowee = addGroupFollowee;
            ThisRemoveFollowee = removeGroupFollowee;
            ThisLoadFollowees = loadGroupFollowees;
            ThisBlock = blockGroup;
            ThisUnblock = unblockGroup;
            ThisLoadBlockers = loadGroupBlockers;
            ThisAddFriend = addGroupMember;
            ThisRemoveFriend = removeGroupMember;
            ThisLoadFriends = loadGroupMembers;
            ThisAddFollower = addGroupFollower;
            ThisRemoveFollower = removeGroupFollower;
            ThisLoadFollowers = loadGroupFollowers;

            user_id = user.id;

            break;

        default:
            ThisAddFollowee = addFollowee;
            ThisRemoveFollowee = removeFollowee;
            ThisLoadFollowees = loadFollowees;
            ThisBlock = block;
            ThisUnblock = unblock;
            ThisLoadBlockers = loadBlockers;
            ThisAddFriend = addFriend;
            ThisRemoveFriend = removeFriend;
            ThisLoadFriends = loadFriends;
            ThisAddFollower = addFollower;
            ThisRemoveFollower = removeFollower;
            ThisLoadFollowers = loadFollowers;

            user_id = userid;

    }
        
    return(       
        <>
            
            {/* 
                                   
            STICKBUTTON:

            RENDERS BUTTON
            
            ISSUES A CRUD-ACTION REQUEST TO STICKACTIONS.

            UPDATES STATE WHEN STICKACTION IS PERFORMED
            
            */}
            
            <Button 
                id="userstick-friend-btn" size="sm" 
                className="ml-1" 
                onClick={(event) => {

                    // var data = new FormData();
                    // data.append('user_id', stickObject.pk);
                    // console.log("StickButton, stickObject.pk", stickObject.pk)
                    
                    event.preventDefault();
                    
                    // Make a request to the backend, according to specified action.
                    // Perform action on id. Action tells which object to perform on:
                    // Friend, FriendsRequest, Follow or Block

                    console.log("USER GROUP/FRIEND USER, stickButton", user_id, user.id);

                    StickActions.action(action, stickObject, setSetState, user_id);
                    
                    // update state as defined in GlobalState.
                    // TODO: Should be done in StickActions.


                    switch (action) {

                        case ACTION_ADD_FRIENDREQUEST: {
                            //TODO: No stat for friendrequest yet
                            break;
                        }
                        
                        case ACTION_ACCEPT_FRIENDREQUEST: {
                            ThisAddFriend(stickObject);
                            break;
                        }

                        case ACTION_REJECT_FRIENDREQUEST: {
                            //TODO: No stat for friendrequest yet
                            break;
                        }

                        case ACTION_REMOVE_FRIEND: {
                            // console.log("removeFriend");
                            ThisRemoveFriend(stickObject);
                            break;
                            }

                        case ACTION_ADD_FOLLOWER: {
                            // console.log("addFollower", action);
                            ThisAddFollower(stickObject);
                            break;
                            }

                        case ACTION_REMOVE_FOLLOWER: {
                            ThisRemoveFollower(stickObject);
                            break;
                            }

                        case ACTION_ADD_FOLLOWEE: {
                            console.log("addFollowee");
                            ThisAddFollowee(stickObject);
                            break;
                            }

                        case ACTION_REMOVE_FOLLOWEE: {
                            console.log("removeFollowee");
                            ThisRemoveFollowee(stickObject);
                            break;
                            }

                        case ACTION_ADD_GROUP: {
                            console.log("addGroup");
                            addGroup(stickObject);
                            break;
                            }

                        case ACTION_REMOVE_GROUP: {
                            console.log("removeGroup");
                            removeGroup(stickObject.pk);
                            break;
                            }

                        case ACTION_BLOCK: {
                            
                            console.log("ACTION_BLOCK", stickObject)

                            ThisBlock(stickObject);
                            ThisRemoveFriend(stickObject);
                            ThisRemoveFollower(stickObject);
                            ThisRemoveFollowee(stickObject);
                            
                            break;
                            }
                        
                        case ACTION_UNBLOCK: {                            
                            
                            ThisUnblock(stickObject);
                            
                            // No guarantee that the StickAction has been performed.
                            // Means no guarantee the user is unblocked before reading
                            // the new state.
                            // if fact - stateAction is not performed until all
                            // below statements are executed. Why?

                            
                            FriendsDataService.blocking(user_id)
                            .then(response => {



                                FriendsDataService.getAllId(user_id)
                            .then(response => {
                                console.log("getAllFriends", response.data);
                                ThisLoadFriends(response.data);
                            })
                            .catch(e => {
                            console.log(e);
                            });   


                            FollowersDataService.getAllFollowers(user_id)
                                .then(response => {
                                console.log("getAllFollowers", response.data);
                                ThisLoadFollowers(response.data);
                                })
                                .catch(e => {
                                console.log(e);
                                });   


                                FollowersDataService.getAllFollowees(user_id)
                            .then(response => {
                            console.log("getAllFollowees", response.data);
                            ThisLoadFollowees(response.data);
                            })
                            .catch(e => {
                            console.log(e);
                            });  





                                })
                            .catch(e => {
                                console.log(e);
                                });
                            
         

    
   


                            break;
                            
                            }                        
                        }
                    }
                }
                variant={(set) ? setColor : color}
                >
                {(set) ? setText : text}
            </Button>
      </>);
}