import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Form, FormControl } from 'react-bootstrap'
import { ImageCropper } from "react-bootstrap-image-cropper";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import { updateGroupProfile } from "../../state/groupProfileState/groupProfileActions";
import { useUserAuthSelector, useUserProfileSelector, useGroupProfileSelector } from "../../state/typedSelectors";
import { API_PROFILE_URL, API_GROUP_URL } from "../../constants/apiUrl";
import { userProfileLink } from "../user/UserProfileLinks";
import {  groupsLink } from "../user/UserProfileLinks";
import {  UPLOAD_TO_USER, 
          UPLOAD_TO_GROUP,  
          UPLOAD_TO_PROJECT, 
          UPLOAD_TO_ARRANGEMENT, 
          UPLOAD_TO_STATEMENT, UPLOAD_TO_REPLY } from '../../constants/GlobalConstants'


const UploadPhoto = (props) => {
    const [upload_image, setImage] = useState(null);
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const fileRef = useRef();

    
    // set which object to upload to
    const uploadTo = props.uploadTo;

    const { userid, username, authToken } = useUserAuthSelector(
        state => state.userAuthReducer
    );

    const { ufirst_name, ulast_name, uemail, date_of_birth, status, gar_avatar_name,
    created, born_in, living_in, city, bio, last_saved } = useUserProfileSelector(
    state => state.userProfileReducer
    ); 

    const { id, owner, name, description, code, image } = useGroupProfileSelector(
      state => state.groupProfileReducer
    );    
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

    function handleChange(croppedFile) {
        
        // console.log(croppedFile.name);
        setImage(croppedFile);

      }   

    const handleSubmit = (e) => {
        e.preventDefault();
        if (upload_image) {

          console.log("UPLOAD IMAGE", upload_image);
          
          let url = "";
          let form_data = new FormData();
          form_data.append('image', upload_image, 'this.jpg');
          
          switch (uploadTo ) {
            case UPLOAD_TO_USER:
              url = API_PROFILE_URL+userid+"/";
              break;
            case UPLOAD_TO_GROUP:
              url = API_GROUP_URL+id+"/";
              break;
            default:
              url = API_PROFILE_URL+userid+"/";
              break;
          }
                   
          axios.patch(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              'Authorization': 'Token ' + String(authToken)
            }
          })
          .then(response => {      
                
                // console.log(response);
                // console.log("Nytt bilde: ", response.data.image);

                switch (uploadTo ) {
                  case UPLOAD_TO_USER:
                    
                    dispatch(
                      updateUserProfile(
                        response.data.username,
                        response.data.first_name,
                        response.data.last_name,
                        response.data.email,
                        response.data.gar_avatar_name,
                        response.data.image,
                        response.data.last_seen,
                        response.data.online,
                        response.data.created,
                        response.data.status,
                        response.data.date_of_birth,
                        response.data.born_in,
                        response.data.living_in,
                        response.data.city,
                        response.data.bio,
                        response.data.last_saved
                      )  
                    );
                    
                    dispatch(push(userProfileLink));
                    break;

                  case UPLOAD_TO_GROUP:

                      console.log("UPLOAD_TO_GROUP", response.data);

                      const groupUser = {
                        'id': response.data.user.pk,
                        'gar_avatar_name': response.data.user.gar_avatar_name,
                        'username': response.data.user.username,
                        'email': response.data.user.email,
                        'created': response.data.user.created
                      }

                      dispatch(
                        updateGroupProfile(
                        response.data.pk, 
                        response.data.id,
                        groupUser,
                        response.data.owner,
                        response.data.admin, 
                        response.data.name, 
                        response.data.established, 
                        response.data.description, 
                        response.data.code,
                        response.data.image,
                        response.data.country,
                        response.data.city        
                      )  
                    );
                                      
                    dispatch(push(groupsLink));
                    break;

                    default:
                      break;
                }


                
                setShow(false);               

              }
              )
              .catch(error => console.log(error))

         };
      };

      
    return (
      <>
        <Button variant="primary" size="sm" className="ml-1" onClick={handleShow}>
          Add Photo
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload profile picture</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
          <h5 className="text-center">Click on the square below to select a image</h5>
          
          <div className="text-center">
            <ImageCropper
                  fileRef={fileRef}
                  onChange={handleChange}
                  cropOptions={{ aspect: 1 / 1, maxZoom: 10 }}
                  outputOptions={{ maxWidth: 165, maxHeight: 165 }}
                  previewOptions={{ width: 400, height: 300 }}
            />
        </div>
          
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="sm" onClick={handleClose}>
              Dismiss
            </Button>
            <Button variant="primary" size="sm" onClick={handleSubmit}>
              Save to profile
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default UploadPhoto;