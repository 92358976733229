import React, { Fragment, useState, useContext } from "react";
import { GlobalContext } from "../../state/GlobalState";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { useUserAuthSelector, useUserProfileSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { InputGroup, Form, Modal, FormControl, Button } from "react-bootstrap";
import { AREA_UDHR } from "../../constants/GlobalConstants";

export const AddReply = ({ setReplyActive, statement, area, thisEditStatement }) => {
    
  const { userid, authToken } = useUserAuthSelector(
      state => state.userAuthReducer
    );
  
    const { addStatement, addUDHRStatement, loadStatements, editStatement, editUDHRStatement, udhr_statements } = useContext(GlobalContext);

  let addStatementState = null;
  // let editStatementState = null;
  console.log("thisEditStatement", thisEditStatement);
  const editStatementState = thisEditStatement;

  // set state to update
  // switch (area) {
  //     case AREA_UDHR:
  //         addStatementState = addUDHRStatement;
  //         editStatementState = editUDHRStatement;
  //         break;
  //     default: 
        // console.log("NOT AddReply", AREA_UDHR);
        // console.log("Area", area);
      //   addStatementState = addStatement;
      //   editStatementState = editStatement;         
      // }
  
  const [replyStatement, setStatement] = useState();
  const [err, setErr] = useState("");
  const [errBool, setErrBool] = useState(false);

  const replyInput = React.useRef();

  let history = useHistory();

  const retrieveStatements = () => {
    StatementsDataService.getAll(userid)
    .then(response => {
        loadStatements(response.data);
    })
    .catch(e => {
    console.log(e);
    });   
  };


  const handleReset = e => {
    e.preventDefault();
    setReplyActive(false);
    history.push(homeLink);
  }

  const handleSubmit = e => {
    e.preventDefault();

    const newReply = {
        userid: userid,
        reply_to: statement.id,
        reply: replyStatement,
        created: new Date(Date.now()),
        // created_by: userid,
        last_saved: new Date(Date.now())        
      };          
      
    StatementsDataService.createReply(newReply)
    .then(response => {
      
      const createdReply = response.data;

      const new_replied_tos = [createdReply, ...statement.replied_tos];
      console.log("createdReply", createdReply);
      console.log("new_replied_tos", new_replied_tos);
      
      const newStatement = {
        ...statement,
        replied_tos: new_replied_tos
      }

      console.log("newStatement", newStatement, editStatementState);

      editStatementState(newStatement);

      // retrieveStatements();
      replyInput.current.value = "";
      replyInput.current.focus();

    })
    .catch(error => {
        
      console.log("Error", error.data);

        if (error["response"] !== undefined) {
          console.log(error.response);
        } else {

        console.log("ERROR", error);


        }
    })

    setReplyActive(false);
    // history.push(homeLink);

  };

  return (
    <Fragment>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
            <InputGroup className="mt-2" style = {{width: '100%'}}>
                <FormControl                                            
                    ref={replyInput} 
                    as="textarea" 
                    aria-label="With textarea" 
                    id="replyInput"
                    placeholder="What is your reply?"
                    onChange={(e) => {
                        e.preventDefault();
                        setStatement(e.target.value);
                    }}    
                />
            </InputGroup>
            <div style = {{width: '100%'}} className="text-right pt-2">
                <Button size="sm" type="submit">Reply</Button>
                <Button size="sm" type="reset">Cancel</Button>
            </div>
        </Form>
    </Fragment>
  );
};