export const START_USER_SESSION = "startUserSession";
export const END_USER_SESSION = "endUserSession";
export const SAVE_SETTINGS = "saveSettings";
export const SAVE_SETTINGS_SUCCESS = "saveSettingsSuccess";

export interface UserState {
  username: string | null;
  userid: string | null;
  sessionActive: boolean;
  authToken: string | null;
  loginTime: string | null;
  number_of_logins: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  image: string;
  online: boolean;
}

export interface SettingsInterface {
  image: string;
  online: boolean;
}

export interface StartUserSessionAction {
  type: typeof START_USER_SESSION;
  payload: UserState;
}

export interface EndUserSessionAction {
  type: typeof END_USER_SESSION;
}

interface SaveSettingsInterface {
  type: typeof SAVE_SETTINGS
  settings: Partial<SettingsInterface>
}

export type settingsActionTypes = SaveSettingsInterface