import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";


const initialState = {
  statements: [],
  udhr_statements: [],
  udhr_groups: [],
  user_statements: [],
  learn_statements: [],
  persons: [],
  friends: [],
  friend_requests: [],
  users: [],
  followers: [],
  followees: [],
  blockers: [],
  groups: [],
  group_members: [],
  group_membership_requests: [],
  group_followers: [],
  group_followees: [],
  group_blockers: [],
  group_projects: [],
  projects: [],
  requireFriendsLoad: true,
  isFriendsLoading: false,
  user: {},
  searchString: ""
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  
  const [state, dispatch] = useReducer(AppReducer, initialState);
    
  //                      ********************************  STATEMENTS ****************************************


  function addStatement(statement) {
    dispatch({
      type: "ADD_STATEMENT",
      payload: statement
    });
  }

  
  function editStatement(statement) {
    dispatch({
      type: "EDIT_STATEMENT",
      payload: statement
    });
  }
  
  function removeStatement(id) {
    dispatch({
      type: "REMOVE_STATEMENT",
      payload: id
    });
  }


  function loadStatements(statements) {
    dispatch({
      type: "LOAD_STATEMENTS",
      payload: statements
    });
  }


  //                      ********************************  UDHR Statements ****************************************


function addUDHRStatement(statement) {
  dispatch({
    type: "ADD_UDHR_STATEMENT",
    payload: statement
  });
}

  function editUDHRStatement(statement) {
    dispatch({
      type: "EDIT_UDHR_STATEMENT",
      payload: statement
    });
  }


function removeUDHRStatement(id) {
  dispatch({
    type: "REMOVE_UDHR_STATEMENT",
    payload: id
  });
}


function loadUDHRStatements(statements) {
  dispatch({
    type: "LOAD_UDHR_STATEMENTS",
    payload: statements
  });
}




//     ********************************  USER_STATEMENTS ****************************************


  function addUserStatement(statement) {
    console.log("addStatement", statement);
    dispatch({
      type: "ADD_USER_STATEMENT",
      payload: statement
    });
  }

  
  function editUserStatement(statement) {
    dispatch({
      type: "EDIT_USER_STATEMENT",
      payload: statement
    });
  }
  
  function removeUserStatement(id) {
    dispatch({
      type: "REMOVE_USER_STATEMENT",
      payload: id
    });
  }


  function loadUserStatements(statements) {
    dispatch({
      type: "LOAD_USER_STATEMENTS",
      payload: statements
    });
  }



//     ********************************  LEARN_STATEMENTS ****************************************


function editLearnStatement(statement) {
  dispatch({
    type: "EDIT_LEARN_STATEMENT",
    payload: statement
  });
}

function loadLearnStatements(statements) {
  dispatch({
    type: "LOAD_LEARN_STATEMENTS",
    payload: statements
  });
}


//                      ********************************  PERSONS ****************************************


  function loadPersons(persons) {
    dispatch({
      type: "LOAD_PERSONS",
      payload: persons
    });
  }

  //                      ********************************  FREINDS ****************************************

  function addFriend(friend) {
    console.log("addFriend", friend);
    dispatch({
      type: "ADD_FRIEND",
      payload: friend,
    });
  } 
  
  function removeFriend(id) {
    dispatch({
      type: "REMOVE_FRIEND",
      payload: id
    });
  }
  
  function loadFriends(friends) {
    console.log("loadFriends", friends);
    dispatch({
      type: "LOAD_FRIENDS",
      payload: friends
    });
  }



//                      ********************************  USERS ****************************************

// State used for search

function loadUsers(users) {
  dispatch({
    type: "LOAD_USERS",
    payload: users
  });
}

// state for the active user
function loadUser(user) {
  dispatch({
    type: "LOAD_USER",
    payload: user
  });
}



  //                      ********************************  FOLLOWERS ****************************************

  function addFollower(follower, stateObj) {
    dispatch({
      type: "ADD_FOLLOWER",
      payload: follower,
      stateObj: stateObj
    });
  }

  function removeFollower(follower) {
    dispatch({
      type: "REMOVE_FOLLOWER",
      payload: follower
    });
  }

  
  function loadFollowers(followers) {
    console.log("loadFollowers", followers);
    dispatch({
      type: "LOAD_FOLLOWERS",
      payload: followers
    });
  }


  //                      ********************************  FOLLOWEE ****************************************

  function addFollowee(followee) {
    console.log("addFollowee", followee);
    dispatch({
      type: "ADD_FOLLOWEE",
      payload: followee
    });
  }

  function removeFollowee(followee) {
    dispatch({
      type: "REMOVE_FOLLOWEE",
      payload: followee
    });
  }

  function loadFollowees(followees) {
    console.log("loadFollowees", followees);
    dispatch({
      type: "LOAD_FOLLOWEES",
      payload: followees
    });
  }

  //                      ********************************  BLOCKS ****************************************


  function block(blockee) {
    // blockee = user to be blocked
    console.log("block", blockee);
    dispatch({
      type: "BLOCK_USER",
      payload: blockee
    });
  }

  function unblock(id) {
    dispatch({
      type: "UNBLOCK_USER",
      payload: id
    });
  }

  function loadBlockers(blockers) {
    dispatch({
      type: "LOAD_BLOCKERS",
      payload: blockers
    });
  }


  //                      ********************************  GROUPS ****************************************


  function addGroup(group) {
    console.log("addgroup", group);
    dispatch({
      type: "ADD_GROUP",
      payload: group
    });
  }

  
  function editGroup(group) {
    dispatch({
      type: "EDIT_GROUP",
      payload: group
    });
  }
  
  function removeGroup(id) {
    dispatch({
      type: "REMOVE_GROUP",
      payload: id
    });
  }


  function loadGroups(groups) {
    console.log("Groups", groups)
    dispatch({
      type: "LOAD_GROUPS",
      payload: groups
    });
  }



  //                      ********************************  UDHR GROUPS ****************************************

  function loadUdhrGroups(groups) {
    console.log("UDHRGroups", groups)
    dispatch({
      type: "LOAD_UDHR_GROUPS",
      payload: groups
    });
  }



  //                      ********************************  GROUP MEMBERS ****************************************


  function addGroupMember(member) {
    console.log("addGroupMember", member);
    dispatch({
      type: "ADD_GROUP_MEMBER",
      payload: member
    });
  } 
  
  function removeGroupMember(id) {
    dispatch({
      type: "REMOVE_GROUP_MEMBER",
      payload: id
    });
  }
  
  function loadGroupMembers(members) {
    console.log("loadGroupMembers", members);
    dispatch({
      type: "LOAD_GROUP_MEMBERS",
      payload: members
    });
  }

  //                      ********************************  GROUP MEMBERSHIP REQUESTS ****************************************
  function loadGroupMembershipRequests(requests) {
    console.log("loadGroupMembershipRequests", requests);
    dispatch({
      type: "LOAD_GROUP_MEMBERSHIP_REQUESTS",
      payload: requests
    });
  }


    //                      ********************************  GROUP FOLLOWERS ****************************************
  
    function addGroupFollower(follower) {
      dispatch({
        type: "ADD_GROUP_FOLLOWER",
        payload: follower
      });
    }
  
    function removeGroupFollower(follower) {
      dispatch({
        type: "REMOVE_GROUP_FOLLOWER",
        payload: follower
      });
    }
  
      function loadGroupFollowers(followers) {
      console.log("loadGroupFollowers", followers);
      dispatch({
        type: "LOAD_GROUP_FOLLOWERS",
        payload: followers
      });
    }
 

  //                      ********************************  GROUP FOLLOWEES ****************************************
  function addGroupFollowee(followee) {
    console.log("addGroupFollowee", followee);
    dispatch({
      type: "ADD_GROUP_FOLLOWEE",
      payload: followee
    });
  }

  function removeGroupFollowee(followee) {
    dispatch({
      type: "REMOVE_GROUP_FOLLOWEE",
      payload: followee
    });
  }

function loadGroupFollowees(followees) {
    console.log("ladGroupFollowees", followees);
    dispatch({
      type: "LOAD_GROUP_FOLLOWEES",
      payload: followees
    });
  }
 

    //                      ********************************  GROUP BLOCKERS ****************************************
    function loadGroupBlockers(blockers) {
      console.log("loadGroupBlockers", blockers);
      dispatch({
        type: "LOAD_GROUP_BLOCKERS",
        payload: blockers
      });
    }

    function blockGroup(blockee) {
      // blockee = user to be blocked
      console.log("block Group", blockee);
      dispatch({
        type: "BLOCK_GROUP",
        payload: blockee
      });
    }
  
    function unblockGroup(blockee) {
      console.log("unblock Group", blockee);
      dispatch({
        type: "UNBLOCK_GROUP",
        payload: blockee
      });
    }
  

  //                      ********************************  GROUP PROJECTS ****************************************

  function loadGroupProjects(projects) {
    console.log("loadGroupProjects", projects);
    dispatch({
      type: "LOAD_GROUP_PROJECTS",
      payload: projects
    });
  }


  //                      ********************************  SEARCH_STRING ****************************************

  function setSearchString(searchString) {
    console.log("setSearchString", searchString);
    dispatch({
      type: "SET_SEARCH_STRING",
      payload: searchString
    });
  }


  return (
    <GlobalContext.Provider
      value={{
        statements: state.statements,
        udhr_statements: state.udhr_statements,
        user_statements: state.user_statements,
        learn_statements: state.learn_statements,
        persons: state.persons,
        friends: state.friends,
        friend_requests: state.friend_requests,
        users: state.users,
        followers: state.followers,
        followees: state.followees,
        blockers: state.blockers,
        requireFriendsLoad: state.requireFriendsLoad,
        isFriendsLoading: state.isFriendsLoading,
        user: state.user,
        udhr_groups: state.udhr_groups,
        groups: state.groups,
        group_members: state.group_members,
        group_membership_requests: state.group_membership_requests,
        group_followers: state.group_followers,
        group_followees: state.group_followees,
        group_blockers: state.group_blockers,
        group_projects: state.group_projects,
        searchString: state.searchString,
        addStatement,
        editStatement,
        removeStatement,
        loadStatements,
        addUDHRStatement,
        editUDHRStatement,
        removeUDHRStatement,
        loadUDHRStatements,
        addUserStatement,
        editUserStatement,
        removeUserStatement,
        loadUserStatements,
        loadLearnStatements,
        editLearnStatement,
        addFriend,
        removeFriend,
        loadFriends,
        loadPersons,
        loadUsers,
        loadUser,
        loadFollowers,
        addFollower,
        removeFollower,
        addFollowee,
        removeFollowee,
        loadFollowees,
        loadBlockers,
        block,
        unblock,
        loadUdhrGroups,
        addGroup,
        editGroup,
        removeGroup,
        loadGroups,
        addGroupMember,
        removeGroupMember,
        loadGroupMembers,
        loadGroupMembershipRequests,
        addGroupFollower,
        removeGroupFollower,
        loadGroupFollowers,
        addGroupFollowee,
        removeGroupFollowee,
        loadGroupFollowees,
        blockGroup,
        unblockGroup,
        loadGroupBlockers,
        loadGroupProjects,
        setSearchString
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};