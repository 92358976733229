import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import {API_PROFILE_URL, API_URL, API_MEDIA_URL, API_STATIC} from "../../constants/apiUrl";
import { Container, Col, Row, Card, Button, Image } from "react-bootstrap";
import { ShowOnlyWhenLoggedIn } from '../utils/elementWrappers/authBasedVisibilityWrappers'
import { useUserAuthSelector, useUserProfileSelector } from "../../state/typedSelectors";
import {homeLink} from '../views/contentPageLinks'
import FriendsDataService from "../../../services/FriendsDataService";
import FollowersDataService from "../../../services/FollowersDataService";
import ReactRoundedImage from "react-rounded-image";
import styled from "styled-components";


const ButtonToggle = styled(Button)`
  opasity: 0.6rem;
${props => props.active && ` 
  opasity: 1.0rem;
  `}
`;

const stickStyle = {
  fontSize: "0.9rem"
}

const FollowingStick = ({user, isFollowers}, { match, location }) => {

  const [blocked, setBlocked] = useState(false);
  const [followed, setFollowed] = useState(true);

  const { authToken } = useUserAuthSelector(
     state => state.userAuthReducer
   );    
    
  let history = useHistory();

  
  const unFollow = (e) => {

      FollowersDataService.remove(user.pk)      
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setFollowed(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  };
  
  const block = (e) => {

  FriendsDataService.block(user.pk)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setBlocked(true);
    })
    .catch(function (error) {
      console.log(error);
    });

  };


const view = (e) => {

  let user_id = e.target.dataset.pk;
  history.push('/user/'+user_id);
  
};

  const uName = (user.first_name) ? 
    (<div>{user.first_name} {user.last_name}</div>):
    (<div>{user.username}</div>)

  const uStatus = (user.status) ? 
  (<Card.Text className="m-0">Status: {user.status}</Card.Text>): null

  const uLivingIn = (user.living_in) ? 
    (<Card.Text className="m-0">Living in: {user.living_in}</Card.Text>): null

  const uCity = (user.city) ? 
    (<Card.Text className="m-0">City: {user.city}</Card.Text>): null

    return (

<React.Fragment>                    

        <Card className="mb-2 p-1 border border-light-dark">

          <Row className="d-block d-flex flex-row">
            <Col sm={1} style={stickStyle}>
              <ReactRoundedImage 
                image={API_STATIC+user.image} 
                imageWidth="42"
                imageHeight="42"
                roundedSize="0"
              />
            </Col>
            <Col  md="auto" style={stickStyle}>
                  {uName}
            </Col>


            <Col sm={3} style={stickStyle}>
                  {user.city}, {user.living_in}
            </Col>

            {
              (isFollowers) ? 
              (
                <Col sm={4} style={{marginLeft: 'auto'}} className="d-flex justify-content-center align-items-center">

                <Button 
                      variant={(!blocked) ? "primary" : "danger"}
                      id={"followingstick-mute-btn-"+user.pk}
                      size="sm" 
                      className="ml-1" 
                      data-pk={user.pk}
                      onClick={block}>
                      {(!blocked) ? "Block" : "Blocked"}
                  </Button>


                  <Button id="userstick-follow-btn" size="sm" className="ml-1" data-pk={user.pk} onClick={view}>View</Button>
                </Col>

              ) : 
              (
                <Col sm={4} style={{marginLeft: 'auto'}} className="d-flex justify-content-center align-items-center">
                  
                <Button 
                      variant={(followed) ? "primary" : "danger"}
                      id={"followingstick-unFollow-btn-"+user.pk}
                      size="sm" 
                      className="ml-1" 
                      data-pk={user.pk}
                      onClick={unFollow}>
                      {(followed) ? "Unfollow" : "Removed"}
                  </Button>
                 
                  <Button id="userstick-follow-btn" size="sm" className="ml-1" data-pk={user.pk} onClick={view}>View</Button>
                </Col>

              )

            }


          </Row>
        </Card>
      </React.Fragment>

    );
  };

export default FollowingStick;