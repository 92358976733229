import React, { useState, useContext, useEffect } from "react";
import * as Io from "react-icons/io";
import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { useUserAuthSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { GlobalContext } from "../../state/GlobalState";
import LoginModal from '../views/userAccess/login/LoginModal';
import { AREA_UDHR } from "../../constants/GlobalConstants";


export default function Likes( {user_id, statement, udhr, area} ) {
        
    
    const [show, setShow] = useState(false);
    const [liked, setLiked] = useState(false);
    const [reaction, setReaction] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const { userid, sessionActive }= useUserAuthSelector(
        state => state.userAuthReducer
      );
    
    const { editStatement, editUDHRStatement } = useContext(GlobalContext);

    let statementsState = null;

    // set state to update
    switch (area) {
        case AREA_UDHR:
            statementsState = editUDHRStatement;
            break;
        default: statementsState = editStatement;
        }
    
    // reject Like if the statement belongs to the user
    const usersOwnStateMent = (statement.user.pk === userid) ? true : false;
    // console.log(usersOwnStateMent);

    // 
    var found = [];


    // if (statement.reactions.length > 0) {
    //     found = statement.reactions.find(function (reaction) {                
    //         if (reaction.created_by === user_id) {
    //             return reaction;
    //         } else {
    //             return [];
    //         }
    //     // setReaction(found);
    //     });
    // };
    


    useEffect(() => {
        //  if (statement.reactions.length > 0) {
        let x = 1;
        found = statement.reactions.find(function (reaction) { 
            // console.log("rec NO: ", x, statement.reactions.length)
            x = x+1;
            if ((reaction.created_by === user_id) && (reaction.reaction === 1)) {
                // if (reaction.created_by === user_id) {
                    // console.log("record NO: ", x)
                    setLiked(true);
                    setReaction(reaction);
                    // console.log("liked set");
                    return reaction;
                } else {
                    setReaction(reaction);
                    // console.log("No reaction set", statement.reactions);
                 }
            // setReaction(found);
             });
        //  };
        
        return () => {
            
        }
    }, [statement])
   
    // setLiked(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const handleLike = (e) => { 


        if (!sessionActive) {
            setShowLoginModal(true);  
        } else {

        console.log("handleLIke")
        
        // let thisLiked = false;
        
        if (usersOwnStateMent) {

            // Is this statement belonging to the User?
            // If so - do nothing;

        } else {
                
                
            // This is statement, does not belong to the user - ok to change it.
                // let BigID = "";

                // if (reaction.length === 0) {
                //     console.log("No existing like on this statement, reaction_set is empty. Craete a Like Reaction");
                //     // thisLiked = false; // Just to make sure, since we know the state - before heart clicked.
                //     setLiked(false);
                //     // console.log("thisLiked", thisLiked);
                // } else {
                //     console.log("Already liked by this user. A reaction is present, and must now be deleted")
                //     console.log("REACTION", reaction);
                //     // thisLiked = true;
                //     BigID = reaction.id

                // }

                    const statementId = parseInt(e.currentTarget.dataset.id);
                    const currentNoLikes = parseInt(e.currentTarget.dataset.likes_count);
                    let newNoLikes = (liked) ? currentNoLikes-1: currentNoLikes+1;
                    let currentReactions = statement.reactions;
                    

                    //variable to hold function
                    let updateLike = ""
                    let deletedReactionId = null;

                    if (liked) {
                        
                        // 1. DELETE "LIKE" reaction from statement.reactions
                        
                        // 1.1 set reaction to be deleted. Neded for update of state later. 
                        deletedReactionId = reaction.id

                        // 1.2 set function and parameters to use when backend is updated.
                        updateLike = StatementsDataService.deleteReaction(deletedReactionId);

                    } else {
                        
                        // 2. CREATE "LIKE" reaction and update backend
                        
                        // 2.1 Configure new reaction

                        const newReaction = {
                            userid: userid,
                            created_by: userid,
                            reaction_to: e.currentTarget.dataset.id,
                            reaction: 1
                        };                         
                            
                        // 2.2 Set function and paramters to use for updating backend.

                        updateLike = StatementsDataService.createReaction(newReaction);
                        console.log("liked should be false", liked)
                    }
                            
                    updateLike
                        .then(response => {
                            const statementUpdatedNoOfLikes = {likes_count: newNoLikes};
                            
                            // set variable for the reaction
                            // either "" if deleted, or new reaction (or error)
                            let newReaction = response.data
                            

                            if (newReaction === "") {
                                // IF DELETED REACTION:
                                currentReactions = currentReactions.filter(
                                       reaction => reaction.id !== deletedReactionId
                                   )
                                } else {
                                   // IF ADDED REACTION
                                   currentReactions = [newReaction, ...currentReactions];
                               }
   


                            // TODO: Update number of likes ons statement - to be set on backend
                            StatementsDataService.update(statementId, statementUpdatedNoOfLikes)
                            .then(response => {
                                const newLikedStatus=!liked;
                                setLiked(newLikedStatus);

                                const newStatement = {
                                    ...statement,
                                    likes_count: newNoLikes,
                                    reactions: currentReactions
                                }

                                
                                // switch (area) {
                                //     case AREA_UDHR:
                                //         editUDHRStatement();
                                // }
                                                
                                 statementsState(newStatement);

                                // console.log("statements retrieved");

                                })   
                            .catch(error => {
                                if (error["response"] !== undefined) {
                                    console.log(error.response);
                                } else {
                                }
                                });
                        })
                        .catch(error => {
                        if (error["response"] !== undefined) {
                            console.log(error.response);
                        } else {
                        }
                        })
                    }
                }
    }

    // const retrieveStatements = () => {
        
    //     let getStatements = ""


    //     if (area === AREA_UDHR) {
    //         console.log("getAllToUDHR - FANTEFAR");
    //         getStatements = StatementsDataService.getAllToUDHR(udhr);
    //     } else {
    //         getStatements = StatementsDataService.getAll(userid);
    //     }
        
    //     getStatements
    //     .then(response => {
            
    //         if (area === AREA_UDHR) {
    //             console.log("getAllToUDHR", response.data );
    //             loadUDHRStatements(response.data)
    //         } else {
    //             loadStatements(response.data);
    //         }
    //         // const res= StatementsDataService.getAllToUDHR(udhr);
    //     })
    //     .catch(e => {
    //     console.log(e);
    //     });   
    // };
    
    return (
        <>
            {/* Prevent the user from liking a statement until logged in */}
            {(showLoginModal) ? (<LoginModal show={showLoginModal} setShowLoginModal={setShowLoginModal} />) : null}

            <div id="statement-like-wrapper-div" className="d-flex flex-column align-items-center w-100">
                <div id="statement-like-heart-div" className="text-center" data-id={statement.id} data-likes_count={statement.likes_count} data-statement={statement} onClick={handleLike}>                                                                                                           
                    {
                        
                        (liked) ? 
                            (<IconWrapper Color="#F93154" Icon={Io.IoIosHeart} Size={"3rem"} />) :
                            (<IconWrapper Color="#F93154" Icon={Io.IoIosHeartEmpty} Size={"3rem"} />)

                        }
                        
                         {/* (statement.reactions.length < 1) ? 
                                (<IconWrapper Color="#F93154" Icon={Io.IoIosHeartEmpty} Size={"3rem"} AddMargin={false} />) :            
                                
                                ( statement.reactions.find(reaction => ((reaction.created_by === user_id) && (reaction.reaction === 1) ) ) ? 
                                 ( statement.reactions.find(reaction => (reaction.created_by === user_id)) ?  

                                    (<IconWrapper Color="#F93154" Icon={Io.IoIosHeart} Size={"3rem"} />) :

                                    (<IconWrapper Color="#F93154" Icon={Io.IoIosHeartEmpty} Size={"3rem"} />)
                                ) */}
                    
                </div>
                <div id="statement-like-numOfLikes-div" className="text-center mr-0">
                    {(statement.likes_count > 0) ? (<div>{statement.likes_count}</div>) : null}
                </div>
            </div>
        </>
    );
  }
