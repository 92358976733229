import React, { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../state/GlobalState";
import StatementsDataService from "../../../services/StatementsDataService";
import { useUserAuthSelector, useUserSettingsSelector } from "../../state/typedSelectors";
import { Nav, ListGroup, Image, Card, Dropdown, DropdownButton, Button, Row, Col } from "react-bootstrap";
import Statement from './Statement';
import Reply from './Reply';
import Share from './Share';
import {timeSince} from '../utils/dateTime'
import { Link, Route, Switch } from "react-router-dom";
import { AddStatement } from '../statements/addStatement';
import { AddReply } from '../statements/addReply';
import { EditStatement } from './EditStatement';
import * as Io from "react-icons/io";
import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { useRouteMatch } from "react-router-dom";
import Udhr from "../udhr/Udhr";
import ReactRoundedImage from "react-rounded-image";
import LoginModal from '../views/userAccess/login/LoginModal'
import {AREA_PUBLIC, AREA_PRIVATE, AREA_UDHR} from '../../constants/GlobalConstants'

export const Statements = ({area, identifier, user_id, objectState}) => {

    // state for log in- modal
    const [showLoginModal, setShowLoginModal] = useState(false);

    const { udhr_statements, statements, user_statements, removeStatement, loadStatements, editStatement, loadUDHRStatements, loadUserStatements } = useContext(GlobalContext);
    
    const { userid, sessionActive }= useUserAuthSelector(
        state => state.userAuthReducer
      );

    // Encourage users to log in and engage by delaying functions.
    let disableFunctions = false;

    if (area === AREA_PUBLIC ) disableFunctions = true;
    if (area === AREA_UDHR ) disableFunctions = true;
    
    if (!user_id) {
        user_id = userid;
    } 
    

    const [isReplyActive, setReplyActive] = useState(false);
    const [isEditActive, setEditActive] = useState(false);
    const [isShareActive, setShareActive] = useState(false);
    const [currentStatement, setCurrentStatement] = useState(null);
    const [likes, setLikes] = useState(null);
    const [liked, setLiked] = useState(false);
    const [reaction, setReaction] = useState(0);

    useEffect(() => {
        // retrieveStatements();
        // loadStatements();
      }, []);

           
    const retrieveStatements = () => {
        
            let getStatements = null;

            if (sessionActive) {

            switch (area) {
                
                case AREA_PUBLIC: 
                    getStatements = StatementsDataService.getAllToArea(AREA_PUBLIC);
                    break;
                case AREA_UDHR:
                    getStatements = StatementsDataService.getAllToUDHR(identifier);
                    break;
                case AREA_PRIVATE:
                    getStatements = StatementsDataService.getAll(user_id);
                    break;
                
                default:

            }
            
            getStatements
            .then(response => {                
                switch (objectState) {
                    case "common":
                        loadStatements(response.data);
                        break;
                    case "user": 
                        loadUserStatements(response.data);
                        break;
                    default: 
                        break;
                }
            })
            .catch(e => {
                console.log("Hvorfor er vi her nå?", e);
            });
        }   
    };



    // if (!statements) return null; //<p>No statements, sorry</p>;

    if (!statements || statements.length === 0) return null; //<p>No statements, sorry</p>;
    console.log("STATEMENTS", statements);

    return (
        <Fragment>     

        {statements.length > 0 ? (
          
            <Col sm={12} md={10} className="p-0 d-flex justify-content-center">
                <ListGroup id={'list-group'} as="ul" variant="flush" className="p-0 w-100 justify-content-center">
                    
                    {statements.map(statement => (
                        <Statement key={statement.id+area} statement={statement} area={area} thisEditStatement={editStatement}/>
                    ))}
                </ListGroup>
            </Col>
        ) : (
          <p>No data</p>
        )}
      </Fragment>
    );
  };