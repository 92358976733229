import React, { Fragment, useState, useContext } from "react";
import { GlobalContext } from "../../state/GlobalState";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { useUserAuthSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { InputGroup, Form, Modal, FormControl, Button, Dropdown, DropdownButton, Col, Row} from "react-bootstrap";
import UploadPhoto from "../photos/UploadPhoto"
import uniqueId from 'lodash'

export const AddStatement = ({ udhr, objectState, user_id }) => {
      
  const { userid, authToken } = useUserAuthSelector(
        state => state.userAuthReducer
      );
    
    const { loadStatements, loadUserStatements, addStatement, editStatement, statements } = useContext(GlobalContext);

    const [formData, setFormData] = useState(
      {
        plain: false
      }
    );

    
  const local_user_id = (user_id) ?  user_id : userid;

  const [statement, setStatement] = useState();
  const [err, setErr] = useState("");
  const [errBool, setErrBool] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [category, setCategory] = useState("plain");

  const [value,setValue]=useState('');

  const commentInput = React.useRef();

  const [ id ] = useState(uniqueId('myprefix-'))
  
  const placeholderText = (udhr) ? "Comment this article. It will be visible on your wall."  : "What is on your mind?"
  


  let history = useHistory();

  const retrieveStatements = () => {

    console.log("USERID", local_user_id);
    
    StatementsDataService.getAll(local_user_id)
    .then(response => {
      console.log("getAll", response.data);
      console.log("OBJECTSTATE", objectState);
      switch (objectState) {
          case "common":
              console.log("addStatement, common")
              loadStatements(response.data);
              break;
          case "user": 
              console.log("addStatement, common")
              loadUserStatements(response.data);
              break;
          default: 
              break;
      }
    })
    .catch(e => {
      console.log("Hvorfor er vi her nå?", e);
    });
  };
  
  const handleClick = (e) => {
    // console.log("handleClick", e);
    // TODO: Capture click on the button, but left of the dropdown to initiate Default publishingarea for the statement.
  }
  
  const handleSelect = (e) => {
    // console.log("handleSelect", e);

    setValue(e);

    console.log("category", category);

    const newStatement = {
        statement: statement,
        category: (checkboxChecked) ? 10 : 1,
        user: local_user_id, //Differentiate between statement from user and other users homepage
        created: new Date(Date.now()),
        created_by: userid,  
        last_saved_by: userid,
        likes: 0,
        edited: false,
        post_to: e, 
        statement_to_udhr: (udhr) ? udhr : -1,
        replied_tos: [], // must be set for Statement to work - should set default value - or errorcheck
        reactions: [] // must be set for Likes to work - should set default value or errorcheck
      };          
    
    StatementsDataService.create(userid, newStatement)
    .then(response => {
          // retrieveStatements();
          // retrieveStatements(); //Get data
              console.log("ADD_STATEMENT",  response.data);                            
              StatementsDataService.getStatement(response.data.id)
              .then(response => {
                      console.log("addedStatement", response.data);
                      addStatement(response.data);
                      // Empty statementbox and regain focus
                      commentInput.current.value = "";
                      commentInput.current.focus();
              }
            )
            .catch(error => {
                if (error["response"] !== undefined) {
                    console.log(error.response);
                }
              }
            );
        })
      .catch(error => {
              if (error["response"] !== undefined) {
                console.log(error.response);
              }
          }
      );
    // history.push(homeLink);
  };


const categories = (
  
  
  <Form.Group controlId="CATEGORIES-GROUP" className="pt-2">
  
  <div className="d-flex m-0">   
    
  <InputGroup size="sm">
    
    <label 
      htmlFor={"CATEGORIES"}
      id={"CATEGORIES-label"} 
      style={{fontSize: "0.9rem"}}  
      className="p-0"
      >
        Learn:
    </label> 
    
    <Form.Check className="ml-1"
      name={"CATEGORIES"}
      id={"CATEGORIES-check"}
      type="checkbox"
      checked={checkboxChecked}
      onChange={(e) => 
        {
          
          console.log(e.target.checked);
          setCheckboxChecked(e.target.checked);

        }}

      />
    </InputGroup>
{/* 
    <InputGroup size="sm">
    
    <label 
      htmlFor={"CATEGORIES"}
      id={"CATEGORIES-label"} 
      style={{fontSize: "0.9rem"}}  
      className="p-0"
      >
        Shared:
    </label> 
    
    <Form.Check className="ml-1"
      name={"CATEGORIES"}
      type="checkbox"
      checked={formData.legalAgreement}
      
      onChange={(e) => 
        {
          console.log(e.target.checked);
          setCategory(e.target.checked);
        }}



      />
    </InputGroup>
 */}


  </div>
  
  </Form.Group>);


  return (
    <React.Fragment>
      
      <Col xs={12} md={12} className="p-0 d-flex flex-row justify-content-center">
        
        <Form  style={{width: "90%"}} >
        
        <Row className="d-flex flex-row justify-content-center">

          <Col xs={12} className="px-0 d-flex flex-row justify-content-center">
        
          <InputGroup>

                <FormControl                                          
                    className="self-align-center"
                    ref={commentInput} 
                    as="textarea" 
                    aria-label="With textarea"
                    rows={5} cols={4}
                    id={new Date().getTime()}
                    placeholder={placeholderText}
                    onChange={(e) => {
                        e.preventDefault();
                        setStatement(e.target.value);
                    }}    
                />
           </InputGroup>

           </Col>

           </Row>

           <Row >
            
           <Col xs={12} className="px-0 d-flex flex-row justify-content-end">
           
            {/* <div className="text-right pt-2">


                  <Button
                      alignRight
                      title="Add picture"
                      id="dropdown-button-menu"
                      size="sm"       
                  >
                  Add picture
                  </Button>
               

              </div> */}

              {/* <div className="text-right pt-2">
                  <Button
                      alignRight
                      title="Add picture"
                      id="dropdown-button-menu"
                      size="sm"       
                  >
                  Add video
                  </Button>
              </div> */}

              {(!!udhr) ? (
              categories
              )  : null }

              <div className="text-right mt-2">
                  <DropdownButton
                      alignRight
                      title="Publish"
                      id={new Date().getTime().toString()+"dropdown-button"}
                      size="sm"       
                      onClick={handleClick}          
                      onSelect={handleSelect}         
                  >
                      <Dropdown.Item eventKey="1">Private</Dropdown.Item>
                      <Dropdown.Item eventKey="5">Public</Dropdown.Item>
                      <Dropdown.Item eventKey="2">Friends</Dropdown.Item>
                      
                  </DropdownButton>
              </div>


            </Col>
            </Row>

        </Form>
        </Col>
    </React.Fragment>
  );
};