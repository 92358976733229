import * as userTypes from "./userProfileTypes";
import { useUserProfileSelector } from "../../state/typedSelectors";

export const initialState: userTypes.UserProfileState = {
  username: null,
  ufirst_name: null,
  ulast_name: null,
  uemail: null,
  gar_avatar_name: null,
  image: null,
  last_seen: null,
  online: null,
  created: null,
  status: null,
  date_of_birth: null,
  born_in: null,
  living_in: null,
  city: null,
  bio: null,
  last_saved: null,
  profile_completed_percent: null,
  number_of_logins: null,
  education: null,
  education_place: null,
  occupation: null,
  position: null,
  first_login: null,
  homepageViewAllowed: null,
  homepageAddAllowed: null
};

export default (
  previousState: userTypes.UserProfileState = initialState,
  action: userTypes.UpdateProfileAction
): userTypes.UserProfileState => {
  switch (action.type) {
    case userTypes.UPDATE_PROFILE:
      {
        // console.log(action.payload)
      return action.payload;}
    // case userTypes.UPDATE_PROFILE:
        // return action.payload;
    }
  return previousState;
};
