import React from 'react';
import FollowersDataService  from '../../../../services/FollowersDataService';
import FriendsDataService  from '../../../../services/FriendsDataService';
import GroupsDataService from '../../../../services/GroupsDataService';
import { useHistory } from "react-router-dom";

import { 
  ACTION_ADD_FRIENDREQUEST, 
  ACTION_ACCEPT_FRIENDREQUEST,
  ACTION_REJECT_FRIENDREQUEST, 
  ACTION_REMOVE_FRIEND,
  ACTION_ADD_FOLLOWER,
  ACTION_REMOVE_FOLLOWER,
  ACTION_ADD_FOLLOWEE,
  ACTION_REMOVE_FOLLOWEE,
  ACTION_BLOCK, 
  ACTION_UNBLOCK,
  ACTION_ADD_GROUP,
  ACTION_REMOVE_GROUP
} from "../../../constants/stickTypes";


class StickActions {

  view (event, history) {
    // directs the user to a users  public-profile
    let user_id = event.target.dataset.pk;
    history.push('/user/'+user_id);      
    }
    
  action(action, stickObject, set, user_id) {
    // action = axios request to the backend
    // object = primary key of object
    // set = state callback from paren. if the actions is performed then set = true
    
    let actionFunction = null;
    const id = stickObject.pk; // NB! Note the conversion of 'pk' to 'id'
    
    // TODO Should be stickObject.Id for Groups. 'Id' is id of the Group. 'Pk' is id of the user/friend.
    
    console.log("stickObject, stickActions", stickObject);

    var data = new FormData();
    data.append('user_id', id);
    data.append('message', '');
    
    switch (action) {

      case ACTION_ADD_FRIENDREQUEST: {
        actionFunction = FriendsDataService.requestFriendship(data)
        break;
      }
      case ACTION_ACCEPT_FRIENDREQUEST: {
        actionFunction = FriendsDataService.acceptFriendship(id);
        break;
      }
      case ACTION_REJECT_FRIENDREQUEST: {
        actionFunction = FriendsDataService.rejectFriendship(id);
        break;
      }
      case ACTION_REMOVE_FRIEND: {
        actionFunction = FriendsDataService.remove(id);
        break;
      }

      case ACTION_ADD_FOLLOWEE: {
        console.log("addFollowee")
        actionFunction = FollowersDataService.addFollowee(data);
        break;
      }

      case ACTION_REMOVE_FOLLOWEE: {
        actionFunction = FollowersDataService.removeFollowee(id);
        break;
      }


      case ACTION_ADD_FOLLOWER: {
        actionFunction = FollowersDataService.addFollower(data);
        break;
      }

      case ACTION_REMOVE_FOLLOWER: {
        actionFunction = FollowersDataService.removeFollower(id);
        break;
      }


      case ACTION_BLOCK: {
        console.log("stickObject, ACTION_BLOCK", stickObject)
        
        // id = den som skal blokkeres, user = den som blokkerer  user eller gruppe eller prosjekt
        // får userid eller pk fra state hhv friends of groups

        actionFunction = FriendsDataService.block(id, user_id);
        break;
      }

      case ACTION_UNBLOCK: {
        actionFunction = FriendsDataService.unblock(id, user_id);
        break;
      }

      case ACTION_REMOVE_GROUP: {
        actionFunction = GroupsDataService.remove(stickObject.id);
        break;
      }

    }
     
    
    // Perform the action
    actionFunction  
    .then(function (response) {
      
      // Set the state of the StickButton
      set(true);
      
    })
      .catch(function (error) {
      console.log(error);
    });

  }
}

export default new StickActions();