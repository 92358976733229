import React, { useState, useContext, useEffect } from "react";
import * as Io from "react-icons/io";
import IconWrapper from "../utils/elementWrappers/IconWrapper";
import { useUserAuthSelector } from "../../state/typedSelectors";
import StatementsDataService from "../../../services/StatementsDataService";
import { GlobalContext } from "../../state/GlobalState";
import LoginModal from '../views/userAccess/login/LoginModal';
import { AREA_UDHR } from "../../constants/GlobalConstants";


export default function Favourite( {user_id, statement, udhr, area} ) {
        
    
    const [show, setShow] = useState(false);
    const [favourite, setFavourite] = useState(false);
    const [reaction, setReaction] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const { userid, sessionActive }= useUserAuthSelector(
        state => state.userAuthReducer
      );
    
    const { editStatement, editUDHRStatement } = useContext(GlobalContext);

    let statementsState = null;

    // set state to update
    switch (area) {
        case AREA_UDHR:
            statementsState = editUDHRStatement;
            break;
        default: statementsState = editStatement;
        }
    
    // reject Like if the statement belongs to the user
    const usersOwnStateMent = (statement.user.pk === userid) ? true : false;
    // console.log(usersOwnStateMent);

    // 
    var found = [];


    // if (statement.reactions.length > 0) {
    //     found = statement.reactions.find(function (reaction) {                
    //         if (reaction.created_by === user_id) {
    //             return reaction;
    //         } else {
    //             return [];
    //         }
    //     // setReaction(found);
    //     });
    // };
    


    useEffect(() => {
        //  if (statement.reactions.length > 0) {
            
        found = statement.reactions.find(function (reaction) {                
                if ((reaction.created_by === user_id) && (reaction.reaction === 10)) {
                    // setLiked(true);
                    setFavourite(true);
                    setReaction(reaction);
                    // console.log("Reaction set", reaction);
                    return reaction;
                } else {
                    setReaction(reaction);
                    // console.log("No reaction set", statement.reactions);
                 }

                //else {
                //     return [];
                // }
            // setReaction(found);
             });
        //  };
        
        return () => {
            
        }
    }, [statement])
   
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const handleLike = (e) => { 


        if (!sessionActive) {
            setShowLoginModal(true);  
        } else {

        console.log("handleLIke")
        
        // let thisLiked = false;
        
        if (usersOwnStateMent) {

            // Is this statement belonging to the User?
            // If so - do nothing;

        } else {
                
                
            // This is statement, does not belong to the user - ok to change it.
                // let BigID = "";

                // if (reaction.length === 0) {
                //     console.log("No existing like on this statement, reaction_set is empty. Craete a Like Reaction");
                //     // thisLiked = false; // Just to make sure, since we know the state - before heart clicked.
                //     setLiked(false);
                //     // console.log("thisLiked", thisLiked);
                // } else {
                //     console.log("Already liked by this user. A reaction is present, and must now be deleted")
                //     console.log("REACTION", reaction);
                //     // thisLiked = true;
                //     BigID = reaction.id

                // }

                    const statementId = parseInt(e.currentTarget.dataset.id);
                    const currentNoLikes = parseInt(e.currentTarget.dataset.likes_count);


                    // let newNoLikes = currentNoLikes;
                    let currentReactions = statement.reactions;

                    // console.log(statementId, currentNoLikes, newNoLikes);
                    console.log("currentReactions", currentReactions);
                    // let newNoLikes = (liked) ? currentNoLikes-1: currentNoLikes+1;
                    

                    //variable to hold function
                    let updateLike = ""
                    let deletedReactionId = null;

                    if (reaction.reaction === 10) {
                        
                        // console.log("Am I here?", reaction)
                        deletedReactionId = reaction.id
                        updateLike = StatementsDataService.deleteReaction(deletedReactionId);

                      
                    } else {
                        
                        const newReaction = {
                            userid: userid,
                            created_by: userid,
                            reaction_to: e.currentTarget.dataset.id,
                            reaction: 10
                            };                         
                            
                        updateLike = StatementsDataService.createReaction(newReaction);
                        // console.log("liked should be false", liked)
                        // setLiked(true);
                    }
                            
                    updateLike
                        .then(response => {
                            // const statementUpdatedNoOfLikes = {likes_count: newNoLikes};                            
                            let newReaction = response.data;

                        if (newReaction === "") {
                             // IF DELETED REACTION:
                             currentReactions = currentReactions.filter(
                                    reaction => reaction.id !== deletedReactionId
                                )
                             } else {
                                // IF ADDED REACTION
                                currentReactions = [newReaction, ...currentReactions];
                            }

                            console.log("currentReactions", currentReactions);

                            console.log("newReactions", currentReactions);
                            // console.log("newReactions", newReaction);


                            setReaction(newReaction);

                            let newReactions = currentReactions;
                            
                            // TODO SHortcut to update only current number of likes, not all replies
                            // Need to update statement reations.
                            //StatementsDataService.update(statementId, statementUpdatedNoOfLikes)
                            //.then(response => {
                                const newFavouriteStatus=!favourite;
                                setFavourite(newFavouriteStatus);

                                const newStatement = {
                                    ...statement,
                                    // likes_count: newNoLikes,
                                    reactions: currentReactions
                                }

                                
                                // switch (area) {
                                //     case AREA_UDHR:
                                //         editUDHRStatement();
                                // }
                                                
                                statementsState(newStatement);

                                console.log("statements retrieved");

                            //     })   
                            // .catch(error => {
                            //     if (error["response"] !== undefined) {
                            //         console.log(error.response);
                            //     } else {
                            //     }
                            //     });
                        })
                        .catch(error => {
                        if (error["response"] !== undefined) {
                            console.log(error.response);
                        } else {
                        }
                        })
                    }
                }
    }

    
    return (
        <>
            {/* Prevent the user from liking a statement until logged in */}
            {(showLoginModal) ? (<LoginModal show={showLoginModal} setShowLoginModal={setShowLoginModal} />) : null}

            <div id="statement-like-wrapper-div" className="d-flex flex-column align-items-center w-100">
                <div id="statement-like-heart-div" className="text-center" data-id={statement.id} data-likes_count={statement.likes_count} data-statement={statement} onClick={handleLike}>                                                                                                           
                    
                    
                {
                        
                        (favourite) ? 
                            (<IconWrapper Color="#FFF700" Icon={Io.IoIosStar} Size={"2rem"} />) :
                            (<IconWrapper Color="#FFF700" Icon={Io.IoIosStarOutline} Size={"2rem"} />)

                        }
                    
                    {/* {
                        (statement.reactions.length < 1) ? 
                                (<IconWrapper Color="#FFF700" Icon={Io.IoIosStarOutline} Size={"2rem"} AddMargin={false} />) :            
                                
                                ( statement.reactions.find(reaction => ((reaction.created_by === user_id) && (reaction.reaction === 10) ) ) ? 

                                    (<IconWrapper Color="#FFF700" Icon={Io.IoIosStar} Size={"2rem"} />) :

                                    (<IconWrapper Color="#FFF700" Icon={Io.IoIosStarOutline} Size={"2rem"} />)
                                )
                    } 
                     */}

                </div>
{/* 
                Not neccesary for Favourotes. Only one.

                <div id="statement-like-numOfLikes-div" className="text-center mr-0">
                    {(statement.likes_count > 0) ? (<div>{statement.likes_count}</div>) : null}
                </div> */}

            </div>
        </>
    );
  }
