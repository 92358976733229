import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Button, Container, Col, Row, Card, Form, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useUserAuthSelector, useUserProfileSelector, useUserSettingsSelector } from "../../state/typedSelectors";
import { updateUserProfile } from "../../state/userProfileState/userProfileActions";
import { Link } from "react-router-dom";


import { useHistory } from "react-router-dom";
import {homeLink} from '../views/contentPageLinks'
import { GlobalContext } from "../../state/GlobalState";
import UserCloseAccount from './UserCloseAccount';
import CreateGroup from '../groups/CreateGroup';
import DataService from '../../../services/DataService';
import { AREA_FRIENDS, AREA_PUBLIC } from '../../constants/GlobalConstants';

const UserProfileSettings = () => {

  const { username, first_name, last_name, email, userid, authToken} = useUserAuthSelector(
    state => state.userAuthReducer
  );

  const { user } = useContext(GlobalContext);


  const { ufirst_name, ulast_name, uemail, date_of_birth, status, gar_avatar_name, image,
    created, born_in, living_in, city, bio, last_saved, homepageViewAllowed, homepageAddAllowed } = useUserProfileSelector(
    state => state.userProfileReducer
  );  
 
  // const [user, setUser] = useState({ user: [] });
  // const [radioValue, setRadioValue] = React.useState(0);

  const radios = [
    { name: "Friends", value: AREA_FRIENDS },
    { name: "Public", value: AREA_PUBLIC },
  ];
  
  const userObject = "friends";

  let history = useHistory();

  const dispatch = useDispatch();


  useEffect(() => {
    // console.log("homepageViewAllowed", homepageViewAllowed)
    // setRadioValue(homepageViewAllowed)
  }, [])
  
  const handleClose = (e) => {
    history.push("/home/user/");
  }

  const handlePageViewRadioChange = (e) => {
    
    console.log("HANDLE_PAGE_VIEW", e.currentTarget.value);
    console.log("HANDLE_PAGE_VIEW", e.currentTarget.value);

    let data = {
      homepageViewAllowed: e.currentTarget.value,
      last_saved: String(new Date(Date.now()))
    }

    console.log("data", data);

    const response = DataService.update(userid, data)
    .then((response) => {
            console.log("setProfile", response.data);
            // setRadioValue(e.currentTarget.value);

            dispatch(
              updateUserProfile(
                response.data.username,
                response.data.first_name,
                response.data.last_name,
                response.data.email,
                response.data.gar_avatar_name,
                response.data.image,
                response.data.last_seen,
                response.data.online,
                response.data.created,
                response.data.status,
                response.data.date_of_birth,
                response.data.born_in,
                response.data.living_in,
                response.data.city,
                response.data.bio,
                response.data.last_saved,
                response.data.profile_completed_percent,
                response.data.number_of_logins,
                response.data.education,
                response.data.education_place,
                response.data.occupation,
                response.data.position,
                response.data.first_login,
                response.data.homepageViewAllowed,
                response.data.homepageAddAllowed                    
          )  
            );
        


          })
          .catch(e => {
          console.log(e.response);
          });  


  }

  const handlePageAddRadioChange = (e) => {
    console.log("handlePageAddRadioChange");

    let data = {
      homepageAddAllowed: e.currentTarget.value,
      last_saved: String(new Date(Date.now()))
    }

    const response = DataService.update(userid, data)
    .then((response) => {
            console.log("setProfile", response.data);
            // setRadioValue(e.currentTarget.value);

    dispatch(
      updateUserProfile(
        response.data.username,
        response.data.first_name,
        response.data.last_name,
        response.data.email,
        response.data.gar_avatar_name,
        response.data.image,
        response.data.last_seen,
        response.data.online,
        response.data.created,
        response.data.status,
        response.data.date_of_birth,
        response.data.born_in,
        response.data.living_in,
        response.data.city,
        response.data.bio,
        response.data.last_saved,
        response.data.profile_completed_percent,
        response.data.number_of_logins,
        response.data.education,
        response.data.education_place,
        response.data.occupation,
        response.data.position,
        response.data.first_login,
        response.data.homepageViewAllowed,
        response.data.homepageAddAllowed                    
  )  
    )
  })
  .catch(e => {
  console.log(e.response);
  });  



    // setRadioValue(e.currentTarget.value);

  }

  // useEffect(() => {
  //   setRadioValue()
  //   return () => {
  //     cleanup
  //   }
  // }, [])


    return (

      <React.Fragment>             


              <Container className="d-flex flex-row justify-content-center w-100">
                      
                    <Card className="p-2 w-100 border-0">

                      <Card.Title style={{color: "grey"}}>Account: </Card.Title>                          
                        
                        <Card.Body className="d-flex flex-column w-100">

                        <Row>

                          <Col md={6}>

                            <Row className="w-100 d-flex justify-content-center">
                                <Col className="d-flex flex-column align-items-start">

                                
                                <Form>
                                  <Form.Group>

                                  <Card.Text as="h6">People who can view content on my homepage:</Card.Text>


                                <ButtonGroup toggle>
                                  {radios.map((radio, index) => (
                                    <ToggleButton
                                      key={index}
                                      type="radio"
                                      name="radio"
                                      value={radio.value}
                                      checked={homepageViewAllowed === radio.value}
                                      onChange={e => handlePageViewRadioChange(e)}
                                    >
                                      {radio.name}
                                    </ToggleButton>
                                  ))}
                                </ButtonGroup>

                              </Form.Group>

                                </Form>

                          
                                  </Col>

                            </Row>

                            <Row className="mt-2">
                              <Col md={6}>
                                <Card.Text as="h6">People who can post on my homepage:</Card.Text>

                                <ButtonGroup toggle>
                                  {radios.map((radio, index) => (
                                    <ToggleButton
                                      key={index}
                                      type="radio"
                                      name="radio"
                                      value={radio.value}
                                      checked={homepageAddAllowed === radio.value}
                                      onChange={e => handlePageAddRadioChange(e)}
                                    >
                                      {radio.name}
                                    </ToggleButton>
                                  ))}
                                </ButtonGroup>

                              </Col>
                            </Row>        

                          </Col>
                          
                          
                          <Col md={6} className="d-flex flex-column mt-3">

                            <Row style={{backgroundColor: "lightgrey"}} className="w-100 p-1 mb-2">
                            <Card.Title style={{color: "white"}}>
                              Info:
                            </Card.Title>
                            </Row>
                            <Row>
                            <Card.Text>
                              Homepage is the page you see when Viewing another user.
                              {/* Preview your own    */}
                              {/* <div>       */}
                                {/* <Link className="m-0" to={{ pathname: `/home/user/${user.pk}`, state: { userObject } }}>  */}
                                {/* </Link> */}
                              {/* </div> */}
                            </Card.Text>
                            <Card.Text>
                              Friends is any person you are friends with.
                            </Card.Text>
                            <Card.Text>
                              Public is everybody - including your followers and friends.
                            </Card.Text>
                            </Row>
                            
      


                          </Col>

                          </Row>


                          <Row className="w-100 mt-5 align-self-center">
                                <div>                                   
                                  <UserCloseAccount />
                                </div>
                            </Row>
  
                        </Card.Body>
                          

                    </Card>     

                  </Container>
                      
      </React.Fragment>

    );
  };

export default UserProfileSettings;