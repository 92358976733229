export const NAME = "group_name";
export const USERNAME = "group_username";
// export const GAR217A_AVATAR_NAME = "group_gar217a_avatar_name";
export const EMAIL = "group_email";
export const ESTABLISHED = "group_estabilshed";
export const DESCRIPTION = "group_description";
export const CODE = "group_code";
export const IMAGE = "group_image";
export const COUNTRY = "country";
export const CITY = "group_city";
export const PASSWORD = "group_password";
export const REPEATED_PASSWORD = "group_repeated_password";


// export const LAST_SEEN = "group_last_seen";
// export const ONLINE = "group_online";
// export const STATUS = "group_status";
// export const CREATED = "group_created";
// export const COUNTRY = "group_country";
// export const CITY = "group_city";
// export const BIO = "group_bio";
// export const LAST_SAVED = "group_last_saved";

export const SUBMIT = "group_submit";
export const FORM_ID = "groupForm";
export const FORM_ERROR_DIV_ID = "group_form-errors";